import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT } from '../common/constants';
import { CodeSystem, CodeSystemSchema } from './CodeSystem';
import { MedicationKnowledgeSchema } from './MedicationKnowledge';
import { MedicationOrganizationSchema } from './MedicationOrganization';
import { UnitConverterSchema } from './UnitConverter';
import { Option } from 'modules/common/type';
import { INVENTORY_STATUS } from 'modules/common/apiConstants';

interface UnitConverter {
  factor: number | string;
  fromUnit: CodeSystem;
  price: number | string;
}

export interface MedicationForm {
  code?: string;
  registrationNumber?: string;
  name?: string;
  numeratorUnit?: string;
  codeGroup?: string;
  form?: string;
  ingredient?: string;
  dose?: string;
  country: CodeSystem;
  basicUnit: CodeSystem | string;
  basicPrice?: number | string;
  intendedRoute: CodeSystem[];
  unitConverterList: UnitConverter[];
  factor: number | string;
  fromUnit: CodeSystem;
  price: number | string;
  specializedDepartmentId: Option;
  medicationCategoryId?: CodeSystem | string;
  packaging?: string;
  productType: CodeSystem | string;
  expiration?: string;
  manufacturerName?: string;
  manufacturerAddress?: string;
  registrationName?: string;
  registrationAddress?: string;
  distributorName?: string;
  distributorAddress?: string;
  createConsecutive: boolean;
}

const MedicationCategorySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    total: z.number(),
    status: z.string(),
    scope: z.string(),
  })
  .partial();

export const MedicationSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    registrationNumber: z.string(),
    name: z.string(),
    numeratorUnit: z.string(),
    codeGroup: z.string(),
    organizationId: z.string(),
    form: z.string(),
    ingredient: z.string(),
    dose: z.string(),
    country: z.string(),
    basePrice: z.number(),
    publicPrice: z.number(),
    approvalDate: createDateTimeSchema(BE_DATE_FORMAT),
    currency: z.string(),
    basicUnit: z.string(),
    basicPrice: z.number(),
    isLockBasicUnit: z.boolean(),
    intendedRoute: z.array(CodeSystemSchema),
    resourceId: z.string(),
    medicationKnowledge: MedicationKnowledgeSchema,
    registration: MedicationOrganizationSchema,
    distributor: MedicationOrganizationSchema,
    manufacturer: MedicationOrganizationSchema,
    unitConverterList: z.array(UnitConverterSchema),
    specializedDepartmentId: z.number(),
    importedInventoryStatus: z.boolean(),
    quantity: z.number(),
    inventoryStatus: createObjectKeysEnumSchema(INVENTORY_STATUS),
    updatedTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    category: MedicationCategorySchema,
  })
  .partial();

export type Medication = z.infer<typeof MedicationSchema>;
