import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import DialogCustom from 'modules/common/component/DialogCustom';
import { useCreateMutate, useDialog, useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import DeleteIcon from '@mui/icons-material/Delete';
import { Inventory, Medication, MedicationPackage, UnitConverter, UnitQuantity } from 'modules/schema';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { axiosThunk } from 'modules/common/redux/axios';
import { INVENTORY_ACTIVITY_TYPE, MEDICATION_UNIT } from 'modules/common/apiConstants';
import SubMedicationFields from './SubMedicationFields';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDialog from './ConfirmDialog';
import { numericFormat } from 'modules/common/utils';
import useExportInventoryContext from './utils';
import { ExportInventoryProps, FormValues, InventoryExportDetail } from './types';

const DialogAddExportInventory = ({
  open,
  onClose,
  mutate,
  inventoryExportDetail,
  inventoryExportId,
}: ExportInventoryProps) => {
  const exportInventoryContext = useExportInventoryContext();
  const [openConfirmDialog, onOpenConfirmDialog, onCloseConfirmDialog] = useDialog();
  const { dispatch, intl } = useGeneralHook();
  const defaultValues = [
    {
      medication: null,
      lot: null,
      registrationDate: '',
      averagePrice: '',
      subMedication: [
        {
          quantity: 1,
          unit: null,
          reason: '',
        },
      ],
    },
  ];
  const form = useForm<any>({
    defaultValues: { inventoryExportDetails: defaultValues },
  });
  const { data: medications } = useFetch(API_SERVER.medication.inventories(), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
  });

  const mapData = <T, R>(data: T[] | undefined, mapFn: (item: T) => R) => data?.map(mapFn) || [];
  const medicationMap = useMemo(
    () =>
      mapData(medications, (i: Medication) => ({
        value: i.id,
        label: i.name,
      })),
    [medications],
  );

  const { fields, append, remove, replace } = useFieldArray({
    control: form.control,
    name: 'inventoryExportDetails',
  });

  const [typeSubmit, setTypeSubmit] = useState<'export' | 'draft'>('export');
  const [registrationNumbers, setRegistrationNumbers] = useState<{ [key: number]: string }>({});
  const [averagePrice, setAveragePrice] = useState<{ [key: number]: string }>({});
  const [inventoryDetails, setInventoryDetails] = useState<Record<number, Inventory>>({});
  const [unitInventoryDetails, setUnitInventoryDetails] = useState<Record<number, UnitConverter[]>>([]);
  const [unitQuantityList, setUnitQuantityList] = useState<Record<number, UnitQuantity[]>>([]);
  const [formData, setFormData] = useState<FormValues>();

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name?.includes('medication')) {
        const updatedRegistrationNumbers =
          value.inventoryExportDetails?.reduce((acc, detail, index) => {
            acc[index] = detail?.medication?.registrationNumber || '';
            return acc;
          }, {} as { [key: number]: string }) || {};
        setRegistrationNumbers(updatedRegistrationNumbers);
      }
    });

    return () => subscription.unsubscribe();
  }, [form, form.watch, medications]);

  const defaultState = () => {
    setRegistrationNumbers({});
    setAveragePrice({});
    setInventoryDetails({});
    setUnitInventoryDetails([]);
    setUnitQuantityList([]);
    setTypeSubmit('export');
  };

  const handleCloseDialog = () => {
    onClose();
    defaultState();
    form.reset({ inventoryExportDetails: defaultValues });
    replace(defaultValues);
  };

  const createInventoryExportMutate = useCreateMutate({
    onSuccess: () => {
      handleCloseDialog();
      exportInventoryContext.revalidateExportInventory();
    },
  });

  const updateInventoryExportMutate = useUpdateMutate({
    onSuccess: () => {
      handleCloseDialog();
      exportInventoryContext.revalidateExportInventory();
    },
  });

  const onSubmit = useCallback(
    async (fData) => {
      const mapInventoryExport = (formData?.inventoryExportDetails || fData?.inventoryExportDetails)
        .filter(
          (item) =>
            item &&
            Array.isArray(item.subMedication) &&
            item.subMedication.some((subObj) => subObj.quantity !== undefined),
        )
        .flatMap((item) =>
          item.subMedication.map((subObject) => ({
            medicationId: item.medication?.value,
            lot: item?.lot?.lot,
            quantity: subObject.quantity,
            unit: subObject.unit?.value,
            expiredDate: item?.lot?.expiredDate,
            note: subObject.reason,
          })),
        );
      const request = {
        type: INVENTORY_ACTIVITY_TYPE.EXPORT,
        inventoryExportDetails: mapInventoryExport,
      };
      if (typeSubmit === 'draft') {
        if (inventoryExportDetail) {
          updateInventoryExportMutate({
            url: API_SERVER.inventory.updateInventoryExportDraft(inventoryExportId!),
            method: 'put',
            data: request,
          });
        } else {
          createInventoryExportMutate({
            url: API_SERVER.inventory.createInventoryExportDraft(),
            method: 'post',
            data: request,
          });
        }
      } else {
        let draftData: any = {};
        if (inventoryExportDetail) {
          await dispatch(
            axiosThunk({
              url: API_SERVER.inventory.updateInventoryExportDraft(inventoryExportId!),
              method: 'put',
              data: request,
            }),
          );
        } else {
          const { data } = await dispatch(
            axiosThunk({
              url: API_SERVER.inventory.createInventoryExportDraft(),
              method: 'post',
              data: request,
            }),
          );
          draftData = data;
        }
        createInventoryExportMutate({
          url: API_SERVER.inventory.processInventoryExport(inventoryExportId! || draftData?.id),
          method: 'post',
        });
      }
      onCloseConfirmDialog();
    },
    [
      createInventoryExportMutate,
      dispatch,
      formData,
      inventoryExportDetail,
      inventoryExportId,
      onCloseConfirmDialog,
      typeSubmit,
      updateInventoryExportMutate,
    ],
  );

  const handleSubmit = (data) => {
    setFormData(data);
    if (typeSubmit === 'export') {
      onOpenConfirmDialog();
    } else {
      onSubmit(data);
    }
  };

  const handleRemoveAndReindexAveragePrice = (keyToRemove: number) => {
    setAveragePrice((prev) => {
      const valuesArray = Object.values(prev);
      const updatedArray = valuesArray.filter((_, index) => index !== keyToRemove);
      const updatedObject = updatedArray.reduce<{ [key: number]: string }>((acc, value, index) => {
        acc[index] = value;
        return acc;
      }, {});
      return updatedObject;
    });
  };

  const handleRemoveAndReindex = (keyToRemove: number) => {
    setRegistrationNumbers((prev) => {
      const valuesArray = Object.values(prev);
      const updatedArray = valuesArray.filter((_, index) => index !== keyToRemove);
      const updatedObject = updatedArray.reduce<{ [key: number]: string }>((acc, value, index) => {
        acc[index] = value;
        return acc;
      }, {});
      return updatedObject;
    });
  };

  const handleRemoveAndReindexInventory = (keyToRemove: number) => {
    setInventoryDetails((prev) => {
      const valuesArray = Object.values(prev).filter((_, index) => index !== keyToRemove);
      const updatedObject = valuesArray.reduce<Record<number, Inventory>>((acc, value, index) => {
        acc[index] = value;
        return acc;
      }, {});

      return updatedObject;
    });
  };

  const onChangeProduct = useCallback(
    async (index: number, type) => {
      handleRemoveAndReindexInventory(index);
      if (type === 'changeProduct') {
        form.setValue(`inventoryExportDetails.${index}.lot`, null);
        form.setValue(`inventoryExportDetails.${index}.subMedication`, [
          {
            quantity: 1,
            unit: null,
            reason: '',
          },
        ]);
      }
      const changeProduct = form.watch('inventoryExportDetails')[index];
      if (changeProduct && changeProduct.medication) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.inventory.getLot(changeProduct.medication?.value).url,
            method: 'get',
          }),
        ).then((res) => {
          const response = res.data;
          setInventoryDetails((prev) => ({
            ...prev,
            [index]: response,
          }));
          setRegistrationNumbers((prev) => ({
            ...prev,
            [index]: response.registrationNumber,
          }));
        });
      }
    },
    [dispatch, form],
  );

  const onChangeLot = useCallback(
    (index: number) => {
      const changeProduct = form.getValues('inventoryExportDetails')[index];
      if (changeProduct && changeProduct.lot) {
        setAveragePrice((prev) => ({
          ...prev,
          [index]: `${numericFormat(changeProduct?.lot?.averagePrice || 0)} VNĐ/${
            changeProduct.medication?.basicUnit
              ? intl.formatMessage({
                  id: MEDICATION_UNIT[changeProduct.medication?.basicUnit!].label,
                })
              : 'N/A'
          }`,
        }));
        if (changeProduct.lot.unitConverterList) {
          // @ts-ignore
          setUnitInventoryDetails((prev) => ({
            ...prev,
            [index]: changeProduct.lot && changeProduct.lot.unitConverterList,
          }));
        }
        if (changeProduct.lot.unitQuantityList) {
          // @ts-ignore
          setUnitQuantityList((prev) => ({
            ...prev,
            [index]: changeProduct.lot && changeProduct.lot.unitQuantityList,
          }));
        }
      }
    },
    [form, intl],
  );

  const renderSubMedicationFields = useCallback(
    (index) => (
      <SubMedicationFields
        key={index}
        index={index}
        form={form}
        unitInventoryDetail={unitInventoryDetails[index]}
        unitQuantityList={unitQuantityList[index]}
      />
    ),
    [form, unitInventoryDetails, unitQuantityList],
  );

  useEffect(() => {
    const asyncFunc = async () => {
      if (inventoryExportDetail?.inventoryExportDetails) {
        const calledMedicationIds = new Set<number>(); // Theo dõi các medicationId đã gọi
        const medicationDataCache: Record<number, MedicationPackage[]> = {}; // Lưu kết quả API theo medicationId
        const result = await Promise.all(
          inventoryExportDetail.inventoryExportDetails.map(async (item, index) => {
            try {
              // Kiểm tra nếu medicationId đã được gọi API trước đó
              if (!calledMedicationIds.has(item.medicationId!)) {
                const { data } = await dispatch(
                  axiosThunk({
                    url: API_SERVER.inventory.getLot(item.medicationId).url,
                  }),
                );
                const lotData = data?.medicationPackageList ?? [];
                medicationDataCache[item.medicationId!] = lotData; // Lưu kết quả vào cache
                calledMedicationIds.add(item.medicationId!); // Đánh dấu medicationId đã được gọi
              }
              // Sử dụng kết quả từ cache
              const lotData = medicationDataCache[item.medicationId!] || [];
              return {
                medicationId: item.medicationId,
                medication: medicationMap?.find((i) => i.value === item.medicationId),
                lot: lotData?.find((i: MedicationPackage) => i.lot === item.lot),
                medicationPackageList: lotData,
                subMedication: [
                  {
                    quantity: item.quantity,
                    unit: Object.values(MEDICATION_UNIT).find((i) => i.value === item.unit),
                    reason: item.note,
                  },
                ],
              } as InventoryExportDetail;
            } catch (error) {
              console.error('Error fetching lot data:', error);
              return null; // Trả về giá trị mặc định nếu xảy ra lỗi
            }
          }),
        );
        // Loại bỏ giá trị null
        const newInventoryExportDetails: InventoryExportDetail[] = result.filter(
          (detail): detail is InventoryExportDetail => detail !== null,
        );
        // Nhóm các subMedication theo medicationId
        const groupedDetails: InventoryExportDetail[] = newInventoryExportDetails.reduce<InventoryExportDetail[]>(
          (acc, detail) => {
            const { medicationId, subMedication, ...rest } = detail;
            const existing = acc.find((item) => item.medicationId === medicationId);
            if (existing) {
              existing.subMedication = [...existing.subMedication, ...subMedication];
            } else {
              acc.push({ medicationId, subMedication, ...rest });
            }
            return acc;
          },
          [],
        );
        // Reset form và chờ đồng bộ xong trước khi xử lý onChangeLot
        form.reset({
          inventoryExportDetails: groupedDetails,
        });
        // Chạy logic sau khi form đã được reset
        groupedDetails.forEach((_, index) => {
          onChangeProduct(index, _);
          onChangeLot(index);
        });
      }
    };
    asyncFunc();
  }, [dispatch, form, inventoryExportDetail, medicationMap, onChangeLot, onChangeProduct]);

  return (
    <>
      <DialogCustom
        open={!!open}
        maxWidth="xs"
        onClose={() => {
          handleCloseDialog();
        }}
        PaperProps={{
          style: { minWidth: 900 },
        }}
        title={'title.add.export.inventory'}
      >
        <Box padding={2}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Typography variant="subtitle1" gutterBottom>
              <FormattedMessage id="inventory.drugList" />
            </Typography>
            {fields.map((item, index) => (
              <Box key={item.id} paddingBottom={2}>
                <Card>
                  <Grid display="flex">
                    <Grid container spacing={2} padding={1}>
                      <Grid item xs={12} sm={4}>
                        <AutocompleteInput
                          form={form}
                          name={`inventoryExportDetails.${index}.medication`}
                          options={medicationMap}
                          getLabel="label"
                          getValue="value"
                          label="sale.product"
                          placeholder="sale.product"
                          required
                          onChange={() => onChangeProduct(index, 'changeProduct')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <AutocompleteInput
                          form={form}
                          name={`inventoryExportDetails.${index}.lot`}
                          options={
                            inventoryDetails[index]?.medicationPackageList ||
                            form.watch('inventoryExportDetails')[index]?.medicationPackageList ||
                            []
                          }
                          getLabel="lot"
                          getValue="lot"
                          label="inventory.lot"
                          placeholder="inventory.lot"
                          required
                          onChange={() => onChangeLot(index)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography variant="body2" color="textSecondary">
                          <FormattedMessage id="encounterPrescription.registerNumber" />
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            cursor: 'default',
                          }}
                        >
                          {registrationNumbers[index] || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="body2" color="textSecondary">
                          <FormattedMessage id="label.inventory.averageImportPrice" />
                        </Typography>
                        <Typography variant="body1">{averagePrice[index] || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body1">
                          {unitQuantityList[index]?.length > 0 &&
                            `(${intl.formatMessage({ id: 'inventory' })}: ${unitQuantityList[index]
                              .map(
                                (item) =>
                                  `${item.quantity} ${
                                    item.unit && intl.formatMessage({ id: MEDICATION_UNIT[item.unit].label })
                                  }`,
                              )
                              .join(', ')})`}
                        </Typography>
                      </Grid>
                      {renderSubMedicationFields(index)}
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      {fields.length > 1 && (
                        <IconButtonTitle
                          onClick={() => {
                            remove(index);
                            handleRemoveAndReindex(index);
                            handleRemoveAndReindexAveragePrice(index);
                            handleRemoveAndReindexInventory(index);
                          }}
                          title="delete"
                          size="small"
                        >
                          <DeleteIcon sx={{ color: '#78909C' }} />
                        </IconButtonTitle>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            ))}
            <Box paddingTop={2}>
              <Button
                startIcon={<AddIcon />}
                onClick={() =>
                  append({
                    medication: null,
                    lot: null,
                    registrationDate: '',
                    averagePrice: '',
                    subMedication: [
                      {
                        quantity: 1,
                        reason: '',
                        unit: null,
                      },
                    ],
                  })
                }
                variant="text"
              >
                <FormattedMessage id="add.new.medication.export.cancel" />
              </Button>
            </Box>
            <Box mt={2} textAlign={'end'}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleCloseDialog();
                }}
              >
                <FormattedMessage id="destroy" />
              </Button>
              <Button
                type="submit"
                color="inherit"
                sx={{ backgroundColor: '#CDDFFF', ml: 1 }}
                onClick={() => setTypeSubmit('draft')}
              >
                <FormattedMessage id="save.draft" />
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginLeft: 8 }}
                onClick={() => setTypeSubmit('export')}
              >
                <FormattedMessage id="export.inventory.button" />
              </Button>
            </Box>
          </form>
        </Box>
      </DialogCustom>
      <ConfirmDialog open={openConfirmDialog} onClose={onCloseConfirmDialog} onSubmit={onSubmit} />
    </>
  );
};

export default DialogAddExportInventory;
