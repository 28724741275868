import { Box, LinearProgress } from '@mui/material';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { useFetch } from 'modules/common/hook';
import { SALE_API } from 'modules/common/service';
import { NumericFormatText } from 'modules/common/utils';
import { ReportProfitDetail, SaleProfitReport } from 'modules/schema';

const COLUMNS: Column<ReportProfitDetail>[] = [
  { type: 'INDEX', width: '30px', sticky: true },
  {
    title: 'inventory.drugName',
    render: (record) => record?.medicationName,
    width: '50%',
  },
  {
    title: 'inventory.lot',
    render: (record) => record.lot,
  },
  {
    title: 'quantity',
    render: (record) => record.quantity,
  },

  {
    title: 'sale.customerPay',
    render: (record) => NumericFormatText(record.amountPaid || 0),
  },

  {
    title: 'report.profitDetail.table.label.averagePrice',
    render: (record) => NumericFormatText(record.averagePrice || 0),
  },
  {
    title: 'report.profitDetail.table.label.profitAmount',
    render: (record) => NumericFormatText(record.profitAmount || 0),
  },
];

interface Props {
  profitReport: SaleProfitReport;
}

export const ProfitDetailTableSub = ({ profitReport }: Props) => {
  const { data, isValidating } = useFetch(SALE_API.getSaleProfitDetail(profitReport.id!), {
    enabled: profitReport.id,
  });
  if (isValidating) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return <Table columns={COLUMNS} data={data} dataKey={'id'} subTableStyle />;
};
