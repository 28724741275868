import { PAYMENT_PLAN, some } from '../../../../common/constants';
import * as React from 'react';
import { Box, Checkbox, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from '../../../../common/component/TableCustom/element';
import { FormattedMessage } from 'react-intl';
import SchemaElement from '../../../../common/SchemaForm/SchemaElement';
import { mergeFieldName } from '../../../../common/SchemaForm/utils';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { PAYMENT_STATUS } from '../../../../reception/constants';
import { NumericFormatText } from '../../../../common/utils';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import ReplayIcon from '@mui/icons-material/Replay';
import { setLoading } from '../../../../common/redux/commonReducer';
import RefundConfirmDialog from './RefundConfirmDialog';
import { fetchThunk } from '../../../../common/redux/thunk';
import { API_SERVER } from '../../../../common/api';
import { Info } from '@mui/icons-material';
import { PAYMENT_DETAIL_TYPE } from 'modules/common/apiConstants';

interface Props {
  dataSource?: some;
  therapyDetail?: some;
  paymentList?: some[];
  discountPercent?: number;
  setSelection: (paymentDetail, checked) => void;
  setPaymentType: (therapyPaymentDetail, type) => void;
  reCalculate: (therapyPaymentDetail, event) => void;
  revalidate: () => void;
  methods: UseFormReturn<any>;
  loading?: boolean;
}

const PaymentDetailRow: React.FunctionComponent<{
  dataSource?: some;
  therapyDetail?: some;
  paymentList?: some[];
  paymentDetail: some;
  setSelection: (paymentDetail, checked) => void;
  setPaymentType: (paymentDetail, type) => void;
  reCalculate: (paymentDetail, event?) => void;
  methods: UseFormReturn<any>;
  setSelectAll: (checked) => void;
  onOpenRefundDialog: (paymentDetail) => void;
}> = (props) => {
  const {
    dataSource,
    therapyDetail,
    paymentList,
    paymentDetail,
    setSelection,
    setPaymentType,
    reCalculate,
    methods,
    setSelectAll,
    onOpenRefundDialog,
  } = props;
  const { setValue } = methods;
  const { intl, appState } = useGeneralHook();

  const maxItemDiscountPrice = ((paymentDetail.unitPrice || 0) * (100 - (dataSource?.discountPercent || 0))) / 100;
  const maxItemDiscountPercent = 100 - (dataSource?.discountPercent || 0);
  const therapyPaymentDetail = paymentList?.find((p) => p.type === PAYMENT_DETAIL_TYPE.THERAPY.value);

  const optionsDiscountType = [
    { value: 'PERCENT', label: '%' },
    { value: 'CASH', label: appState.common.groupCurrency },
  ];

  const paymentStatus =
    (paymentDetail?.type === PAYMENT_DETAIL_TYPE.THERAPY.value && paymentDetail.status === 'CANCELLED') ||
    (paymentDetail?.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value && paymentDetail.status === 'PACKAGE_PAID')
      ? undefined
      : PAYMENT_STATUS.find((s) => s.value === paymentDetail.status);

  const isDisabledSession =
    paymentDetail?.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value &&
    (therapyPaymentDetail?.therapyPaymentType === 'PACKAGE' || therapyDetail?.paymentPlan === PAYMENT_PLAN.FREE.value);

  const isDisabledTherapy =
    paymentDetail?.type === PAYMENT_DETAIL_TYPE.THERAPY.value &&
    (therapyPaymentDetail?.therapyPaymentType === 'SEPARATE' || therapyDetail?.paymentPlan === PAYMENT_PLAN.FREE.value);

  const isDisabled = paymentDetail?.status !== 'UNPAID' || isDisabledSession || isDisabledTherapy;
  return (
    <>
      <TableCustomRow
        key={paymentDetail.index}
        sx={
          isDisabledSession
            ? {
                '& p': { opacity: '0.4' },
                '& span': { opacity: '0.4' },
                '& td': { padding: '0px 4px', margin: '0px', height: '59px' },
              }
            : { '& p': { lineHeight: '17px' }, '& td': { padding: '0px 4px', margin: '0px', height: '59px' } }
        }
      >
        {(paymentDetail.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value ||
          paymentDetail.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE.value) && (
          <TableCustomCell></TableCustomCell>
        )}
        <TableCustomCell sx={{ '& span': { opacity: '0.85' }, padding: 0 }}>
          {/*<p>{typeof paymentDetail?.checked}</p>*/}
          {/*<p>{(paymentDetail?.checked || false).toString()}</p>*/}
          {!isDisabled && (
            <Checkbox
              style={
                paymentDetail.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value ||
                paymentDetail.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE.value
                  ? { padding: 0 }
                  : {}
              }
              disableTouchRipple
              disableRipple
              disableFocusRipple
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                const checkedValue = event.target.checked;
                if (!checkedValue) {
                  setSelectAll(false);
                }
                setSelection(paymentDetail, checkedValue);
              }}
              checked={paymentDetail?.checked || false}
              disabled={isDisabled}
            />
          )}
        </TableCustomCell>
        {paymentDetail.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value &&
          paymentDetail.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE.value && (
            <TableCustomCell></TableCustomCell>
          )}
        <TableCustomCell sx={{ padding: 0 }}>
          <Typography variant="body1">{paymentDetail?.content}</Typography>
        </TableCustomCell>
        <TableCustomCell sx={{ padding: 0 }}>
          <Typography variant="body1">{paymentDetail.quantity}</Typography>
        </TableCustomCell>
        <TableCustomCell sx={{ padding: 0 }}>
          <Typography variant="body1">{paymentDetail.unit}</Typography>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Box marginTop={1.5}>
            <SchemaElement
              fieldName={mergeFieldName({
                name: 'unitPrice',
                index: paymentDetail?.index,
                parent: 'paymentList',
              })}
              propsElement={{
                type: 'number-field',
                inputType: 'number',
                placeholder: intl.formatMessage({
                  id: 'therapy.price',
                }),
                register: {
                  min: { value: 0, message: '>= 0' },
                },
                // hidden: isDisabledTherapy,
                onChange: (event) => {
                  reCalculate(paymentDetail, event);
                },
                disabled: isDisabled,
              }}
            />
          </Box>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          {paymentDetail?.discountType === 'PERCENT' && (
            <Box display={'block'} marginTop={1.5}>
              <SchemaElement
                fieldName={mergeFieldName({
                  name: 'discount',
                  index: paymentDetail?.index,
                  parent: 'paymentList',
                })}
                propsElement={{
                  type: 'number-field',
                  placeholder: intl.formatMessage({
                    id: 'cashier.enter',
                  }),
                  inputType: 'number',
                  // hidden: isDisabledTherapy,
                  register: {
                    max: {
                      value: maxItemDiscountPercent,
                      message: `<= ${maxItemDiscountPercent}%`,
                    },
                    min: { value: 0, message: `>= 0%` },
                  },
                  onChange: (event) => {
                    reCalculate(paymentDetail, event);
                  },
                  disabled: isDisabled,
                }}
              />
            </Box>
          )}
          {paymentDetail?.discountType === 'CASH' && (
            <Box display={'block'} marginTop={1.5}>
              <SchemaElement
                // key={`therapyDiscountValue_${therapyPaymentDetail?.id}`}
                fieldName={mergeFieldName({
                  name: 'discount',
                  index: paymentDetail?.index,
                  parent: 'paymentList',
                })}
                propsElement={{
                  type: 'number-field',
                  inputType: 'number',
                  // hidden: isDisabledTherapy,
                  placeholder: intl.formatMessage({
                    id: 'cashier.enter',
                  }),
                  register: {
                    max: {
                      value: maxItemDiscountPrice,
                      message: `<= ${maxItemDiscountPrice} ${appState.common.groupCurrency}`,
                    },
                    min: { value: 0, message: `>= 0 ${appState.common.groupCurrency}` },
                  },
                  onChange: (event) => {
                    reCalculate(paymentDetail, event);
                  },
                  disabled: isDisabled,
                }}
              />
            </Box>
          )}
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Box textAlign="left" marginTop={1.5}>
            <SchemaElement
              // key={`therapyDiscountType_${therapyPaymentDetail?.id}`}
              fieldName={mergeFieldName({
                name: 'discountType',
                index: paymentDetail?.index,
                parent: 'paymentList',
              })}
              propsElement={{
                type: 'select',
                placeholder: intl.formatMessage({
                  id: 'cashier.select',
                }),
                options: optionsDiscountType,
                defaultValue: 'PERCENT',
                rawOptions: true,
                disableClearBtn: true,
                hideIconSelect: true,
                // hidden: isDisabledTherapy,
                // onChange: (event) => {
                //     reCalculate(paymentDetail, event);
                // },
                onChange: (val) => {
                  setValue(
                    mergeFieldName({
                      name: 'discountType',
                      index: paymentDetail?.index,
                      parent: 'paymentList',
                    }),
                    val,
                  );
                  reCalculate(paymentDetail);
                },
                disabled: isDisabled,
              }}
            />
          </Box>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Typography variant="body1">{NumericFormatText(paymentDetail.receiptDiscountAmount || 0)}</Typography>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Typography variant="body1">{NumericFormatText(paymentDetail.discountAmount || 0)}</Typography>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Typography variant="body1">{NumericFormatText(paymentDetail.totalAmount || 0)}</Typography>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Box marginTop={1.5}>
            <SchemaElement
              // key={`therapyPrice_${therapyPaymentDetail.id}`}
              fieldName={mergeFieldName({
                name: 'vat',
                index: paymentDetail?.index,
                parent: 'paymentList',
              })}
              propsElement={{
                type: 'number-field',
                inputType: 'number',
                placeholder: intl.formatMessage({
                  id: 'vat',
                }),
                // hidden: isDisabledTherapy,
                register: {
                  min: { value: 0, message: '>= 0' },
                  max: { value: 100, message: '<= 100' },
                },
                onChange: (event) => {
                  reCalculate(paymentDetail, event);
                },
                disabled: isDisabled,
              }}
            />
          </Box>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Typography variant="body1">{NumericFormatText(paymentDetail.patientPay || 0)}</Typography>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          <Typography
            noWrap
            sx={{
              color: paymentStatus?.sx || 'primary.main',
              fontWeight: '500',
            }}
          >
            <FormattedMessage id={paymentStatus?.label || ' '} />
          </Typography>
        </TableCustomCell>
        <TableCustomCell
          sx={
            isDisabledTherapy
              ? {
                  '& p': { opacity: '0.4' },
                  '& span': { opacity: '0.4' },
                  padding: 0,
                }
              : { padding: 0 }
          }
        >
          {paymentDetail?.status === 'PAID' &&
            !paymentDetail?.refundId &&
            therapyDetail?.paymentPlan !== PAYMENT_PLAN.FREE.value && (
              <IconButtonTitle
                size="small"
                title="refund"
                onClick={() => {
                  onOpenRefundDialog(paymentDetail);
                }}
              >
                <ReplayIcon />
              </IconButtonTitle>
            )}
          {paymentDetail?.refundId && (
            <IconButtonTitle
              size="small"
              title="refund"
              onClick={() => {
                onOpenRefundDialog(paymentDetail);
              }}
            >
              <Info />
            </IconButtonTitle>
          )}
          {/*<Info color="action" style={{ cursor: 'pointer' }} />*/}
        </TableCustomCell>
      </TableCustomRow>
      {paymentDetail.type === PAYMENT_DETAIL_TYPE.THERAPY.value && (
        <TableCustomRow>
          <TableCustomCell colSpan={17} style={{ padding: 0, border: 0 }}>
            <SchemaElement
              key={`therapyPaymentType_${paymentDetail.id}`}
              fieldName={mergeFieldName({
                name: 'therapyPaymentType',
                index: paymentDetail?.index,
                parent: 'paymentList',
              })}
              propsElement={{
                type: 'multiple-radio',
                options: [
                  { value: 'PACKAGE', label: 'therapy.payment.type.label.package' },
                  { value: 'SEPARATE', label: 'therapy.payment.type.label.separate' },
                ],
                row: true,
                register: { required: true },
                disabled:
                  therapyDetail?.paymentPlan === PAYMENT_PLAN.FREE.value &&
                  (paymentDetail?.paymentScope === 'IN_PACKAGE' ||
                    paymentDetail?.type === PAYMENT_DETAIL_TYPE.THERAPY.value),
                noHelperText: true,
                propsWrapper: {
                  xs: 12,
                  marginLeft: '100px',
                },
                onChange: (val) => {
                  setSelectAll(false);
                  if (val === 'PACKAGE') {
                    paymentList
                      ?.filter(
                        (p) =>
                          p.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value ||
                          p.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE.value,
                      )
                      ?.forEach((v) => {
                        setSelection(v, false);
                      });
                  } else {
                    setSelection(
                      paymentList?.find((p) => p.type === PAYMENT_DETAIL_TYPE.THERAPY.value),
                      false,
                    );
                  }
                  setPaymentType(paymentDetail, val);
                },
              }}
            />
          </TableCustomCell>
        </TableCustomRow>
      )}
    </>
  );
};

const TablePrice: React.FunctionComponent<Props> = (props) => {
  const { dataSource, therapyDetail, paymentList, setSelection, setPaymentType, reCalculate, revalidate, methods } =
    props;
  const [selectAll, setSelectAll] = React.useState<boolean>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listCheckable: some[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listUncheckable: some[] = [];
  const therapyPaymentDetail = paymentList?.find((p) => p.type === PAYMENT_DETAIL_TYPE.THERAPY.value);
  const { dispatch, confirmDialog, openSnackbar, intl } = useGeneralHook();
  const [openRefundDialog, setOpenRefundDialog] = React.useState(false);
  const [refundPaymentDetail, setRefundPaymentDetail] = React.useState<some>();
  const { promptConfirmation, close } = confirmDialog;

  paymentList
    ?.filter((p) => p.status === 'UNPAID')
    ?.forEach((p) => {
      if (therapyDetail?.paymentPlan === PAYMENT_PLAN.FREE.value) {
        if (p?.packageScope === 'IN_PACKAGE' || p?.type === PAYMENT_DETAIL_TYPE.THERAPY.value) {
          listUncheckable.push(p);
        } else {
          listCheckable.push(p);
        }
      } else if (therapyPaymentDetail?.therapyPaymentType === 'PACKAGE') {
        if (p.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value) {
          listUncheckable.push(p);
          // setSelection(p, false);
        } else {
          listCheckable.push(p);
          // setSelection(p, true);
        }
      } else {
        if (p.type === PAYMENT_DETAIL_TYPE.THERAPY.value) {
          listUncheckable.push(p);
          // setSelection(p, false);
        } else {
          listCheckable.push(p);
          // setSelection(p, true);
        }
      }
    });

  const onRefund = React.useCallback(
    async (refundDetail: some) => {
      const confirm = await promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: <FormattedMessage id="therapy.cashier.payment.refund.message.confirm" />,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(
            fetchThunk(
              API_SERVER.payment.refund(dataSource?.paymentId),
              'post',
              {
                id: refundPaymentDetail?.id,
                ...refundDetail,
              },
              'application/json-patch+json',
            ),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [
      close,
      dataSource?.paymentId,
      dispatch,
      intl,
      openSnackbar,
      promptConfirmation,
      refundPaymentDetail?.id,
      revalidate,
    ],
  );

  const content = React.useMemo(() => {
    return (
      <Box
        position="relative"
        overflow="inherit"
        display="flex"
        flexDirection="column"
        height="-webkit-fill-available"
        flex={1}
      >
        <TableContainer style={{ height: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableCustomRow>
                <TableHeaderCell sx={{ '& span': { opacity: '0.85' } }}>
                  <Checkbox
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    checked={selectAll || false}
                    disabled={!paymentList?.find((p) => p.status === 'UNPAID') || listCheckable?.length <= 0}
                    onChange={(event) => {
                      const checkedValue = event.target.checked;
                      setSelectAll(checkedValue);
                      if (checkedValue) {
                        listCheckable?.forEach((p) => {
                          setSelection(p, true);
                        });
                        listUncheckable?.forEach((p) => {
                          setSelection(p, false);
                        });
                      } else {
                        paymentList?.forEach((p) => {
                          setSelection(p, checkedValue);
                        });
                      }
                    }}
                  />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="stt" />
                </TableHeaderCell>
                <TableHeaderCell sx={{ width: 250 }}>
                  <FormattedMessage id="cashier.service" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="quantity" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="indication.unit" />
                </TableHeaderCell>
                <TableHeaderCell sx={{ width: 150 }}>
                  <FormattedMessage id="therapy.price" />
                </TableHeaderCell>
                <TableHeaderCell sx={{ width: 150 }}>
                  <FormattedMessage id="therapy.cashier.table.header.discount" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="cashier.discountType" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="therapy.cashier.table.header.discountAmountPercent" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="therapy.cashier.table.header.discountAmount" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="totalPrice" />
                </TableHeaderCell>
                <TableHeaderCell sx={{ width: 15 }}>
                  <FormattedMessage id="vatPercent" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="patientPay" />
                </TableHeaderCell>
                <TableHeaderCell sx={{ maxWidth: '30px', minWidth: '30px' }}>
                  <FormattedMessage id="therapy.cashier.table.header.paymentStatus" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="action" />
                </TableHeaderCell>
              </TableCustomRow>
            </TableHead>
            <TableBody>
              {paymentList && paymentList.length > 0 ? (
                <>
                  {paymentList
                    ?.filter((p) => p.type === PAYMENT_DETAIL_TYPE.THERAPY.value)
                    .map((row, index) => (
                      <PaymentDetailRow
                        dataSource={dataSource}
                        therapyDetail={therapyDetail}
                        paymentList={paymentList}
                        paymentDetail={row}
                        setSelection={setSelection}
                        setPaymentType={setPaymentType}
                        reCalculate={reCalculate}
                        setSelectAll={setSelectAll}
                        methods={methods}
                        onOpenRefundDialog={(paymentDetail) => {
                          setOpenRefundDialog(true);
                          setRefundPaymentDetail(paymentDetail);
                        }}
                        key={`${index}_${row?.id}`}
                      />
                    ))}
                  {paymentList
                    ?.filter(
                      (p) =>
                        p.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value ||
                        p.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE.value,
                    )
                    .map((row, index) => (
                      <PaymentDetailRow
                        dataSource={dataSource}
                        therapyDetail={therapyDetail}
                        paymentList={paymentList}
                        paymentDetail={row}
                        setSelection={setSelection}
                        setPaymentType={setPaymentType}
                        reCalculate={reCalculate}
                        setSelectAll={setSelectAll}
                        methods={methods}
                        onOpenRefundDialog={(paymentDetail) => {
                          setOpenRefundDialog(true);
                          setRefundPaymentDetail(paymentDetail);
                        }}
                        key={`${index}_${row?.id}`}
                      />
                    ))}
                  {paymentList
                    ?.filter(
                      (p) =>
                        p.type !== PAYMENT_DETAIL_TYPE.THERAPY.value &&
                        p.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value &&
                        p.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE.value,
                    )
                    .map((row, index) => (
                      <PaymentDetailRow
                        dataSource={dataSource}
                        therapyDetail={therapyDetail}
                        paymentList={paymentList}
                        paymentDetail={row}
                        setSelection={setSelection}
                        setPaymentType={setPaymentType}
                        reCalculate={reCalculate}
                        setSelectAll={setSelectAll}
                        methods={methods}
                        onOpenRefundDialog={(paymentDetail) => {
                          setOpenRefundDialog(true);
                          setRefundPaymentDetail(paymentDetail);
                        }}
                        key={`${index}_${row?.id}`}
                      />
                    ))}
                </>
              ) : (
                <>
                  <TableCustomRow>
                    <TableCustomCell colSpan={17}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minHeight: '100%',
                        }}
                      >
                        <Typography variant="inherit" color="textSecondary">
                          <FormattedMessage id="noData" />
                        </Typography>
                      </Box>
                    </TableCustomCell>
                  </TableCustomRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {openRefundDialog && (
          <RefundConfirmDialog
            open={openRefundDialog}
            paymentDetail={refundPaymentDetail}
            onClose={() => {
              setOpenRefundDialog(false);
            }}
            onSubmit={(refundDetail) => {
              setOpenRefundDialog(false);
              onRefund(refundDetail);
            }}
          />
        )}
      </Box>
    );
  }, [
    dataSource,
    listCheckable,
    listUncheckable,
    methods,
    onRefund,
    openRefundDialog,
    paymentList,
    reCalculate,
    refundPaymentDetail,
    selectAll,
    setPaymentType,
    setSelection,
    therapyDetail,
  ]);

  return <>{content}</>;
};

export default TablePrice;
