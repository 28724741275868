import { Box, Button, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { EncounterDetailProps } from '../../type';
import { API_SERVER } from 'modules/common/api';
import { useCreateMutate, useDeleteMutate, useDialog, useFetch, useUpdateMutate } from 'modules/common/hook';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { MEDICATION_CATEGORY_SCOPE, MEDICATION_USAGE_TYPE } from 'modules/common/apiConstants';
import { CodeSystem, IndicationRequest, Medication, MedicationCategory, MedicationUsage } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import AddDrugUsageDialog from './AddDrugUsageDialog';
import PrintIcon from '@mui/icons-material/Print';
import DrugUsagePrintDialog from './PrintDialog/DrugUsagePrintDialog';
import SelectInput from 'modules/common/component/form/SelectInput';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import Table, { TableOptions } from 'modules/common/component/table';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import { Column } from 'modules/common/component/table/type';
import NumberInput from 'modules/common/component/form/NumberInput';
import IconButton from 'modules/common/component/IconButton';
import { some } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { NoNote } from 'svg';
import DialogCustom from 'modules/common/component/DialogCustom';
import { NumericFormatText } from 'modules/common/utils';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import TextArea from 'modules/common/component/form/TextArea';
import { renderLabelSearch } from '../../const';

type MedicationType = Omit<
  Medication,
  | 'approvalDate'
  | 'medicationKnowledge'
  | 'registration'
  | 'distributor'
  | 'manufacturer'
  | 'unitConverterList'
  | 'inventoryStatus'
  | 'updatedTime'
  | 'category'
> & { group?: string };

type IndicationRequestType = Omit<IndicationRequest, 'createdTime' | 'startTime' | 'endTime'>;
type MedicineInUseType = Omit<MedicationUsage, 'indicationRequest' | 'medication' | 'intendedRoute'> & {
  indicationRequest?: IndicationRequestType;
  medication?: MedicationType;
  intendedRoute?: CodeSystem;
};
type MedicationCategoryType = Omit<MedicationCategory, 'medicationList'> & {
  medicationList?: MedicationType[];
};

interface SchemaFormMedicineInUse {
  group: MedicationCategoryType | null;
  medication: (Pick<Medication, 'id' | 'name'> & { group?: string; medicalSuppliesId?: number }) | null;
  medicineInUse: MedicineInUseType[];
  note: string | null;
}

const MedicineInUseSession = ({ encounter, readonly }: EncounterDetailProps) => {
  const form = useForm<SchemaFormMedicineInUse>({
    defaultValues: { group: null, medication: null, medicineInUse: [] },
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'medicineInUse',
    keyName: 'key',
  });

  const { intl, appState, openSnackbar } = useGeneralHook();
  const [openAddDrugDialog, onOpenAddDrugDialog, onCloseAddDrugDialog] = useDialog();
  const [selectedMedication, setSelectedMedication] = React.useState<Medication | undefined>(undefined);
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const allIntendedRoutes = appState.common.intendedRoutes;
  const [openNoteDialog, onOpenNoteDialog, onCloseNoteDialog] = useDialog();
  const [medicationNote, setMedicationNote] = React.useState<MedicineInUseType | undefined>(undefined);

  const { data: medicationUsages, revalidate: medicationUsageRevalidate } = useFetch(
    API_SERVER.medicationUsage.getList(encounter?.id!, { type: MEDICATION_USAGE_TYPE.ENCOUNTER }),
  );

  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.CLINIC }),
  );

  const { data: indicationRequests } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER, [
      SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
    ]),
  );

  useEffect(() => {
    if (medicationUsages) {
      form.setValue(
        'medicineInUse',
        medicationUsages?.map((item) => ({
          ...item,
          intendedRoute: allIntendedRoutes.find((i) => i.value === item.intendedRoute),
        })),
      );
    }
  }, [medicationUsages, form, allIntendedRoutes]);

  const onAddMedicationUsageMutate = useCreateMutate({
    onSuccess: () => {
      medicationUsageRevalidate();
      onCloseAddDrugDialog();
      form.resetField('medication');
    },
  });

  const medicalItems = medicationCategoryList?.flatMap((medication) =>
    medication?.medicationList?.map((item) => ({
      ...item,
      group: medication.name,
    })),
  );

  const onDeleteMutate = useDeleteMutate({ onSuccess: medicationUsageRevalidate });
  const updateMedicationUsageMultipleMutate = useUpdateMutate({
    onSuccess: () => {
      medicationUsageRevalidate();
    },
    globalLoading: false,
  });
  const updateMedicationUsageDetail = useDebouncedCallback(() => {
    updateMedicationUsageMultipleMutate({
      url: API_SERVER.medicationUsage.updateMultipleMedicationUsages(),
      method: 'PUT',
      data: {
        data: form
          .getValues('medicineInUse')
          .filter((value, index) => !value.isPaid)
          .map((medicationUse) => ({
            id: medicationUse?.id,
            medicationId: medicationUse?.medicationId,
            quantity: medicationUse?.quantity || 1,
            cancellationQuantity: medicationUse?.cancellationQuantity,
            cancellationReason: medicationUse?.cancellationReason,
            isFree: medicationUse?.isFree,
            indicationRequestId: medicationUse?.indicationRequest?.id,
            intendedRoute: medicationUse?.intendedRoute?.value,
            note: medicationUse?.note,
            referenceId: medicationUse?.referenceId,
            type: medicationUse?.type,
          })),
      },
    });
  }, 2000);
  const updateMedicationUsageMutate = useUpdateMutate({
    onSuccess: () => {
      medicationUsageRevalidate();
      onCloseNoteDialog();
    },
  });

  const columns: Column<typeof fields[number]>[] = [
    {
      type: 'INDEX',
      width: '2%',
      headerCellProps: { sx: { fontSize: '16px' } },
    },
    {
      title: 'drugUsage.drugName',
      width: '25%',
      align: 'left',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record) => {
        return (
          <Stack direction={'row'} flex={1} alignItems={'center'} gap={'4px'}>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: '16px',
                cursor: 'default',
              }}
            >
              {renderLabelSearch(record.medicationName, record?.medication?.ingredient, record?.medication?.dose)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      title: 'quantity',
      align: 'center',
      width: '8%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => {
        form.setValue(`medicineInUse.${index}.quantity`, record.quantity);
        return (
          <NumberInput
            form={form}
            name={`medicineInUse.${index}.quantity`}
            hideError
            variant="standard"
            sx={{ '& .MuiInput-root': { backgroundColor: 'transparent' }, fontSize: '16px' }}
            disabled={record.isPaid || readonly}
            onChange={(value) => {
              updateMedicationUsageDetail();
              if (!value?.target?.value) {
                form.setValue(`medicineInUse.${index}.quantity`, 1);
              }
            }}
            inputProps={{ sx: { textAlign: 'center' } }}
            max={999}
            defaultValue={1}
          />
        );
      },
    },
    {
      title: 'drugUsage.unit',
      width: '8%',
      align: 'center',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record) => (
        <Typography
          sx={{
            fontSize: '16px',
            cursor: 'default',
          }}
        >
          {record.medicationUnit}
        </Typography>
      ),
    },
    {
      title: 'indication',
      width: '17%',
      align: 'left',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => {
        return (
          <SelectInput
            form={form}
            name={`medicineInUse.${index}.indicationRequest`}
            options={indicationRequests}
            getValue="serviceName"
            renderLabel="serviceName"
            variant="standard"
            sx={{ backgroundColor: 'transparent', fontSize: '16px' }}
            groupBy="systemCategoryName"
            hideError
            disabled={record.isPaid || readonly}
            onChange={updateMedicationUsageDetail}
            rawOptionLabel
            rawGroup
          />
        );
      },
    },
    {
      title: 'drugUsage.intendedRoute',
      align: 'left',
      width: '12%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => {
        return (
          <SelectInput
            form={form}
            name={`medicineInUse.${index}.intendedRoute`}
            options={medicalItems?.find((item) => item?.id === record?.medication?.id)?.intendedRoute}
            getValue="value"
            renderLabel="label"
            variant="standard"
            sx={{ backgroundColor: 'transparent', fontSize: '16px' }}
            hideError
            disabled={record.isPaid || readonly}
            onChange={updateMedicationUsageDetail}
            rawOptionLabel
          />
        );
      },
    },
    {
      title: 'price',
      align: 'left',
      width: '8%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record) => (
        <Typography
          sx={{
            fontSize: '16px',
            cursor: 'default',
          }}
        >
          {NumericFormatText(record?.medication?.basePrice)}
        </Typography>
      ),
    },
    {
      title: 'totalPrice',
      fieldName: 'totalPrice',
      align: 'left',
      headerCellProps: { sx: { fontSize: '16px' } },
      width: '8%',
      render: (record) => (
        <Typography
          sx={{
            fontSize: '16px',
            cursor: 'default',
          }}
        >
          {NumericFormatText((record?.medication?.basePrice || 0) * (record?.quantity || 0))}
        </Typography>
      ),
    },
    {
      title: 'common.billable',
      align: 'left',
      width: '6%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => {
        return (
          <Tooltip
            title={<FormattedMessage id={record.isPaid ? 'report.debt.table.title.amountPaid' : 'freeSwitch'} />}
            disableInteractive
          >
            <span>
              <Switch
                size="small"
                color="primary"
                checked={!record.isFree}
                disabled={record.isPaid || readonly}
                onChange={async (_, checked) => {
                  await updateMedicationUsageMutate({
                    url: API_SERVER.medicationUsage.update(record.id!),
                    method: 'PUT',
                    data: {
                      id: record?.id,
                      medicationId: record?.medicationId,
                      quantity: record?.quantity,
                      cancellationQuantity: record?.cancellationQuantity,
                      cancellationReason: record?.cancellationReason,
                      isFree: !checked,
                      indicationRequestId: record?.indicationRequestId,
                      intendedRoute: record?.intendedRoute?.value,
                      note: record?.note,
                      referenceId: record?.referenceId,
                      type: record?.type,
                    },
                  });
                }}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'action',
      align: 'right',
      width: '10%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record) => (
        <>
          <IconButtonTitle
            onClick={async () => {
              await onDeleteMutate(
                {
                  url: API_SERVER.medicationUsage.delete(record?.id!),
                  method: 'DELETE',
                },
                {
                  confirmMessage: intl.formatMessage({ id: 'confirmDelete' }, { name: record?.medication?.name }),
                },
              );
            }}
            title="delete"
            size="small"
            disabled={record.isPaid || readonly}
          >
            <Delete sx={{ color: record.isPaid || readonly ? '#E0E0E0' : '#78909C' }} />
          </IconButtonTitle>
          <IconButtonTitle
            title="note"
            key="note-btn"
            size="small"
            onClick={() => {
              setMedicationNote(record);
              onOpenNoteDialog();
              form.setValue('note', record?.note || '');
            }}
            disabled={readonly}
            sx={{ '& svg': { width: '24px' } }}
          >
            {record.note ? (
              <StickyNote2Icon sx={{ color: record.isPaid || readonly ? '#E0E0E0' : '#78909C' }} fontSize="small" />
            ) : (
              <NoNote fontSize="small" />
            )}
          </IconButtonTitle>
        </>
      ),
    },
  ];

  const dataMedication = useMemo(
    () =>
      form.watch('group')
        ? medicalItems?.filter((item) => item?.group === form.getValues('group')?.name)
        : medicalItems,
    [form, medicalItems],
  );

  const debounceUpdateMedicationUsage = useDebouncedCallback(async (medicationNote, value) => {
    if (medicationNote?.id && value !== medicationNote.note) {
      await updateMedicationUsageMutate({
        url: API_SERVER.medicationUsage.update(medicationNote?.id!),
        method: 'PUT',
        data: {
          id: medicationNote.id,
          medicationId: medicationNote.medicationId,
          quantity: medicationNote.quantity,
          cancellationQuantity: medicationNote.cancellationQuantity,
          cancellationReason: medicationNote.cancellationReason,
          isFree: medicationNote.isFree,
          indicationRequestId: medicationNote.indicationRequestId,
          referenceId: medicationNote.referenceId,
          type: medicationNote.type,
          note: value,
        },
      });
    }
  }, 1000);

  const handleChange = () => {
    const length = form.watch('note')?.length || 0;
    if (length > 255) {
      openSnackbar({
        message: intl.formatMessage({
          id: 'text.max.length.255',
        }),
        type: 'error',
      });
      return;
    }
    debounceUpdateMedicationUsage(medicationNote, form.watch('note'));
  };

  const TABLE_OPTIONS = useMemo(() => {
    let totalValue = 0;
    medicationUsages?.forEach((item) => {
      const processedValue = (item?.medication?.basePrice || 0) * (item?.quantity || 0);
      totalValue += processedValue;
    });
    let tableOptions: TableOptions = {
      position: 'bottom',
      record: { totalPrice: totalValue },
      messageKey: 'supply.provisional.uppercase',
      startIndex: 6,
    };
    return tableOptions;
  }, [medicationUsages]);

  return (
    <>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <Box width="300px">
              <AutocompleteInput
                key="group"
                form={form}
                name="group"
                getValue="id"
                options={medicationCategoryList}
                rawLabel
                label={intl.formatMessage({ id: 'drugUsage.groupDrug' })}
                placeholder="drugUsage.groupDrugEnter"
                getLabel="name"
                disabled={readonly}
                clearButton
              />
            </Box>
            <Box width="300px">
              <AutocompleteInput
                key={'medication'}
                form={form}
                name="medication"
                getValue={(option) => option?.id!}
                options={dataMedication}
                rawLabel
                label={intl.formatMessage({ id: 'encounterPrescription.drugName' })}
                placeholder="encounterPrescription.drugNameEnter"
                getLabel={(option) => option?.name!}
                disabled={readonly}
                onChange={() => {
                  const medication = form.getValues('medication');
                  if (medication) {
                    onOpenAddDrugDialog();
                    setSelectedMedication(medication);
                  }
                }}
                getOptionDisabled={(option) => some(medicationUsages, (item) => item.medicationId === option?.id)}
                clearButton
              />
            </Box>
          </Stack>
          <IconButton
            disabled={fields?.length === 0}
            icon={<PrintIcon sx={{ color: fields?.length !== 0 ? '#307BFF' : '#9E9ED0' }} />}
            onClick={onOpenPrintDialog}
          />
        </Stack>
        <Table columns={columns} dataKey={'id'} data={fields} options={TABLE_OPTIONS} />
        {selectedMedication && (
          <AddDrugUsageDialog
            open={openAddDrugDialog}
            indicationRequests={indicationRequests || []}
            medication={selectedMedication}
            onSubmit={(data) =>
              onAddMedicationUsageMutate({
                url: API_SERVER.medicationUsage.create(),
                method: 'POST',
                data: {
                  medicationId: selectedMedication?.id,
                  quantity: 1,
                  isFree: false,
                  referenceId: encounter?.id,
                  type: MEDICATION_USAGE_TYPE.ENCOUNTER,
                  indicationRequestId: data?.indicationRequest?.id,
                  intendedRoute: data?.intendedRoute?.value,
                  cancellationQuantity: 0,
                },
              })
            }
            onClose={onCloseAddDrugDialog}
          />
        )}
        <DrugUsagePrintDialog
          open={openPrintDialog}
          onClose={onClosePrintDialog}
          medicationUsages={medicationUsages || []}
        />
      </Box>
      <DialogCustom
        open={openNoteDialog}
        maxWidth="xs"
        keepMounted={false}
        onClose={onCloseNoteDialog}
        PaperProps={{
          style: { minWidth: 682 },
        }}
        title={
          <Typography variant="h6" color="primary">
            <FormattedMessage id="indication.addNewNote" />
          </Typography>
        }
      >
        <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
          <TextArea form={form} name="note" placeholder="room.noteEnter" sx={{ padding: '20px' }} />
        </Stack>
        <Stack alignItems="flex-end" justifyContent="flex-end" padding={1}>
          <Button onClick={handleChange}>
            <FormattedMessage id="save" />
          </Button>
        </Stack>
      </DialogCustom>
    </>
  );
};

export default MedicineInUseSession;
