import { Add, Check, Delete } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../../common/api';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom from '../../../common/component/TableCustom';
import { some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import { axiosThunk } from 'modules/common/redux/axios';
import { PrescriptionFormTemplate, PrescriptionFormTemplateSchema } from 'modules/schema';
import { useDeleteMutate } from 'modules/common/hook/useMutate';
import PrescriptionFormData, { PrescriptionForm } from './PrescriptionFormData';
import { chain, keyBy } from 'lodash';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

interface IFormListProps {}

const FormList: React.FunctionComponent<IFormListProps> = () => {
  const { dispatch, openSnackbar, intl, appState } = useGeneralHook();
  const [formData, setFormData] = React.useState<PrescriptionForm>({
    name: '',
    presNote: '',
    list: [],
  });

  const intendedRouteMap = React.useMemo(
    () => keyBy(appState.common.intendedRoutes, 'value'),
    [appState.common.intendedRoutes],
  );

  const { data: templates, revalidate: templatesRevalidate } = useFetch(API_SERVER.prescriptionFormTemplate.getAll());

  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.INVENTORY }),
  );

  const medicationMap = React.useMemo(
    () =>
      chain(medicationCategoryList)
        .flatMap((category) =>
          category.medicationList?.map((ele) => ({
            ...ele,
            groupName: category.name,
          })),
        )
        .keyBy('id')
        .value(),
    [medicationCategoryList],
  );

  const formatTemplate = React.useCallback(
    (template: PrescriptionFormTemplate) => {
      return {
        id: String(template.id),
        name: template.name,
        presNote: template.advice,
        list:
          template?.detailTemplateList?.map((ele) => ({
            ...ele,
            ...medicationMap[ele.medicationId!],
            id: ele?.id,
            templateDetailId: ele.id,
            instruction: ele.usage,
            intendedRoute: intendedRouteMap[ele.intendedRoute!],
            intendedRouteOption: medicationMap[ele.medicationId!]?.intendedRoute || [],
            status: 'ACTIVE',
          })) || [],
      };
    },
    [medicationMap, intendedRouteMap],
  );

  const listTemplate = React.useMemo(() => {
    return templates?.map((template) => formatTemplate(template));
  }, [templates, formatTemplate]);

  const drugData = React.useMemo(() => {
    return (
      medicationCategoryList?.map((category) => ({
        id: category.id,
        title: category.name,

        items:
          category?.medicationList?.map((ele) => ({
            ...ele,
            groupName: category.name,
            intendedRoute: null,
            intendedRouteOption: ele.intendedRoute,
          })) || [],
      })) || []
    );
  }, [medicationCategoryList]);

  const onDeleteMutate = useDeleteMutate({
    confirmMessage: 'confirmDeleteTitle',
    onSuccess: templatesRevalidate,
  });

  const onSubmit = React.useCallback(
    async (value: PrescriptionForm) => {
      if (value.id) {
        try {
          dispatch(setLoading(true));
          const { data } = await dispatch(
            axiosThunk({
              url: API_SERVER.prescriptionFormTemplate.update(parseInt(value.id!)),
              method: 'put',
              data: {
                name: value.name,
                advice: value.presNote,
                detailTemplateList: [
                  ...(value?.list?.map((ele) => ({
                    id: ele.templateDetailId,
                    medicationId: ele.medicationId,
                    quantity: ele.quantity,
                    intendedRoute: ele.intendedRoute.value,
                    usage: ele.instruction,
                    status: 'ACTIVE',
                  })) || []),
                  ...(formData?.list
                    ?.filter((ele) => !value?.list?.some((item) => item.id === ele.id))
                    ?.map((ele) => ({
                      id: ele.templateDetailId,
                      medicationId: ele.medicationId,
                      quantity: ele.quantity,
                      intendedRoute: ele.intendedRoute.value,
                      usage: ele.instruction,
                      status: 'INACTIVE',
                    })) || []),
                ],
              },
            }),
          );
          await templatesRevalidate();
          setFormData(formatTemplate(PrescriptionFormTemplateSchema.parse(data)));
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        try {
          dispatch(setLoading(true));
          const { data } = await dispatch(
            axiosThunk({
              url: API_SERVER.prescriptionFormTemplate.create(),
              method: 'post',
              data: {
                name: value.name,
                advice: value.presNote,
                detailTemplateList:
                  value?.list?.map((ele) => ({
                    medicationId: ele.medicationId,
                    quantity: ele.quantity,
                    intendedRoute: ele.intendedRoute.value,
                    usage: ele.instruction,
                    status: 'ACTIVE',
                  })) || [],
              },
            }),
          );

          await templatesRevalidate();
          setFormData(formatTemplate(PrescriptionFormTemplateSchema.parse(data)));
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch, formData?.list, formatTemplate, intl, openSnackbar, templatesRevalidate],
  );

  return (
    <>
      <Paper style={{ padding: 16, flex: 1, height: '100%', overflow: 'auto', marginRight: 16 }}>
        <TableCustom
          dataSource={listTemplate}
          caption
          rowProps={(record: any, index) => ({
            onClick: async () => {
              setFormData(record);
            },
            style: { cursor: 'pointer' },
          })}
          columns={[
            {
              title: 'report.name',
              dataIndex: 'name',
            },
            {
              title: 'action',
              align: 'right',
              minWidth: 120,
              render: (record: some) => {
                return (
                  <Box display={'flex'} alignItems="center" gap={1} justifyContent="flex-end">
                    {formData?.id === record?.id && <Check color="success" />}
                    <IconButtonTitle
                      size="small"
                      title="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteMutate({
                          url: API_SERVER.prescriptionFormTemplate.delete(record?.id),
                          method: 'delete',
                        });
                      }}
                    >
                      <Delete />
                    </IconButtonTitle>
                  </Box>
                );
              },
            },
          ]}
        />
        <Box position="sticky" zIndex={2} bgcolor={'background.paper'} bottom={0} top={'unset'}>
          <Button
            variant="text"
            color="primary"
            fullWidth
            startIcon={<Add />}
            style={{ marginTop: 8 }}
            onClick={() => setFormData({ name: '', presNote: '', list: [] })}
          >
            <FormattedMessage id="add" />
          </Button>
        </Box>
      </Paper>
      <Paper style={{ padding: 16, flex: 2, height: '100%', overflow: 'auto' }}>
        <Typography variant="h6">
          <FormattedMessage id={true ? 'report.edit' : 'report.addNew'} />
        </Typography>

        <PrescriptionFormData formData={formData} submit={onSubmit} listTemplate={templates} drugData={drugData} />
      </Paper>
    </>
  );
};

export default FormList;
