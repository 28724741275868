import { Checkbox, CheckboxProps } from '@mui/material';
import { FormCommonProps, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';
import { getError } from './utils';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  placeholder?: string;
  rawPlaceholder?: boolean;
  rawError?: boolean;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<CheckboxProps, 'name' | 'ref' | 'placeholder' | 'error' | 'id' | 'multiline' | 'form'>;

const CheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  form,
  placeholder,
  rawPlaceholder = false,
  label,
  rawLabel = false,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  hideError = false,
  required,
  fullWidth = true,
  disabled = false,
  rawError = false,
  ...restProps
}: Props<TFieldValues, TName>) => {
  const id = useId();
  return (
    <FormControl
      error={getError(form, name)}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      fullWidth={fullWidth}
      controlProps={{ ...controlProps, sx: { minWidth: '42px', ...controlProps?.sx } }}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      disabled={disabled}
      rawError={rawError}
    >
      <Controller
        name={name}
        control={form.control}
        render={({ field: { name, onChange, onBlur, ref, value } }) => (
          <Checkbox
            {...restProps}
            id={id}
            disabled={disabled}
            name={name}
            checked={value}
            onChange={(event) => {
              onChange(event);
              restProps?.onChange && restProps.onChange(event, value);
            }}
            onBlur={(event) => {
              onBlur();
              restProps?.onBlur && restProps.onBlur(event);
            }}
            ref={ref}
          />
        )}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default CheckboxInput;
