import React, { useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import TextInput from 'modules/common/component/form/TextInput';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { MEDICATION_UNIT } from 'modules/common/apiConstants';
import NumberInput from 'modules/common/component/form/NumberInput';
import { Option } from 'modules/common/type';

const SubMedicationFields = ({ index, form, unitInventoryDetail, unitQuantityList }) => {
  const { intl } = useGeneralHook();
  const {
    fields: subFields,
    append: appendSubDetail,
    remove: removeSubDetail,
  } = useFieldArray({
    control: form.control,
    name: `inventoryExportDetails.${index}.subMedication`,
  });

  useEffect(() => {
    if (subFields.length === 0) {
      appendSubDetail({
        unit: undefined,
        quantity: 1,
        reason: '',
      });
    }
  }, [subFields, appendSubDetail]);

  const unitMedication = useMemo(() => {
    if (!unitInventoryDetail) {
      return undefined;
    }

    return unitInventoryDetail.map((item) => {
      const unit = MEDICATION_UNIT[item.fromUnit];
      return {
        ...item,
        value: item.fromUnit,
        label: unit && unit.label,
      };
    });
  }, [unitInventoryDetail]);

  return (
    <Box style={{ marginLeft: '20px' }}>
      {subFields.map((subField, subIndex) => (
        <Box key={subField.id} style={{ display: 'flex', gap: '10px' }}>
          <Grid container xs={12} spacing={2} padding={1}>
            <Grid item xs={12} sm={3}>
              <NumberInput
                form={form}
                name={`inventoryExportDetails.${index}.subMedication.${subIndex}.quantity`}
                label="quantity"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AutocompleteInput
                form={form}
                name={`inventoryExportDetails.${index}.subMedication.${subIndex}.unit`}
                fullWidth
                label="indication.unit"
                placeholder="supply.unitEnter"
                required
                options={unitMedication || Object.values(MEDICATION_UNIT)}
                getLabel={(record: Option) => record?.label && intl.formatMessage({ id: record.label })}
                getValue="value"
                renderLabel={(record) => intl.formatMessage({ id: record?.label })}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextInput
                form={form}
                name={`inventoryExportDetails.${index}.subMedication.${subIndex}.reason`}
                label="reason.export.cancel"
                placeholder="drugUsage.reasonCancelEnter"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2} spacing={4} container paddingTop={4}>
            <Grid item xs={12} sm={4}>
              <IconButtonTitle
                onClick={() =>
                  appendSubDetail({
                    unit: undefined,
                    quantity: 1,
                    note: '',
                  })
                }
                title="add"
                size="small"
              >
                <AddIcon sx={{ color: '#78909C' }} />
              </IconButtonTitle>
            </Grid>
            <Grid item xs={12} sm={4}>
              {subFields.length > 1 && (
                <IconButtonTitle onClick={() => removeSubDetail(subIndex)} title="delete" size="small">
                  <DeleteIcon sx={{ color: '#78909C' }} />
                </IconButtonTitle>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};
export default SubMedicationFields;
