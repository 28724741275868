import { Box } from '@mui/material';
import * as React from 'react';
import FormList from '../component/prescriptionTemplate/FormList';

interface Props {}

const ManagePrescriptionTemplate: React.FunctionComponent<Props> = (props) => {
  return (
    <Box display="flex" flex={1} overflow="hidden">
      <FormList />
    </Box>
  );
};

export default ManagePrescriptionTemplate;
