import { Box, Stack, Typography } from '@mui/material';
import { EncounterDetailProps } from '../../type';
import { API_SERVER } from 'modules/common/api';
import { useCreateMutate, useDeleteMutate, useDialog, useFetch, useUpdateMutate } from 'modules/common/hook';
import {
  MEDICATION_CATEGORY_SCOPE,
  MEDICATION_UNIT,
  PRESCRIPTION_DETAIL_TYPE,
  PRESCRIPTION_TYPE,
} from 'modules/common/apiConstants';
import {
  CodeSystem,
  Medication,
  Prescription,
  PrescriptionDetail,
  PrescriptionDetailClone,
  PrescriptionFormTemplate,
} from 'modules/schema';
import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { ValueType } from 'modules/common/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { keyBy, some, uniqBy } from 'lodash';
import NumberInput from 'modules/common/component/form/NumberInput';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import MedicationInfo from './MedicationInfo';
import PrescriptionInfo from './PrescriptionInfo';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import PrintIcon from '@mui/icons-material/Print';
import PrescriptionPrintExamDoctorDialog from './PrintDialog/PrescriptionPrintExamDoctorDialog';
import IconButton from 'modules/common/component/IconButton';
import PrescriptionOldInfo from './PrescriptionOldInfo';
import { useDebouncedCallback } from 'use-debounce';
import MedicationInstructionInput from 'modules/common/component/form/MedicationInstructionInput';
import { FormattedMessage } from 'react-intl';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import PatientPrescriptionInput from './PatientPrescriptionInput';
import { checkFields } from '../../const';
import TextArea from 'modules/common/component/form/TextArea';

const PRESCRIPTION_TYPE_FILTER = {
  SINGLE_MEDICATION: { value: 'NEW', label: 'encounterPrescription.addSingleMedicationRequest' },
  PRESCRIPTION_TEMPLATE: { value: 'TEMPLATE', label: 'encounter.managePrescriptionTemplate' },
  OLD_PRESCRIPTION: { value: 'OLD', label: 'dental.prescription.label.oldPrescription' },
};

type PrescriptionDetailType = Omit<
  PrescriptionDetail,
  'medication' | 'intendedRoute' | 'medicationName' | 'quantity' | 'dayNumber' | 'unitPerDay'
> & {
  medication?: Omit<Medication, 'approvalDate' | 'updatedTime' | 'inventoryStatus' | 'category'>;
  intendedRoute?: CodeSystem;
  medicationName?: string;
  quantity?: number | null;
  dayNumber?: number | null;
  unitPerDay?: number | null;
};

type PrescriptionDetailListType = Omit<PrescriptionDetail, 'medication'> & {
  medication?: Omit<Medication, 'approvalDate' | 'updatedTime' | 'inventoryStatus' | 'category'>;
  intendedRoute?: CodeSystem;
};

type MedicationType =
  | (Omit<
      Medication,
      'approvalDate' | 'intendedRoute' | 'unitConverterList' | 'updatedTime' | 'inventoryStatus' | 'category'
    > & {
      categoryName: string | undefined;
      value: string | undefined;
      intendedRoute?: CodeSystem[];
    })
  | null;

interface Form {
  type: ValueType<typeof PRESCRIPTION_TYPE_FILTER> | null;
  prescriptionTemplate: Pick<PrescriptionFormTemplate, 'id' | 'name' | 'detailTemplateList' | 'advice'> | null;
  patientPrescription:
    | (Omit<Prescription, 'createdTime' | 'encounter' | 'dentalExam' | 'prescriptionDetailList'> & {
        prescriptionDetailList?: PrescriptionDetailListType[];
      })
    | null;
  medication: MedicationType[];
  prescriptionDetails: PrescriptionDetailType[];
  advice: string;
  medicationCategory: CodeSystem | null;
}

const requiredFields = ['quantity', 'intendedRoute', 'usage', 'unit'];
const PrescriptionSession = ({ encounter, readonly }: EncounterDetailProps) => {
  const form = useForm<Form>({
    defaultValues: {
      type: PRESCRIPTION_TYPE_FILTER.SINGLE_MEDICATION,
      prescriptionTemplate: null,
      patientPrescription: null,
      medication: [],
      prescriptionDetails: [],
      advice: '',
      medicationCategory: null,
    },
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'prescriptionDetails',
    keyName: 'key',
  });
  const prescriptionType = form.watch('type');
  const { intl, confirmDialog, appState } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [openEncounterForm, onOpenEncounterForm, onCloseEncounterForm] = useDialog();
  const allIntendedRoutes = appState.common.intendedRoutes;
  const { data: medicationCategories, isValidating } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({
      scope: MEDICATION_CATEGORY_SCOPE.INVENTORY,
      isShowQuantityInventory: true,
    }),
  );

  const {
    data: prescription,
    revalidate: revalidatePrescription,
    isValidating: isValidatingPrescription,
  } = useFetch(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: encounter?.id!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    }),
  );

  useEffect(() => {
    if (prescription && !isValidatingPrescription) {
      form.setValue('advice', prescription.advice || '');
      form.setValue(
        'prescriptionDetails',
        prescription.prescriptionDetailList?.map((item) => ({
          ...item,
          intendedRoute: allIntendedRoutes.find((i) => i.value === item.intendedRoute),
        })) || [],
      );
    }
  }, [allIntendedRoutes, form, isValidatingPrescription, prescription]);

  const { data: patientPrescriptions } = useFetch(
    API_SERVER.dental.getDentalPrescriptionsPatient({
      referenceId: encounter.id!,
      patientId: encounter.patientId!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    }),
    { enabled: prescriptionType?.value === PRESCRIPTION_TYPE_FILTER.OLD_PRESCRIPTION.value },
  );

  const prescriptionTemplateMap = useSelector((state: AppState) => state.common.prescrptionFormTemplate);

  const medicationMap = useMemo(() => {
    return keyBy(
      medicationCategories?.flatMap(
        (category) =>
          category.medicationList?.map((medication) => ({
            ...medication,
            categoryName: category.name,
            quantity: medication.quantity,
          })) || [],
      ) || [],
      'id',
    );
  }, [medicationCategories]);

  // create dental prescriptions clone
  const addPrescriptionsClone = useCreateMutate({
    onSuccess: () => {
      revalidatePrescription();
      form.reset();
    },
  });
  const prescriptionsClone = async (data: PrescriptionDetailClone[], advice: string) => {
    const dataClone = {
      referenceId: encounter?.id!,
      patientId: encounter?.patientId,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
      prescriptionDetailVOList: data,
      advice: advice,
    };
    if (data) {
      addPrescriptionsClone({
        url: API_SERVER.dental.createDentalPrescriptionsClone(),
        method: 'POST',
        data: dataClone,
      });
    }
  };

  // tao nhanh don thuoc tu mau don thuoc
  const onChangePrescriptionTemplate = async () => {
    const selectTemplate = form.getValues('prescriptionTemplate');
    const prescriptionClone: PrescriptionDetailClone[] = [];
    if (selectTemplate) {
      if (prescription && prescription?.prescriptionDetailList?.length !== 0) {
        const confirm = await promptConfirmation({
          title: intl.formatMessage({ id: 'encounterPrescription.confirmTile' }),
          content: intl.formatMessage({ id: 'encounterPrescription.confirmAddTemplate' }),
          okId: 'ok',
        });
        if (confirm) {
          selectTemplate?.detailTemplateList?.forEach(async (i) => {
            const medication = Object.values(medicationMap).find((item) => item.id === i?.medicationId);
            prescriptionClone.push({
              patientId: encounter?.patientId!,
              referenceId: encounter?.id!,
              prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
              medicationId: i?.medicationId,
              medicationName: '',
              quantity: i?.quantity,
              unit: medication?.numeratorUnit,
              intendedRoute: i?.intendedRoute,
              type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
              usage: i?.usage,
            });
          });
          prescriptionsClone(prescriptionClone, selectTemplate?.advice || '');
        }
        close();
      } else {
        selectTemplate?.detailTemplateList?.forEach(async (i) => {
          const medication = Object.values(medicationMap).find((item) => item.id === i?.medicationId);
          prescriptionClone.push({
            patientId: encounter?.patientId!,
            referenceId: encounter?.id!,
            prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
            medicationId: i?.medicationId,
            medicationName: '',
            quantity: i?.quantity,
            unit: medication?.numeratorUnit,
            intendedRoute: i?.intendedRoute,
            type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
            usage: i?.usage,
          });
        });
        prescriptionsClone(prescriptionClone, selectTemplate?.advice || '');
      }
    }
  };

  // tao nhanh don thuoc tu don thuoc cu
  const addTemplateHistory = async () => {
    const selectMedicationPatient = form.getValues('patientPrescription');
    const prescriptionClone: PrescriptionDetailClone[] = [];
    if (selectMedicationPatient) {
      if (prescription && prescription?.prescriptionDetailList?.length !== 0) {
        const confirm = await promptConfirmation({
          title: intl.formatMessage({ id: 'encounterPrescription.confirmTile' }),
          content: intl.formatMessage({ id: 'encounterPrescription.confirmAddTemplate' }),
          okId: 'ok',
        });
        if (confirm) {
          selectMedicationPatient?.prescriptionDetailList?.forEach(async (i) => {
            prescriptionClone.push({
              patientId: encounter?.patientId,
              dayNumber: undefined,
              intendedRoute: i?.intendedRoute,
              medicationId: i?.medicationId,
              medicationName: i?.medicationName,
              prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
              quantity: i?.quantity,
              referenceId: encounter?.id!,
              type: i?.type,
              unit: i.medication?.numeratorUnit,
              unitPerDay: undefined,
              usage: i?.usage,
            });
          });
          prescriptionsClone(prescriptionClone, selectMedicationPatient?.advice || '');
        }
        close();
      } else {
        selectMedicationPatient?.prescriptionDetailList?.forEach(async (i) => {
          prescriptionClone.push({
            patientId: encounter?.patientId,
            dayNumber: undefined,
            intendedRoute: i?.intendedRoute,
            medicationId: i?.medicationId,
            medicationName: i?.medicationName,
            prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
            quantity: i?.quantity,
            referenceId: encounter?.id!,
            type: i?.type,
            unit: i.medication?.numeratorUnit,
            unitPerDay: undefined,
            usage: i?.usage,
          });
        });
        prescriptionsClone(prescriptionClone, selectMedicationPatient?.advice || '');
      }
    }
  };

  const updateQuantity = useCallback(
    (index: number, dayNumber: number, unitPerDay: number) => {
      form.setValue(`prescriptionDetails.${index}.quantity`, dayNumber * unitPerDay);
    },
    [form],
  );

  const typeForm = form.watch('type');
  const medicationCategory = form.watch('medicationCategory');

  useEffect(() => {
    form.resetField('prescriptionTemplate');
    form.resetField('patientPrescription');
    form.resetField('medication');
    form.resetField('medicationCategory');
  }, [form, typeForm]);

  const MEDICATION_FILTER = {
    [PRESCRIPTION_TYPE_FILTER.PRESCRIPTION_TEMPLATE.value]: (
      <AutocompleteInput
        key="prescriptionTemplate"
        form={form}
        name="prescriptionTemplate"
        options={Object.values(prescriptionTemplateMap || {})}
        getLabel="name"
        renderLabel={(option) => <PrescriptionInfo medicationMap={medicationMap} prescriptionTemplate={option} />}
        getValue="id"
        placeholder="encounter.encounterDetail.input.placeholder.prescriptionTemplate"
        hideError
        label={PRESCRIPTION_TYPE_FILTER.PRESCRIPTION_TEMPLATE.label}
        onChange={onChangePrescriptionTemplate}
        disabled={readonly}
      />
    ),
    [PRESCRIPTION_TYPE_FILTER.OLD_PRESCRIPTION.value]: (
      <AutocompleteInput
        key="patientPrescription"
        form={form}
        name="patientPrescription"
        options={patientPrescriptions}
        getLabel="createdTimeFormat"
        getValue="id"
        placeholder="dental.prescription.label.oldPrescription"
        hideError
        label={PRESCRIPTION_TYPE_FILTER.OLD_PRESCRIPTION.label}
        onChange={addTemplateHistory}
        disabled={readonly}
        renderLabel={(option) => <PrescriptionOldInfo prescription={option} medicationMap={medicationMap} />}
      />
    ),
    [PRESCRIPTION_TYPE_FILTER.SINGLE_MEDICATION.value]: (
      <PatientPrescriptionInput
        form={form}
        name="medication"
        loading={isValidating}
        hideError
        sx={{ '& .MuiAutocomplete-inputRoot': { backgroundColor: 'transparent' } }}
        disabled={readonly}
        onAddPrescriptionDetail={(value) => {
          onAddPrescriptionDetail();
        }}
        getOptionDisabled={(option) =>
          some(prescription?.prescriptionDetailList, (item) => item.medicationId === option?.id)
        }
        medicationCategory={medicationCategory || null}
        loadingPrescription={isValidatingPrescription}
      />
    ),
  };

  const medicationSelect = form.watch('medication');
  useEffect(() => {
    if (some(medicationSelect, { id: -1 })) {
      onAddPrescriptionDetailMutate({
        url: API_SERVER.dental.createMultipleDentalPrescriptionsDetail(),
        method: 'POST',
        data: [
          {
            referenceId: encounter.id,
            patientId: encounter.patientId,
            prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
            type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL,
            medicationName: medicationSelect[0]?.value,
          },
        ],
      }).finally(() => form.resetField('medication'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounter.id, encounter.patientId, form, medicationSelect]);

  const onDeleteMutate = useDeleteMutate({
    onSuccess: () => {
      revalidatePrescription();
      form.resetField('prescriptionDetails');
    },
  });
  const onUpdatePrescriptionsDetailMutate = useUpdateMutate({
    onSuccess: () => {
      if (checkFields(form.getValues('prescriptionDetails'), requiredFields).length === 0) {
        revalidatePrescription();
      }
    },
    globalLoading: false,
  });

  const onUpdatePrescriptionsDetail = useDebouncedCallback(() => {
    const data = form
      .getValues('prescriptionDetails')
      .filter(
        (value, index) =>
          !!form.formState.dirtyFields.prescriptionDetails?.[index] && value.quantity && value.quantity !== 0,
      )
      .map((prescriptionDetail) => ({
        id: prescriptionDetail?.id,
        referenceId: encounter?.id,
        patientId: encounter?.patientId,
        prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
        medicationId: prescriptionDetail?.medicationId,
        medicationName: prescriptionDetail?.medicationName,
        quantity: prescriptionDetail?.quantity
          ? prescriptionDetail?.quantity
          : (prescriptionDetail?.dayNumber || 0) * (prescriptionDetail?.unitPerDay || 0),
        unit: prescriptionDetail?.unit,
        dayNumber:
          prescriptionDetail?.dayNumber && prescriptionDetail?.dayNumber !== 0
            ? prescriptionDetail?.dayNumber
            : undefined,
        unitPerDay:
          prescriptionDetail.unitPerDay && prescriptionDetail.unitPerDay !== 0
            ? prescriptionDetail.unitPerDay
            : undefined,
        intendedRoute: prescriptionDetail?.intendedRoute?.value,
        usage: prescriptionDetail?.usage,
        type: prescriptionDetail.type,
      }));
    if (data.length !== 0) {
      onUpdatePrescriptionsDetailMutate({
        url: API_SERVER.prescriptionDetail.updateMultiplePrescriptionDetail(),
        method: 'PUT',
        data: {
          data: data,
        },
      });
    }
  }, 2000);

  const COLUMNS: Column<typeof fields[number]>[] = [
    {
      type: 'INDEX',
      width: '30px',
      align: 'left',
      headerCellProps: { sx: { fontSize: '16px' } },
    },
    {
      title: 'encounterPrescription.drugName',
      headerCellProps: { sx: { fontSize: '16px' } },
      width: '25%',
      render: (record) => (
        <MedicationInfo
          type={record.type!}
          medication={record?.medication}
          medicationGroup={Object.values(medicationMap).find((item) => item.id === record.medicationId) || {}}
          medicationGroupName={
            Object.values(medicationMap).find((item) => item.id === record.medicationId)?.categoryName
          }
          medicationName={record.medicationName!}
        />
      ),
    },
    {
      title: 'dental.prescription.label.dayNumber',
      width: '120px',
      align: 'center',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => (
        <NumberInput
          form={form}
          name={`prescriptionDetails.${index}.dayNumber`}
          hideError
          variant="standard"
          sx={{ '& .MuiInput-root': { backgroundColor: 'transparent' }, fontSize: '16px' }}
          placeholder="dental.prescription.label.dayNumber"
          disabled={readonly}
          onValueChange={({ floatValue }) =>
            updateQuantity(index, floatValue || 0, form.getValues(`prescriptionDetails.${index}.unitPerDay`) || 0)
          }
          onChange={onUpdatePrescriptionsDetail}
          inputProps={{ sx: { textAlign: 'center' } }}
        />
      ),
    },
    {
      title: '',
      align: 'center',
      rawTitle: true,
      width: '30px',
      render: () => <span>&#10005;</span>,
    },
    {
      title: 'unit.and.day.prescription',
      align: 'center',
      width: '130px',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (_, index) => (
        <NumberInput
          form={form}
          name={`prescriptionDetails.${index}.unitPerDay`}
          hideError
          variant="standard"
          sx={{ '& .MuiInput-root': { backgroundColor: 'transparent' }, fontSize: '16px' }}
          placeholder="unit.and.day.prescription"
          disabled={readonly}
          onValueChange={({ floatValue }) =>
            updateQuantity(index, form.getValues(`prescriptionDetails.${index}.dayNumber`) || 0, floatValue || 0)
          }
          onChange={onUpdatePrescriptionsDetail}
          inputProps={{ sx: { textAlign: 'center' } }}
        />
      ),
    },
    {
      title: '',
      align: 'center',
      rawTitle: true,
      width: '30px',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: () => <span>&#61;</span>,
    },
    {
      title: 'quantity',
      width: '120px',
      align: 'center',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => (
        <NumberInput
          form={form}
          name={`prescriptionDetails.${index}.quantity`}
          hideError
          variant="standard"
          sx={{ '& .MuiInput-root': { backgroundColor: 'transparent' }, fontSize: '16px' }}
          placeholder="quantity"
          disabled={readonly}
          onValueChange={({ floatValue }) => {
            form.setValue(`prescriptionDetails.${index}.dayNumber`, null);
            form.setValue(`prescriptionDetails.${index}.unitPerDay`, null);
          }}
          onChange={onUpdatePrescriptionsDetail}
          required
          showError
          inputProps={{ sx: { textAlign: 'center' } }}
        />
      ),
      required: true,
    },
    {
      title: 'encounterPrescription.unit',
      width: '150px',
      headerCellProps: { sx: { fontSize: '16px' } },
      required: true,
      render: (record, index) => {
        return record.type === 'MEDICATION_EXTERNAL' ? (
          <AutocompleteInput
            form={form}
            name={`prescriptionDetails.${index}.unit`}
            options={Object.values(MEDICATION_UNIT)}
            getValue={(option) => (typeof option === 'string' ? option : intl.formatMessage({ id: option.label }))}
            getLabel={(option) => (typeof option === 'string' ? option : intl.formatMessage({ id: option.label }))}
            hideError
            InputProps={{ variant: 'standard' }}
            sx={{
              '& .MuiInput-root': { backgroundColor: 'transparent' },
              fontSize: '16px',
              textAlignLast: 'end',
            }}
            onChange={async (...params) => {
              const [, option] = params;
              if (typeof option === 'object') {
                if (option) {
                  await form.setValue(`prescriptionDetails.${index}.unit`, intl.formatMessage({ id: option.label }));
                }
              } else {
                await form.setValue(`prescriptionDetails.${index}.unit`, intl.formatMessage({ id: option }));
              }
              await onUpdatePrescriptionsDetail();
            }}
            showError
          />
        ) : (
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            {record?.unit}
          </Typography>
        );
      },
      align: 'center',
    },
    {
      title: 'encounterPrescription.intendedRoute',
      width: '200px',
      headerCellProps: { sx: { fontSize: '16px' } },
      required: true,
      render: (record, index) => {
        const medicationOption =
          Object.values(medicationMap).find((item) => item?.id === record.medicationId)?.intendedRoute ?? [];
        if (
          record.intendedRoute &&
          !medicationOption?.some(
            (item) => item.value === record?.intendedRoute?.value || item.label === record?.intendedRoute?.label,
          )
        ) {
          medicationOption?.push(record?.intendedRoute);
        }

        return (
          <SelectInput
            form={form}
            name={`prescriptionDetails.${index}.intendedRoute`}
            options={record.type === 'MEDICATION_EXTERNAL' ? allIntendedRoutes : medicationOption}
            getValue="value"
            renderLabel="label"
            rawOptionLabel
            hideError
            variant="standard"
            sx={{ backgroundColor: 'transparent', fontSize: '16px' }}
            placeholder="encounterPrescription.intendedRoute"
            disabled={readonly}
            onChange={onUpdatePrescriptionsDetail}
            showError
            rawLabel
          />
        );
      },
    },
    {
      title: 'encounterPrescription.instruction',
      width: '350px',
      headerCellProps: { sx: { fontSize: '16px' } },
      required: true,
      render: (_, index) => {
        return (
          <MedicationInstructionInput
            form={form}
            name={`prescriptionDetails.${index}.usage`}
            disabled={readonly}
            onChange={onUpdatePrescriptionsDetail}
          />
        );
      },
    },
    {
      title: 'action',
      width: '100px',
      headerCellProps: { sx: { fontSize: '16px' } },
      align: 'center',
      render: (record, index) => (
        <IconButtonTitle
          onClick={async () => {
            onDeleteMutate(
              {
                url: API_SERVER.dental.deleteDentalPrescriptionsDetail(record?.id!),
                method: 'DELETE',
              },
              { confirmMessage: intl.formatMessage({ id: 'confirmDelete' }, { name: record?.medication?.name }) },
            );
          }}
          title="delete"
          size="small"
          disabled={readonly}
        >
          <Delete sx={{ color: '#78909C' }} />
        </IconButtonTitle>
      ),
    },
  ];
  const updateDoctorAdviceMutate = useCreateMutate({
    onSuccess: () => {
      revalidatePrescription();
      form.setValue('advice', form.getValues('advice'));
    },
    globalLoading: false,
  });
  const onUpdateDoctorAdvice = useDebouncedCallback((value) => {
    if (extractHTMLContent(form.watch('advice')) !== extractHTMLContent(prescription?.advice)) {
      updateDoctorAdviceMutate({
        url: API_SERVER.dental.createDentalPrescriptions(),
        method: 'POST',
        data: {
          patientId: encounter?.patientId,
          advice: form.watch('advice'),
          referenceId: encounter?.id!,
          type: PRESCRIPTION_TYPE.ENCOUNTER,
        },
      });
    }
  }, 1500);

  const disableIconPrint = useCallback(() => {
    if (readonly && fields?.length !== 0) {
      return false;
    }
    return checkFields(form.watch('prescriptionDetails'), requiredFields).length > 0 || fields?.length === 0;
  }, [fields?.length, form, readonly]);

  const optionCategory = useMemo(() => {
    return uniqBy(Object.values(medicationMap), 'categoryName').map((item) => ({
      value: item.categoryName,
      label: item.categoryName,
    }));
  }, [medicationMap]);

  const onAddPrescriptionDetailMutate = useUpdateMutate({
    onSuccess: () => {
      revalidatePrescription();
      form.resetField('medication');
      form.resetField('medicationCategory');
    },
  });
  const onAddPrescriptionDetail = useCallback(async () => {
    const medication = form.getValues('medication');
    if (medication.length !== 0) {
      const dataPost = medication.map((item) =>
        item?.id !== -1
          ? {
              patientId: encounter?.patientId,
              intendedRoute:
                Object.values(item?.intendedRoute || {})?.length > 0
                  ? Object.values(item?.intendedRoute || {})[0].value
                  : '',
              medicationId: item?.id,
              medicationName: item?.name,
              prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
              referenceId: encounter?.id!,
              type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
              unit: item?.numeratorUnit,
            }
          : {
              referenceId: encounter.id,
              patientId: encounter.patientId,
              prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
              type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL,
              medicationName: item?.value,
            },
      );

      onAddPrescriptionDetailMutate({
        url: API_SERVER.dental.createMultipleDentalPrescriptionsDetail(),
        method: 'POST',
        data: dataPost,
      });
    }
  }, [encounter.id, encounter.patientId, form, onAddPrescriptionDetailMutate]);

  return (
    <Stack direction="column" gap="16px">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <Box width="300px">
            <SelectInput
              form={form}
              name="type"
              options={Object.values(PRESCRIPTION_TYPE_FILTER)}
              getValue="value"
              renderLabel="label"
              placeholder="encounterPrescription.groupDrugEnter"
              label="group.prescription"
              hideError
              disabled={readonly}
              sx={{ fontSize: '16px' }}
            />
          </Box>
          {prescriptionType?.value === PRESCRIPTION_TYPE_FILTER.SINGLE_MEDICATION.value && (
            <Box width="300px">
              <AutocompleteInput
                key="medicationCategory"
                form={form}
                name="medicationCategory"
                options={optionCategory}
                getLabel="value"
                getValue="value"
                placeholder="drugUsage.groupDrugEnter"
                loading={isValidating}
                hideError
                label="drugUsage.groupDrug"
                disabled={readonly}
                clearButton
              />
            </Box>
          )}
          <Box width="300px">{prescriptionType && MEDICATION_FILTER[prescriptionType.value]}</Box>
        </Stack>
        <IconButton
          disabled={disableIconPrint()}
          icon={<PrintIcon sx={{ color: disableIconPrint() ? '#9E9ED0' : '#307BFF' }} />}
          onClick={onOpenEncounterForm}
        />
      </Stack>
      <Table data={fields} dataKey="id" columns={COLUMNS} />
      {checkFields(form.getValues('prescriptionDetails'), requiredFields).length > 0 && (
        <Typography variant="caption" color="error" sx={{ fontSize: '12px', cursor: 'default' }}>
          <FormattedMessage id="validateFields.prescription.update" />
        </Typography>
      )}
      <TextArea
        label="dental.dentalDetail.printForm.medicationNode.title"
        disabled={readonly}
        form={form}
        placeholder="encounterPrescription.noteEnter"
        onBlur={onUpdateDoctorAdvice}
        name="advice"
      />
      <PrescriptionPrintExamDoctorDialog
        open={openEncounterForm}
        onClose={onCloseEncounterForm}
        presNote={form.getValues('advice')}
        prescription={prescription!}
      />
    </Stack>
  );
};

export default PrescriptionSession;
