export const THERAPY_STATUS = {
  NEW: {
    value: 'NEW',
    color: 'primary.main',
    label: 'therapy.management.status.new',
  },
  PROCESSING: {
    value: 'PROCESSING',
    color: '#FFB300',
    label: 'therapy.management.status.processing',
  },
  FINISHED: {
    value: 'FINISHED',
    color: '#43A047',
    label: 'therapy.management.status.finished',
  },
  CANCELLED: {
    value: 'CANCELLED',
    color: '#C62828',
    label: 'therapy.management.status.cancelled',
  },
} as const;

export const THERAPY_STATUS_COLOR = {
  NEW: {
    value: 'NEW',
    color: '#D9D9D9',
    label: 'therapy.management.status.new',
  },
  PROCESSING: {
    value: 'PROCESSING',
    color: '#FFA000',
    label: 'therapy.management.status.processing',
  },
  FINISHED: {
    value: 'FINISHED',
    color: '#C62828',
    label: 'therapy.management.status.finished',
  },
  CANCELLED: {
    value: 'CANCELLED',
    color: '#C62828',
    label: 'therapy.management.status.cancelled',
  },
} as const;

export const ENCOUNTER_STATUS = {
  INPROGRESS: {
    value: 'INPROGRESS',
    color: 'warning.main',
    label: 'encounterStatus.in-progress',
  },
  ARRIVED: {
    value: 'ARRIVED',
    color: 'primary.main',
    label: 'encounterStatus.arrived',
  },
  FINISHED: {
    value: 'FINISHED',
    color: 'success.main',
    label: 'encounterStatus.finished',
  },
  ERROR: {
    value: 'ERROR',
    color: 'error.main',
    label: 'encounterStatus.entered-in-error',
  },
  CANCELLED: {
    value: 'CANCELLED',
    color: '',
    label: 'encounterStatus.cancelled',
  },
} as const;

export const SYSTEM_CATEGORY_TYPE = {
  PRICE_LIST: 'PRICE_LIST',
  GROUP: 'GROUP',
} as const;

export const SYSTEM_CATEGORY_SCOPE = {
  HEALTH_CHECK_SERVICE: { value: 'HEALTH_CHECK_SERVICE', label: 'systemCategory.healthCheck' }, // Khám bệnh
  LABORATORY_SERVICE: { value: 'LABORATORY_SERVICE', label: 'systemCategory.laboratory' }, // Xét nghiệm
  RADIOLOGY_SERVICE: { value: 'RADIOLOGY_SERVICE', label: 'systemCategory.radiology' }, // Chuẩn đoán hình ảnh
  SURGICAL_SERVICE: { value: 'SURGICAL_SERVICE', label: 'systemCategory.surgical' }, // Thủ thuật
  OTHER_SERVICE: { value: 'OTHER_SERVICE', label: 'systemCategory.other' }, // Dịch vụ chuyển môn khác
  MEDICAL_SUPPLIES: { value: 'MEDICAL_SUPPLIES', label: 'systemCategory.medicalSupplies' }, // Vật tư y tế
  PHYSICS_ROOM: { value: 'PHYSICS_ROOM', label: 'systemCategory.physicsRoom' }, // Phòng khám
  MEDICAL_EQUIPMENT: { value: 'MEDICAL_EQUIPMENT', label: 'systemCategory.medicalEquipment' }, // Máy móc thiết bị y tế
  THERAPY_SERVICE: { value: 'THERAPY_SERVICE', label: 'systemCategory.therapy' }, // Liệu trình
} as const;

export const GENDER = {
  OTHER: {
    value: 'OTHER',
    label: 'carePlan.other',
  },
  MALE: {
    value: 'MALE',
    label: 'male',
  },
  FEMALE: {
    value: 'FEMALE',
    label: 'female',
  },
  UNKNOWN: {
    value: 'UNKNOWN',
    label: 'gender.empty',
  },
};

export const ORDER_TYPE = {
  DESC: 'DESC',
  ASC: 'ASC',
} as const;

export const ICD_TYPE = {
  PRIMARY_ICD10: 'PRIMARY_ICD10',
  SECOND_ICD10: 'SECOND_ICD10',
  PRIMARY_ICD9: 'PRIMARY_ICD9',
  SECOND_ICD9: 'SECOND_ICD9',
} as const;

export const CARE_PLAN_SCOPE = {
  ENCOUNTER: 'ENCOUNTER',
  DENTAL: 'DENTAL',
} as const;

export const CARE_PLAN_TYPE = {
  /* Kê toa thuốc */
  PRESCRIPTION: { value: 'PRESCRIPTION', label: 'encounterInfo.orderMedicine' },
  /* Hẹn khám lại */
  RE_EXAMINATION: { value: 'RE_EXAMINATION', label: 'encounterInfo.recheckAppointment' },
  /* Khám tại nhà */
  HOME_EXAMINATION: { value: 'HOME_EXAMINATION', label: 'encounterInfo.checkAtHome' },
  /* Chuyển viện */
  HOSPITAL_TRANSFER: { value: 'HOSPITAL_TRANSFER', label: 'encounterInfo.hospitalTransfer' },
  /* Khác */
  OTHER: { value: 'OTHER', label: 'encounterInfo.other' },
} as const;

export const ROLE_TYPE = {
  ADMIN: {
    value: 'ADMIN',
    label: 'account.admin',
    description: 'Quản trị viên hệ thống phòng khám - Dùng ở bản Standard|Basic',
  },
  DOCTOR: {
    value: 'DOCTOR',
    label: 'account.examDoctor',
    description: 'Quản trị viên hệ thống phòng khám - Dùng ở bản Standard|Basic',
  },
  GENERAL_DOCTOR: {
    value: 'GENERAL_DOCTOR',
    label: 'account.general',
    description: 'Bác sĩ tổng quát - Dùng ở bản Basic',
  },
  NURSE: { value: 'NURSE', label: 'account.nurse', description: 'Điểu dưỡng - Dùng ở bản Standard' },
  PHARMACIST: {
    value: 'PHARMACIST',
    label: 'account.pharmacist',
    description: 'Dược sĩ/Nhân viên bán - Dùng ở bản Standard|Basic',
  },
  CASHIER: { value: 'CASHIER', label: 'account.cashier', description: 'Thu ngân - Dùng ở bản Standard' },
  COORDINATOR: {
    value: 'COORDINATOR',
    label: 'account.frontOffice',
    description: 'Điều phối viên (Thu ngân/Lễ tân) - Dùng ở bản Basic',
  },
  RADIOGRAPHER: {
    value: 'RADIOGRAPHER',
    label: 'account.imageTechnician',
    description: 'Kĩ thuật viên chuẩn đoán hình ảnh - Dùng ở bản Standard',
  },
  LABORATORY_TECHNOLOGIST: {
    value: 'LABORATORY_TECHNOLOGIST',
    label: 'account.laboratory',
    description: 'Kỹ thuật viên xét nghiệm - Dùng ở bản Standard',
  },
  RADIOLOGIST: {
    value: 'RADIOLOGIST',
    label: 'account.imageSpecialist',
    description: 'Bác sĩ chuẩn đoán hình ảnh - Dùng ở bản Standard',
  },
  CLINICAL_PATHOLOGIST: {
    value: 'CLINICAL_PATHOLOGIST',
    label: 'account.labDoctor',
    description: 'Bác sĩ xét nghiệm - Dùng ở bản Standard',
  },
  RECEPTIONIST: { value: 'RECEPTIONIST', label: 'account.reception', description: 'Lễ tân - Dùng ở bản Standard' },
} as const;

export const PATIENT_TYPE = {
  NEW: {
    value: 'NEW',
    label: 'newPatient',
  },
  RE_EXAMINATION: {
    value: 'RE_EXAMINATION',
    label: 'returningPatient',
  },
} as const;

export const MEDICATION_CATEGORY_SCOPE = {
  INVENTORY: 'INVENTORY',
  CLINIC: 'CLINIC',
} as const;

export const MEDICATION_USAGE_TYPE = {
  THERAPY_SESSION: 'THERAPY_SESSION',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL_EXAM: 'DENTAL_EXAM',
} as const;

export const PATIENT_CONTACT_TYPE = {
  COMPANY: 'COMPANY',
  PERSON_IN_CONTACT: 'PERSON_IN_CONTACT',
  PERSON_IN_CHARGE: 'PERSON_IN_CHARGE',
} as const;

export const PATIENT_ADDRESS_TYPE = {
  HOME: 'HOME',
  WORK: 'WORK',
} as const;

export const MEDICATION_UNIT = {
  UNIT: { value: 'UNIT', label: 'unit' },
  PILL: { value: 'PILL', label: 'pill' },
  TUBE: { value: 'TUBE', label: 'tube' },
  BLISTER: { value: 'BLISTER', label: 'blister' },
  PACK: { value: 'PACK', label: 'pack' },
  VIAL: { value: 'VIAL', label: 'vial' },
  BOX: { value: 'BOX', label: 'box' },
  CASE: { value: 'CASE', label: 'case' },
  PIECE: { value: 'PIECE', label: 'piece' },
  BOTTLE: { value: 'BOTTLE', label: 'bottle' },
  PIPE: { value: 'PIPE', label: 'pipe' },
  POTION: { value: 'POTION', label: 'potion' },
  PATCH: { value: 'PATCH', label: 'patch' },
  CYLINDER: { value: 'CYLINDER', label: 'cylinder' },
  GRAM: { value: 'GRAM', label: 'GRAM' },
  PACKAGE: { value: 'PACKAGE', label: 'PACKAGE' },
};
export const ANESTHESISA_METHOD = {
  NOT_AVAILABLE: {
    value: 'NOT_AVAILABLE',
    label: 'procedure.none',
  },
  ANESTHETIZE: {
    value: 'ANESTHETIZE',
    label: 'procedure.general',
  },
  ANESTHESIA: {
    value: 'ANESTHESIA',
    label: 'procedure.local',
  },
};

export const ATTEND_PERSON_MISSION = {
  MAIN_PERFORMER: {
    value: 'MAIN_PERFORMER',
    label: 'TT Viên chính',
  },
  ANESTHESIOLOGIST: {
    value: 'ANESTHESIOLOGIST',
    label: 'GTGM Chính',
  },
  ASSISTANT: {
    value: 'ASSISTANT',
    label: 'Phụ việc',
  },
  HELPER: {
    value: 'HELPER',
    label: 'Giúp việc',
  },
} as const;

export const PAYMENT_HISTORY_TYPE = {
  PAYMENT: { value: 'PAYMENT', label: 'common.payment.history.type.label.payment', color: '' },
  REFUND: { value: 'REFUND', label: 'common.payment.history.type.label.refund' },
  PAYMENT_ADVANCE: { value: 'PAYMENT_ADVANCE', label: 'common.payment.history.type.label.paymentAdvance' },
  PAYMENT_ADVANCE_REFUND: {
    value: 'PAYMENT_ADVANCE_REFUND',
    label: 'common.payment.history.type.label.paymentAdvanceRefund',
  },
  PREPAYMENT: {
    value: 'PREPAYMENT',
    label: 'common.payment.history.type.label.prepayment',
  },
} as const;

export const PAYMENT_METHOD = {
  CASH: { value: 'CASH', label: 'common.paymentMethod.cash' },
  BANK_TRANSFER: { value: 'BANK_TRANSFER', label: 'common.paymentMethod.bank_transfer' },
  CARD: { value: 'CARD', label: 'common.paymentMethod.card' },
  PREPAYMENT: { value: 'PREPAYMENT', label: 'common.paymentMethod.prepayment' },
} as const;

export const PAYMENT_METHOD_NOT_FULL = {
  CASH: { value: 'CASH', label: 'common.paymentMethod.cash' },
  BANK_TRANSFER: { value: 'BANK_TRANSFER', label: 'common.paymentMethod.bank_transfer' },
  CARD: { value: 'CARD', label: 'common.paymentMethod.card' },
} as const;

export const PAYMENT_ADVANCE_STATUS = {
  DRAFT: { value: 'DRAFT', label: 'therapy.payment.prepayment.status.draft', color: 'grey.main' },
  REFUNDED: { value: 'REFUNDED', label: 'therapy.payment.prepayment.status.refunded', color: 'warning.main' },
  PREPAID: { value: 'PREPAID', label: 'therapy.payment.prepayment.status.prepaid', color: 'primary.main' },
} as const;

export const PAYMENT_STATUS = {
  PAID: { value: 'PAID', label: 'therapy.payment.status.label.paid', color: 'success.main' },
  UNPAID: { value: 'UNPAID', label: 'therapy.payment.status.label.unpaid', color: 'warning.main' },
  PACKAGE_PAID: { value: 'PACKAGE_PAID', label: 'therapy.payment.status.label.packagePaid', color: 'warning.main' },
  // PREPAID: { value: 'PREPAID', label: 'therapy.payment.status.label.prepaid', color: 'primary.main' },
  PREPAID: { value: 'PREPAID', label: 'therapy.payment.status.label.unpaid', color: 'warning.main' },
  REFUNDED: { value: 'REFUNDED', label: 'therapy.payment.status.label.refund', color: 'primary.main' },
  CANCELLED: { value: 'CANCELLED', label: 'therapy.payment.status.label.cancelled', color: 'error.main' },
} as const;

export const PAYMENT_TYPE = {
  THERAPY: 'THERAPY',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL: 'DENTAL',
} as const;

export const PAYMENT_DETAIL_TYPE = {
  THERAPY: { value: 'THERAPY', label: 'therapy.cashier.paymentType.label.therapy' },
  THERAPY_SESSION: { value: 'THERAPY_SESSION', label: 'therapy.cashier.paymentType.label.therapySession' },
  THERAPY_SESSION_OUT_PACKAGE: {
    value: 'THERAPY_SESSION_OUT_PACKAGE',
    label: 'therapy.cashier.paymentType.label.therapySessionOutPackage',
  },
  MEDICATION_USAGE: { value: 'MEDICATION_USAGE', label: 'therapy.cashier.paymentType.label.therapyMedication' },
  MEDICAL_SUPPLIES_USAGE: {
    value: 'MEDICAL_SUPPLIES_USAGE',
    label: 'therapy.cashier.paymentType.label.therapyMedicalSupplies',
  },
  INDICATION_REQUEST: { value: 'INDICATION_REQUEST', label: 'therapy.cashier.paymentType.label.indicationRequest' },
  INDICATION_MEDICATION: {
    value: 'INDICATION_MEDICATION',
    label: 'therapy.cashier.paymentType.label.indicationMedication',
  },
  INDICATION_MEDICAL_SUPPLIES: {
    value: 'INDICATION_MEDICAL_SUPPLIES',
    label: 'therapy.cashier.paymentType.label.indicationMedicalSupplies',
  },
  DENTAL: {
    value: 'DENTAL',
    label: 'cashier.paymentType.label.dental',
  },
  ENCOUNTER: {
    value: 'ENCOUNTER',
    label: 'cashier.paymentType.label.encounter',
  },
} as const;

export const PACKAGE_SCOPE = {
  IN_PACKAGE: { value: 'IN_PACKAGE', label: 'therapy.payment.packageScope.inPackage' },
  OUT_PACKAGE: { value: 'OUT_PACKAGE', label: 'therapy.payment.packageScope.outPackage' },
} as const;

export const DISCOUNT_TYPE = {
  PERCENT: { value: 'PERCENT', label: '%' },
  CASH: { value: 'CASH', label: '$' },
} as const;

export const USER_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  LOCKED: 'LOCKED',
} as const;

export const INDICATION_LABORATORY_EVALUATE = {
  NO_NORMAL: {
    value: 'NO_NORMAL',
    label: 'referenceIndication.Abnormal',
  },
  LOW: {
    value: 'LOW',
    label: 'referenceIndication.Low',
  },
  NORMAL: {
    value: 'NORMAL',
    label: 'referenceIndication.Normal',
  },
  HIGH: {
    value: 'HIGH',
    label: 'referenceIndication.High',
  },
} as const;

export const LABORATORY_REFERENCE_RESULT_TYPE = {
  STATISTICS: 'STATISTICS',
  LIST: 'LIST',
  DATA_INPUT: 'DATA_INPUT',
} as const;

export const PHYSICS_ROOM_CODE = {
  // Khám bệnh
  DIAGNOSTICS_OR_THERAPEUTICS_UNIT: {
    code: 'DX',
    name: 'Diagnostics or therapeutics unit',
    value: 'DIAGNOSTICS_OR_THERAPEUTICS_UNIT',
  },
  // Xét nghiệm
  MEDICAL_LABORATORY: { code: 'MBL', name: 'medical laboratory', value: 'MEDICAL_LABORATORY' },
  // Chẩn đoán hình ảnh
  RADIOLOGY_UNIT: { code: 'HRAD', name: 'radiology unit', value: 'RADIOLOGY_UNIT' },
  // Thủ thuật
  OPERATING_ROOM: { code: '225738002', name: 'Operating room', value: 'OPERATING_ROOM' },
} as const;

export const ENCOUNTER_REPORT_TYPE = {
  REVENUE: 'REVENUE',
  REFUND: 'REFUND',
} as const;

export const SERVICE_TYPE_REPORT = {
  ENCOUNTER: { value: 'ENCOUNTER', label: 'report.refund.service.type.encounter' },
  INDICATION_REQUEST: { value: 'INDICATION_REQUEST', label: 'report.refund.service.type.indication.request' },
  MEDICATION_USAGE: { value: 'MEDICATION_USAGE', label: 'report.refund.service.type.medication.usage' },
  MEDICAL_SUPPLIES_USAGE: {
    value: 'MEDICAL_SUPPLIES_USAGE',
    label: 'report.refund.service.type.medication.supplies.usage',
  },
} as const;

// Code : Xét nghiệm
export const LAB_DEPARTMENT_CODE = 'K47' as const;
// Chẩn đoán hình ảnh
export const IMAGE_DEPARTMENT_CODE = 'K39' as const;

export const GROUP_VERSION = {
  BASIC: 'BASIC',
  STANDARD: 'STANDARD',
} as const;

export const GROUP_TYPE = {
  MEDRING: 'MEDRING',
  CLINIC: 'CLINIC',
  DRUGSTORE: 'DRUGSTORE',
  THIRD_PARTY: 'THIRD_PARTY',
} as const;

export const SPECIALIZATION_TYPE = {
  ENT: 'ENT',
  PEDIATRICS: 'PEDIATRICS',
  INTERNAL_MEDICINE: 'INTERNAL_MEDICINE',
  NUTRITION: 'NUTRITION',
  DERMATOLOGY: 'DERMATOLOGY',
  REHABILITATION: 'REHABILITATION',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  ORTHOPEDIC: 'ORTHOPEDIC',
  CARDIOLOGY: 'CARDIOLOGY',
  DENTAL: 'DENTAL',
  OPHTHALMOLOGY: 'OPHTHALMOLOGY',
} as const;

export const GROUP_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
} as const;

export const STORAGE_RESOURCE_TYPE = {
  THERAPY_SESSION: 'THERAPY_SESSION',
  THERAPY_DIAGNOSTIC_IMAGE: 'THERAPY_DIAGNOSTIC_IMAGE',
  THERAPY_DIAGNOSTIC_IMAGE_ALBUM: 'THERAPY_DIAGNOSTIC_IMAGE_ALBUM',
  THERAPY_INDICATION_DIAGNOSTIC_IMAGE: 'THERAPY_INDICATION_DIAGNOSTIC_IMAGE',
  DENTAL_ADULT: 'DENTAL_ADULT',
  DENTAL_CHILD: 'DENTAL_CHILD',
  DENTAL_EXAM_IMAGE: 'DENTAL_EXAM_IMAGE',
  DENTAL_EXAM_IMAGE_ALBUM: 'DENTAL_EXAM_IMAGE_ALBUM',
  DENTAL_DIAGNOSIS_IMAGE: 'DENTAL_DIAGNOSIS_IMAGE',
  DENTAL_DIAGNOSIS_IMAGE_ALBUM: 'DENTAL_DIAGNOSIS_IMAGE_ALBUM',
  ENCOUNTER_INDICATION_DIAGNOSTIC_IMAGE: 'ENCOUNTER_INDICATION_DIAGNOSTIC_IMAGE',
  ENCOUNTER_DIAGNOSTIC_IMAGE: 'ENCOUNTER_DIAGNOSTIC_IMAGE',
  ENCOUNTER_DIAGNOSTIC_IMAGE_ALBUM: 'ENCOUNTER_DIAGNOSTIC_IMAGE_ALBUM',
  ENCOUNTER_ATTACHED_DOCUMENT: 'ENCOUNTER_ATTACHED_DOCUMENT',
  ATTACHED_DOCUMENT: 'ATTACHED_DOCUMENT',
  PATIENT_ATTACHED_DOCUMENT: 'PATIENT_ATTACHED_DOCUMENT',
  INDICATION_ATTACHED_DOCUMENT: 'INDICATION_ATTACHED_DOCUMENT',
} as const;

export const PRESCRIPTION_TYPE = {
  THERAPY: 'THERAPY',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL_EXAM: 'DENTAL_EXAM',
} as const;

export const PRESCRIPTION_DETAIL_TYPE = {
  MEDICATION_INTERNAL: 'MEDICATION_INTERNAL',
  MEDICATION_EXTERNAL: 'MEDICATION_EXTERNAL',
} as const;

export const APPOINTMENT_PATIENT_TYPE = {
  PERSONAL: {
    value: 'PATIENT',
    label: 'calendar.appointment.type.personal',
  },
  GROUP: {
    value: 'APPOINTMENT_GROUP',
    label: 'calendar.appointment.type.group',
  },
} as const;

export const INDICATION_REQUEST_TYPE = {
  THERAPY_SESSION: { value: 'THERAPY_SESSION', label: 'indication.type.therapySession' },
  ENCOUNTER: { value: 'ENCOUNTER', label: 'indication.type.encounter' },
  DENTAL_EXAM: { value: 'DENTAL_EXAM', label: 'indication.type.dentalExam' },
} as const;

export const SERVICE_TYPE_ALL = {
  THERAPY: { value: 'THERAPY', label: 'indication.type.therapySession' },
  ENCOUNTER: { value: 'ENCOUNTER', label: 'indication.type.encounter' },
  DENTAL: { value: 'DENTAL', label: 'indication.type.dentalExam' },
} as const;

export const SERVICE_TYPE_INCOME = {
  ENCOUNTER: { value: 'ENCOUNTER', label: 'indication.type.encounter' },
  THERAPY: { value: 'THERAPY', label: 'indication.type.therapySession' },
} as const;

export const SPECIALIZED_REPORT_TYPE = {
  THERAPY: {
    value: 'THERAPY',
    label: 'indication.type.therapySession',
  },
  ENCOUNTER: {
    value: 'ENCOUNTER',
    label: 'indication.type.encounter',
  },
  DENTAL: {
    value: 'DENTAL',
    label: 'indication.type.dentalExam',
  },
} as const;

export const APPOINTMENT_CHART_TYPE = {
  EXAM: {
    value: 'EXAM',
    label: 'indication.type.encounter',
  },
  THERAPY: {
    value: 'THERAPY',
    label: 'indication.type.therapySession',
  },
} as const;

export const APPOINTMENT_TABLE_TYPE = {
  EXAM: 'EXAM',
  THERAPY: 'THERAPY',
} as const;

export const DEBT_STATUS = {
  FREE_DEBT: {
    value: '0',
    label: 'debtFree',
  },
  STILL_DEBT: {
    value: '1',
    label: 'stillDebt',
  },
} as const;

export const TYPE_REPORT_REVENUE = {
  SUMMARY: { value: 'SUMMARY', label: 'navMenu.summaryReport' },
  DETAIL: {
    value: 'DETAIL',
    label: 'navMenu.detailReport',
  },
} as const;

export const APPOINTMENT_TYPES = {
  THERAPY: 'THERAPY',
  ENCOUNTER: 'ENCOUNTER',
  DENTAL_EXAM: 'DENTAL_EXAM',
} as const;

export const RECEIPT_TYPE = {
  DOCTOR: { value: 'DOCTOR', label: 'doctor-prescription' },
  RETAIL: { value: 'RETAIL', label: 'retail' },
} as const;

export const RECEIPT_TYPES = {
  DOCTOR: 'DOCTOR',
  RETAIL: 'RETAIL',
} as const;

export const INVENTORY_STATUS = {
  STOCKED: {
    value: 'STOCKED',
    label: 'inventory.status.stocked',
    color: '#43A047',
  },
  NOT_STOCK: {
    value: 'NOT_STOCK',
    label: 'inventory.status.not,stocked',
    color: '#FFB300',
  },
} as const;

export const MEDICATION_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ERROR: 'ERROR',
} as const;

export const INVENTORY_ACTIVITY_TYPE = {
  IMPORT: 'IMPORT',
  SALES_EXPORT: 'SALES_EXPORT',
  CANCEL_SALES_EXPORT: 'CANCEL_SALES_EXPORT',
  EXPORT: 'EXPORT',
  MANAGE: 'MANAGE',
} as const;
