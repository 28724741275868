import { Box } from '@mui/material';
import * as React from 'react';
import Tabs from 'modules/common/component/Tabs';
import MedicalPageTab from '../component/drugDatabase';

interface Props {}

const DrugDatabaseAdminPage: React.FunctionComponent<Props> = (props) => {
  return (
    <Box sx={{ padding: '10px 16px' }}>
      <Tabs
        searchUrlKey="tab"
        tabs={[
          {
            title: 'page.medical.tab.title',
            key: 'medical',
            content: <MedicalPageTab />,
          },
        ]}
        title=""
      />
    </Box>
  );
};

export default DrugDatabaseAdminPage;
