import { Add, Check, Delete, Help, Info } from '@mui/icons-material';
import { Alert, Box, Button, FormControlLabel, Stack, Typography } from '@mui/material';
import NumberInput from 'modules/common/component/form/NumberInput';
import SelectInput from 'modules/common/component/form/SelectInput';
import TextInput from 'modules/common/component/form/TextInput';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Medication, MedicationCategory, MedicationForm } from 'modules/schema';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { Option } from 'modules/common/type';
import { DRUG_OPTIONS } from 'modules/admin/constants';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import CheckboxInput from 'modules/common/component/form/CheckboxInput';
import { MEDICATION_UNIT } from 'modules/common/apiConstants';
import { defaultCodeSystemValue, maxValue } from './const';
import { API_SERVER } from 'modules/common/api';
import { useCreateMutate, useUpdateMutate } from 'modules/common/hook';
import AlertWarningElement from 'modules/common/SchemaForm/element/alert/AlertWarningElement';
import useDrugDatabaseContext from '../const';

interface DrugDataFormProps {
  onClose: () => void;
  formData?: Medication;
  dataMedicationCategory?: MedicationCategory[];
}

const DrugDataForm = ({ onClose, formData, dataMedicationCategory }: DrugDataFormProps) => {
  const specializedDepartmentMap = useSelector((state: AppState) => state.common.specializedDepartments);
  const { intl, appState, locationId } = useGeneralHook();
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const countryList = appState.common.countryList;
  const drugDatabaseContext = useDrugDatabaseContext();
  const listId = drugDatabaseContext.listId;

  const defaultValue = useMemo(() => {
    return {
      basicPrice: '',
      basicUnit: '',
      code: '',
      codeGroup: '',
      country: defaultCodeSystemValue,
      distributorName: '',
      distributorAddress: '',
      dose: '',
      form: '',
      ingredient: '',
      intendedRoute: [],
      manufacturerName: '',
      manufacturerAddress: '',
      medicationCategoryId: '',
      expiration: '',
      packaging: '',
      productType: '',
      name: '',
      numeratorUnit: '',
      registrationName: '',
      registrationAddress: '',
      registrationNumber: '',
      specializedDepartmentId: defaultCodeSystemValue,
      unitConverterList: [
        {
          factor: '',
          fromUnit: defaultCodeSystemValue,
          price: '',
        },
      ],
    };
  }, []);

  const form = useForm<MedicationForm>({
    defaultValues: defaultValue,
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'unitConverterList',
  });

  const unitConverterList = useWatch({
    control: form.control,
    name: 'unitConverterList',
  });

  const createConsecutive = form.watch().createConsecutive;
  const basicUnit = form.watch().basicUnit;
  const basicPrice = form.watch().basicPrice;
  const basicUnitValue = typeof basicUnit === 'string' ? basicUnit : basicUnit?.value;

  const basicUnitOption = useMemo(() => {
    return Object.values(MEDICATION_UNIT).filter(
      (v) => !unitConverterList || !unitConverterList.some((u) => u.fromUnit?.value === v?.value),
    );
  }, [unitConverterList]);

  const fromUnitOption = useMemo(() => {
    return Object.values(MEDICATION_UNIT).filter((v) => v.value !== basicUnitValue);
  }, [basicUnitValue]);

  const specializedDepartmentOption: Option[] = useMemo(
    () =>
      Object.values(specializedDepartmentMap).map((ele) => ({ value: ele.id!, label: `${ele.code} - ${ele.name}` })),
    [specializedDepartmentMap],
  );

  const mapData = <T, R>(data: T[] | undefined, mapFn: (item: T) => R) => data?.map(mapFn) || [];
  const dataMedicationCategoryMap = useMemo(
    () =>
      mapData(dataMedicationCategory, (i: MedicationCategory) => ({
        value: i.id?.toString(),
        label: i.name,
      })),
    [dataMedicationCategory],
  );

  const createDrugMutate = useCreateMutate({
    onSuccess: () => {
      drugDatabaseContext.revalidateDashboard();
      if (drugDatabaseContext.selectedViewMode === 'GROUP') {
        drugDatabaseContext.revalidateMedicationCategory();
      } else {
        drugDatabaseContext.revalidateMedicationSearch();
      }
      setSubmitSuccess(true);
      if (!createConsecutive) {
        onClose();
      }
      form.reset(defaultValue);
    },
  });

  const updateDrugMutate = useUpdateMutate({
    onSuccess: () => {
      drugDatabaseContext.revalidateDashboard();
      if (drugDatabaseContext.selectedViewMode === 'GROUP') {
        drugDatabaseContext.revalidateMedicationCategory();
      } else {
        drugDatabaseContext.revalidateMedicationSearch();
      }
      onClose();
      form.reset(defaultValue);
    },
  });

  const onSubmit = useCallback(
    async (data: MedicationForm) => {
      const body = {
        basicPrice: data.basicPrice,
        basicUnit: typeof data.basicUnit === 'string' ? data.basicUnit : data.basicUnit.value,
        code: data.code,
        codeGroup: data.codeGroup,
        country: data.country.value,
        distributor: {
          address: data.distributorAddress,
          name: data.distributorName,
        },
        dose: data.dose,
        form: data.form,
        ingredient: data.ingredient,
        intendedRoute: data.intendedRoute.map((i) => i.value),
        manufacturer: {
          address: data.manufacturerAddress,
          name: data.manufacturerName,
        },
        medicationCategoryId:
          (typeof data.medicationCategoryId === 'string'
            ? data.medicationCategoryId
            : data.medicationCategoryId?.value) || listId?.medicationCategoryId,
        medicationKnowledge: {
          expiration: data.expiration,
          packaging: data.packaging,
          productType: typeof data.productType === 'string' ? data.productType : data.productType.value,
        },
        name: data.name,
        numeratorUnit: data.numeratorUnit,
        registration: {
          address: data.registrationAddress,
          name: data.registrationName,
        },
        registrationNumber: data.registrationNumber,
        specializedDepartmentId: data.specializedDepartmentId.value,
        currency: appState.common.groupCurrency,
        unitConverterList: data.unitConverterList?.map((i) => {
          return {
            factor: i.factor,
            fromUnit: i.fromUnit?.value,
            price: i.price,
          };
        }),
      };
      const unitConverterListNull = data.unitConverterList.some(
        (item) => !item.factor && !item.fromUnit && !item.price,
      );
      const bodyCustom = unitConverterListNull
        ? {
            ...body,
            unitConverterList: [],
          }
        : body;
      if (formData?.id) {
        await updateDrugMutate({
          url: API_SERVER.medication.update(formData.id),
          method: 'put',
          data: bodyCustom,
        });
      } else {
        await createDrugMutate({
          url: API_SERVER.medication.index(locationId).url,
          method: 'post',
          data: bodyCustom,
        });
      }
    },
    [appState.common.groupCurrency, createDrugMutate, formData, locationId, listId, updateDrugMutate],
  );

  useEffect(() => {
    if (formData) {
      form.reset({
        basicPrice: formData.basicPrice,
        basicUnit: basicUnitOption.find((i) => i.value === formData.basicUnit)!,
        code: formData.code,
        codeGroup: formData.codeGroup,
        country: countryList.find((i) => i.value === formData.country)!,
        distributorAddress: formData.distributor?.address,
        distributorName: formData.distributor?.name,
        dose: formData.dose,
        form: formData.form,
        ingredient: formData.ingredient,
        intendedRoute: formData.intendedRoute!,
        manufacturerAddress: formData.manufacturer?.address,
        manufacturerName: formData.manufacturer?.name,
        medicationCategoryId: dataMedicationCategoryMap?.find(
          (i) => i.value === listId?.medicationCategoryId?.toString(),
        ),
        expiration: formData.medicationKnowledge?.expiration,
        packaging: formData.medicationKnowledge?.packaging,
        productType: DRUG_OPTIONS.find((i) => i.value === formData.medicationKnowledge?.productType)!,
        name: formData.name,
        numeratorUnit: formData.numeratorUnit,
        registrationAddress: formData.registration?.address,
        registrationName: formData.registration?.name,
        registrationNumber: formData.registrationNumber,
        specializedDepartmentId: specializedDepartmentOption.find((i) => i.value === formData.specializedDepartmentId)!,
        unitConverterList: formData.unitConverterList?.map((item) => {
          return {
            factor: item.factor,
            fromUnit: fromUnitOption.find((i) => i.value === item.fromUnit),
            price: item.price,
          };
        }),
      });
    } else {
      form.reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList, form, formData, specializedDepartmentOption]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box sx={{ flexGrow: 1 }}>
        <Box marginBottom={2} display="flex" alignItems="baseline" justifyContent="space-between">
          <Typography variant="h6" style={{ marginRight: 24 }}>
            <FormattedMessage id="basicInfo" />
          </Typography>
        </Box>
        <Box>
          <Stack spacing={2} direction="row">
            <TextInput
              name="name"
              form={form}
              label="pharmacy.drugName"
              placeholder="pharmacy.drugNameEnter"
              required
            />
            <TextInput
              name="registrationNumber"
              form={form}
              label="pharmacy.registerNumber"
              placeholder="registrationNumberEnter"
              required
            />
            <SelectInput
              form={form}
              name="medicationCategoryId"
              options={dataMedicationCategoryMap}
              getValue="value"
              renderLabel="label"
              label="drugUsage.groupDrug"
              placeholder="drugUsage.groupDrugEnter"
              defaultValue={listId?.medicationCategoryId || ''}
              type="select"
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    width: 300,
                  },
                  sx: {
                    '& .MuiTypography-root': {
                      whiteSpace: 'normal',
                    },
                  },
                },
              }}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextInput name="code" form={form} label="pharmacy.code" placeholder="pharmacy.codeEnter" />
            <TextInput name="codeGroup" form={form} label="drugGroupCode" placeholder="drugGroupCodeEnter" />
            <SelectInput
              form={form}
              name="specializedDepartmentId"
              options={specializedDepartmentOption}
              getValue="value"
              renderLabel="label"
              label="pharmacy.departmentCode"
              placeholder="departmentCodeSelect"
              clearButton
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextInput name="dose" form={form} label="sale.dose" placeholder="doseEnter" />
            <TextInput
              name="ingredient"
              form={form}
              label="pharmacy.ingredients"
              placeholder="ingredientsEnter"
              required
            />
            <TextInput
              name="numeratorUnit"
              form={form}
              label="pharmacy.numeratorUnit"
              placeholder="numeratorUnitEnter"
              required
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <SelectInput
              form={form}
              name="productType"
              options={DRUG_OPTIONS}
              getValue="value"
              renderLabel="label"
              label="pharmacy.formType"
              placeholder="pharmacy.formTypeEnter"
              type="select"
              required
            />
            <AutocompleteInput
              form={form}
              name="intendedRoute"
              options={appState.common.intendedRoutes}
              getLabel="label"
              getValue="value"
              label="drugUsage.intendedRoute"
              placeholder="intendedRouteSelect"
              multiple
              limitTags={2}
              required
            />
            <TextInput name="form" form={form} label="pharmacy.dosageForm" placeholder="dosageFormEnter" />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextInput name="expiration" form={form} label="expiredDate" placeholder="expiryEnter" />
            <TextInput
              name="packaging"
              form={form}
              label="pharmacy.packagingFormat"
              placeholder="pharmacy.packagingFormatEnter"
            />
            <SelectInput
              form={form}
              name="country"
              options={countryList}
              getValue="value"
              renderLabel="label"
              label="pharmacy.produceCountry"
              placeholder="produceCountrySelect"
              clearButton
            />
          </Stack>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6" display="flex" alignItems="center">
                <FormattedMessage id="pharmacy.unitConversion" />
                <Help sx={{ marginLeft: '5px', color: '#78909C' }} />
              </Typography>
            </Box>
            {formData?.importedInventoryStatus && (
              <Alert severity="warning" sx={{ flexDirection: 'row', marginBottom: 2 }}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'dugDatabase.alert.waring.fromUnitExist' }),
                  }}
                ></Typography>
              </Alert>
            )}
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: '10px', color: '#003CA6', display: 'flex', alignItems: 'center' }}
            >
              <FormattedMessage id="smallestPackingUnit" />
              <Info sx={{ marginLeft: '5px' }} />
            </Typography>
            <Stack spacing={2} direction="row">
              <SelectInput
                form={form}
                name="basicUnit"
                options={basicUnitOption}
                getValue="value"
                renderLabel="label"
                label="pharmacy.unit"
                placeholder="unitExample"
                required={Boolean(unitConverterList?.length > 0 || basicPrice)}
                type="select"
              />
              <NumberInput
                name="basicPrice"
                form={form}
                label="sellingPrice"
                placeholder="drugUsage.patientPriceEnter"
                required={Boolean(basicUnitValue)}
                max={maxValue}
                thousandSeparator
              />
            </Stack>
            <Typography variant="subtitle1" sx={{ color: '#003CA6', display: 'flex', alignItems: 'center' }}>
              <FormattedMessage id="dugDatabase.title.conversionUnit" />
              <Info sx={{ marginLeft: '5px' }} />
            </Typography>
            {fields.map((item, index) => {
              const currentFactor = unitConverterList?.[index]?.factor || 0;
              return (
                <>
                  <Stack key={item.id} spacing={2} direction="row" marginTop="10px">
                    <Stack
                      spacing={2}
                      direction="row"
                      width="100%"
                      padding="16px 16px 0 16px"
                      sx={{
                        backgroundColor: '#E0ECFF',
                        borderRadius: '3px',
                        padding: '16px 16px 0 16px',
                        width: '100%',
                      }}
                    >
                      <SelectInput
                        form={form}
                        name={`unitConverterList.${index}.fromUnit`}
                        options={fromUnitOption}
                        getValue="value"
                        renderLabel="label"
                        placeholder="select"
                        clearButton
                      />
                      <Typography paddingTop="5px">{'='}</Typography>
                      <NumberInput
                        name={`unitConverterList.${index}.factor`}
                        form={form}
                        placeholder="quantity"
                        max={maxValue}
                        thousandSeparator
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (!isNaN(value)) {
                            form.setValue(`unitConverterList.${index}.factor`, value);
                          }
                        }}
                      />
                      <Typography paddingTop="5px">
                        {basicUnitValue && <FormattedMessage id={MEDICATION_UNIT[basicUnitValue]?.label} />}
                      </Typography>
                      <Typography paddingTop="5px">{'='}</Typography>
                      <NumberInput
                        name={`unitConverterList.${index}.price`}
                        form={form}
                        placeholder="pharmacy.sellingPrice"
                        max={maxValue}
                        thousandSeparator
                      />
                      <Typography paddingTop="5px">{'VNĐ'}</Typography>
                    </Stack>
                    <IconButtonTitle
                      title="delete"
                      size="small"
                      sx={{ height: 'max-content', marginTop: '15px!important' }}
                      onClick={() => remove(index)}
                    >
                      <Delete />
                    </IconButtonTitle>
                  </Stack>
                  {currentFactor === 1 && (
                    <Box marginTop="10px">
                      <AlertWarningElement label="dugDatabase.alert.waring.duplicateUnit" />
                    </Box>
                  )}
                </>
              );
            })}
            <Button
              variant="text"
              color="primary"
              startIcon={<Add />}
              sx={{ marginTop: '5px', textTransform: 'uppercase' }}
              onClick={() => append({ factor: '', fromUnit: { value: '', label: '' }, price: '' })}
            >
              <FormattedMessage id="addConversionUnit" />
            </Button>
          </Box>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6">
                <FormattedMessage id="pharmacy.organizationInfo" />
              </Typography>
            </Box>
            <Stack spacing={2} direction="row">
              <TextInput
                name="manufacturerName"
                form={form}
                label="pharmacy.producer"
                placeholder="pharmacy.producerEnter"
              />
              <TextInput name="manufacturerAddress" form={form} label="address" placeholder="addressEnter" />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextInput
                name="registrationName"
                form={form}
                label="pharmacy.registerCompany"
                placeholder="pharmacy.registerCompanyEnter"
              />
              <TextInput name="registrationAddress" form={form} label="address" placeholder="addressEnter" />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextInput
                name="distributorName"
                form={form}
                label="pharmacy.distributor"
                placeholder="pharmacy.distributorEnter"
              />
              <TextInput name="distributorAddress" form={form} label="address" placeholder="addressEnter" />
            </Stack>
            <Box display="flex" justifyContent={formData?.id ? 'flex-end' : 'space-between'}>
              {!formData?.id && (
                <FormControlLabel
                  sx={{
                    '>div': {
                      flexDirection: 'row',
                      width: '40px',
                    },
                  }}
                  control={<CheckboxInput name="createConsecutive" form={form} />}
                  label={<FormattedMessage id="createConsecutively" />}
                />
              )}
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography display="flex" alignItems="center">
                  {submitSuccess && (
                    <>
                      <Check sx={{ marginRight: '5px', color: '#43A047' }} />
                      <FormattedMessage id="addNewDrug" />
                    </>
                  )}
                </Typography>
                <Button variant="outlined" onClick={onClose}>
                  <FormattedMessage id="cancelled" />
                </Button>
                <Button variant="contained" type="submit">
                  <FormattedMessage id="save" />
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
export default DrugDataForm;
