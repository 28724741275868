import { Box, Button, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Help } from '@mui/icons-material';
import { FormValues } from './types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: FormValues | null) => void;
}

const ConfirmDialog = ({ onClose, open, onSubmit }: Props) => {
  const { intl } = useGeneralHook();

  return (
    <DialogCustom
      open={!!open}
      maxWidth="lg"
      onClose={onClose}
      PaperProps={{
        style: { width: 591 },
      }}
      title={'confirm.export.cancel.title'}
    >
      <Box padding={2}>
        <Grid container xs={12} alignItems="center">
          <Grid item xs={2} textAlign="center">
            <Help color="disabled" sx={{ fontSize: '60px!important' }} />
          </Grid>
          <Grid item xs={10}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'confirm.export.cancel.content' }),
              }}
            ></Typography>
          </Grid>
        </Grid>
        <Box padding={1} paddingTop={3} display="flex" justifyContent="end">
          <Button variant="outlined" color="primary" sx={{ backgroundColor: '#CDDFFF' }} onClick={onClose}>
            <FormattedMessage id="destroy" />
          </Button>
          <Button color="primary" style={{ marginLeft: 8 }} onClick={() => onSubmit(null)}>
            <FormattedMessage id="confirm.export.cancel.submit" />
          </Button>
        </Box>
      </Box>
    </DialogCustom>
  );
};

export default ConfirmDialog;
