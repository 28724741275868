import { Add, GridOn } from '@mui/icons-material';
import { Box, Button, Collapse, Divider, InputAdornment, Typography } from '@mui/material';
import * as React from 'react';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { useDialog, useDownloadFileLoading, useFetch } from 'modules/common/hook';
import TextInput from 'modules/common/component/form/TextInput';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import DateInput from 'modules/common/component/form/DateInput';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import moment from 'moment';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import { INVENTORY_EXPORT_STATUS } from '../inventory/constant';
import { Option } from 'modules/common/type';
import { Medication } from 'modules/schema';
import SearchIcon from '@mui/icons-material/Search';
import DialogAddExportInventory from './DialogAddExportInventory';
import useExportInventoryContext from './utils';

interface FormSchema {
  code?: string;
  medication?: Pick<Medication, 'id' | 'name' | 'ingredient'>;
  status?: Option;
  lot?: string;
  exportDateFrom?: string;
  exportDateTo?: string;
}

interface Props {
  revalidate: () => void;
  setSearchParam: (value) => void;
}

const FilterBox: React.FunctionComponent<Props> = ({ revalidate, setSearchParam }) => {
  const exportInventoryContext = useExportInventoryContext();
  const [expand, setExpand] = React.useState(false);
  const { locationId, API_SERVER, intl } = useGeneralHook();
  const [openAddExport, onOpenAddExport, onCloseAddExport] = useDialog();
  const form = useForm<FormSchema>({
    defaultValues: {
      code: undefined,
      medication: undefined,
      lot: '',
      exportDateFrom: '',
      exportDateTo: '',
    },
    mode: 'onChange',
  });

  const { data: medications } = useFetch(API_SERVER.medication.inventories(locationId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
  });
  const { downloadFile, loading } = useDownloadFileLoading(
    API_SERVER.report.exportReport(exportInventoryContext.searchParam),
  );

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box flex={1.5}>
          <Typography variant="h6">
            <FormattedMessage id="navMenu.exportInventory" />
          </Typography>
        </Box>
        <Box flex={1} paddingTop={2} paddingRight={1}>
          <TextInput
            name="code"
            form={form}
            type="search"
            placeholder={'inventory.searchByExportCode'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          sx={{ ml: 1 }}
          onClick={() => {
            const data = form.getValues();
            setSearchParam((prev) => ({
              ...prev,
              medicationId: data?.medication?.id,
              status: data?.status?.value,
              lot: data.lot ? data.lot : undefined,
              code: data.code ? data.code : undefined,
              exportDateFrom: data.exportDateFrom
                ? moment(data.exportDateFrom).startOf('day').utc().format(BE_DATE_TIME_FORMAT)
                : undefined,
              exportDateTo: data.exportDateTo
                ? moment(data.exportDateTo).endOf('day').utc().format(BE_DATE_TIME_FORMAT)
                : undefined,
            }));
          }}
          color="primary"
          variant="contained"
        >
          <FormattedMessage id="search" />
        </Button>
        <Button
          onClick={() => setExpand(!expand)}
          color="primary"
          variant="text"
          endIcon={<ExpandLessIcon style={{ transform: expand ? 'rotate(0deg)' : 'rotate(180deg)' }} />}
          sx={{ ml: 1 }}
        >
          <FormattedMessage id="advance" />
        </Button>
        <LoadingButton
          sx={{ backgroundColor: '#CDDFFF', ml: 1 }}
          loading={loading}
          color="inherit"
          startIcon={<GridOn />}
          onClick={downloadFile}
        >
          <FormattedMessage id="inventory.export.excel.button" />
        </LoadingButton>
        <Button onClick={onOpenAddExport} color="primary" variant="contained" startIcon={<Add />} sx={{ ml: 1 }}>
          <FormattedMessage id="add.export.inventory" />
        </Button>
      </Box>
      <Collapse in={expand}>
        <Box display="flex" gap={2} mb={2}>
          <Box width="20%">
            <AutocompleteInput
              form={form}
              name="medication"
              options={medications}
              getValue="id"
              getLabel="name"
              renderLabel={(value) => `${value.id} - ${value.name} (${value.ingredient})`}
              label="inventory.drugName"
              placeholder="select"
              hideError
              clearButton
            />
          </Box>
          <Box width="20%">
            <AutocompleteInput
              form={form}
              name="status"
              getValue="value"
              getLabel={(record: Option) => intl.formatMessage({ id: record?.label })}
              options={INVENTORY_EXPORT_STATUS}
              label="status"
              placeholder="status"
              renderLabel={(option) => <FormattedMessage id={option.label} />}
              clearButton
            />
          </Box>
          <Box width="20%">
            <TextInput name="lot" form={form} label="inventory.lot" placeholder="inventory.lotEnter" />
          </Box>
          <Box width="20%">
            <DateInput
              name="exportDateFrom"
              form={form}
              label="fromDate"
              format={FE_DATE_FORMAT}
              maxDate={moment()}
              toUTC={false}
            />
          </Box>
          <Box width="20%">
            <DateInput
              name="exportDateTo"
              form={form}
              label="toDate"
              format={FE_DATE_FORMAT}
              maxDate={moment()}
              toUTC={false}
            />
          </Box>
        </Box>
        <Divider />
      </Collapse>

      <DialogAddExportInventory open={openAddExport} onClose={onCloseAddExport} mutate={() => revalidate} />
    </Box>
  );
};

export default FilterBox;
