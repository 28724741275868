import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  title: string;
  totalElementAmount?: number;
  foundResultAmount?: number;
  showSubTitle?: boolean;
  renderRight?: ReactNode;
}

export const ReportTitle = ({
  title,
  totalElementAmount,
  foundResultAmount,
  showSubTitle = false,
  renderRight,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={1}>
      <Box>
        <Typography
          sx={{
            color: '#263238',
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.18px',
          }}
        >
          <FormattedMessage id={title} />
        </Typography>
        {showSubTitle && (
          <Typography
            sx={{
              color: '#455A64',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '16px',
              letterSpacing: '0.4px',
            }}
          >
            {!foundResultAmount ? (
              <FormattedMessage
                id="foundResultTotal"
                values={{
                  total: (
                    <Typography sx={{ color: '#003CA6', fontWeight: 700 }} component="span">
                      {totalElementAmount || 0}
                    </Typography>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="foundResult"
                values={{
                  num: (
                    <Typography sx={{ color: '#003CA6', fontWeight: 700 }} component="span">
                      {foundResultAmount || 0}
                    </Typography>
                  ),
                  total: (
                    <Typography sx={{ color: '#003CA6', fontWeight: 700 }} component="span">
                      {totalElementAmount || 0}
                    </Typography>
                  ),
                }}
              />
            )}
          </Typography>
        )}
      </Box>
      {renderRight}
    </Stack>
  );
};
