import { TextField, TextFieldProps } from '@mui/material';
import { FormCommonProps, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';
import { getError, getPlaceholder } from './utils';
import { useIntl } from 'react-intl';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  showError?: boolean;
  placeholder?: string;
  rawPlaceholder?: boolean;
  thousandSeparator?: boolean;
  rawError?: boolean;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
  onValueChange?: (values: NumberFormatValues) => void;
  numericFormatProps?: Omit<
    NumericFormatProps,
    'thousandSeparator' | 'value' | 'onValueChange' | 'customInput' | 'size' | 'color'
  >;
  max?: number;
  defaultValue?: number;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<TextFieldProps, 'defaultValue' | 'value' | 'name' | 'ref' | 'placeholder' | 'error' | 'id' | 'type'>;

const NumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  form,
  placeholder,
  rawPlaceholder = false,
  label,
  rawLabel = false,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  thousandSeparator,
  numericFormatProps,
  hideError,
  required,
  fullWidth = true,
  disabled = false,
  rawError = false,
  showError = false,
  onValueChange,
  max,
  defaultValue,
  ...restProps
}: Props<TFieldValues, TName>) => {
  const id = useId();
  const intl = useIntl();

  return (
    <FormControl
      error={getError(form, name)}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      controlProps={controlProps}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      fullWidth={fullWidth}
      disabled={disabled}
      rawError={rawError}
    >
      <Controller
        name={name}
        control={form.control}
        rules={{
          required: required && intl.formatMessage({ id: 'required' }),
        }}
        render={({ field: { onChange, name, value, onBlur, ref }, fieldState }) => (
          <NumericFormat
            name={name}
            disabled={disabled}
            value={value ?? ''}
            onValueChange={(values, sourceInfo) => {
              const { floatValue } = values;
              if (floatValue === null || floatValue === undefined) {
                onChange({ target: { value: defaultValue || '' } });
              } else {
                onChange({ target: { value: floatValue || '' } });
              }
              if (sourceInfo.event && restProps.onChange) {
                restProps.onChange(sourceInfo.event as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
              }
              onValueChange && onValueChange(values);
            }}
            // required
            onBlur={(...params) => {
              onBlur();
              restProps.onBlur && restProps.onBlur(...params);
              if ((value === '' || value === null || value === undefined) && defaultValue !== undefined) {
                onChange({ target: { value: defaultValue || '' } });
              }
            }}
            id={id}
            inputRef={ref}
            thousandSeparator={thousandSeparator}
            customInput={TextField}
            placeholder={getPlaceholder(placeholder, rawLabel, intl)}
            error={
              (showError && !value) ||
              (form.formState.errors[name as keyof typeof form.formState.errors] as any)?.message ||
              !!fieldState.error
            }
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue === undefined || (floatValue >= 0 && (max === undefined || floatValue <= max));
            }}
            {...numericFormatProps}
            {...restProps}
          />
        )}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default NumberInput;
