import React, { useState } from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import { BOX_SUMMARY_KEY, SearchParams, DrugDatabaseContext, ListId } from './const';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import DrugDataListTable from './DrugDataListTable';
import DrugDataFormDialog from './DrugDataFormDialog';
import GroupDrugDataFormDialog from './GroupDrugDataFormDialog';
import { Medication, MedicationCategory } from 'modules/schema';
import DrugTableView from './DrugTableView';
import usePaginationHook from 'modules/common/hook/usePaginationHook';

interface Props {}

const MedicalPageTab: React.FunctionComponent<Props> = () => {
  const [selectedViewMode, setSelectedViewMode] = useState<'TABLE' | 'GROUP'>('GROUP');
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [formDataGroup, setFormDataGroup] = useState<MedicationCategory>();
  const [summaryBox, setSummaryBox] = useState<keyof typeof BOX_SUMMARY_KEY>(BOX_SUMMARY_KEY.TOTAL);
  const [openMedicationForm, setOpenMedicationForm] = useState<boolean>(false);
  const hookPagination = usePaginationHook();
  const { pagination, clearParams } = hookPagination;
  const [totalResult, setTotalResutl] = useState<number>(0);
  const { data, revalidate, isValidating } = useFetch(API_SERVER.medicationCategory.getCategoryList(searchParams), {
    enabled: selectedViewMode === 'GROUP',
  });
  const { data: dataMedicationCategory, revalidate: revalidateMedicationCategory } = useFetch(
    API_SERVER.medicationCategory.getCategoryList(),
  );
  const { data: dataDashboard, revalidate: revalidateDashboard } = useFetch(
    API_SERVER.medication.medicationDashboard(),
  );
  const {
    data: dataMedicationSearch,
    revalidate: revalidateMedicationSearch,
    isValidating: isValidatingMedicationSearch,
  } = useFetch(
    API_SERVER.medication.search({
      ...searchParams,
      page: pagination.page,
      pageSize: pagination.pageSize,
    }),
    {
      enabled: selectedViewMode === 'TABLE',
    },
  );

  const [selection, setSelection] = useState<Medication[]>([]);
  const [listId, setListId] = useState<ListId>({ medicationId: null, medicationCategoryId: null });

  return (
    <DrugDatabaseContext.Provider
      value={{
        selectionMedication: selection,
        setSelectionMedication: (value: Medication[]) => setSelection(value),
        summaryBox: summaryBox,
        setSummaryBox: (value) => setSummaryBox(value),
        searchParams: searchParams,
        selectedViewMode: selectedViewMode,
        revalidateCategoryGroup: revalidate,
        dataDashboard: dataDashboard || {},
        revalidateDashboard: revalidateDashboard,
        openMedicationForm: openMedicationForm,
        setOpenMedicationForm: setOpenMedicationForm,
        listId: listId,
        setListId: setListId,
        formDataGroup: formDataGroup || undefined,
        setFormDataGroup: setFormDataGroup,
        revalidateMedicationCategory: revalidateMedicationCategory,
        revalidateMedicationSearch: revalidateMedicationSearch,
        isValidatingMedicationSearch: isValidatingMedicationSearch,
        totalResult: totalResult,
        setTotalResult: setTotalResutl,
      }}
    >
      <Box padding="10px">
        <Header
          selectedViewMode={selectedViewMode}
          setSelectedViewMode={setSelectedViewMode}
          setSearchParams={setSearchParams}
          clearParams={clearParams}
        />
        {selectedViewMode === 'TABLE' ? (
          <DrugTableView dataMedicationSearch={dataMedicationSearch} />
        ) : (
          <DrugDataListTable loading={isValidating} data={data} />
        )}
        <GroupDrugDataFormDialog formData={formDataGroup} onClose={() => setFormDataGroup(undefined)} />
        <DrugDataFormDialog dataMedicationCategory={dataMedicationCategory} />
      </Box>
    </DrugDatabaseContext.Provider>
  );
};

export default MedicalPageTab;
