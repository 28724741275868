import { MedicationCountResultSchema } from './../schema/MedicationCountResult';
import { createPageSchema } from 'modules/schema/common';
import { CODE_SYSTEM, DIAGNOSTIC_IMAGE_TYPE, INDICATION_REQUEST_TYPE, some } from './constants';

import { DENTAL_GROUP, DENTAL_GROUP_DETAIL } from 'modules/reception/component/dental/DentalDetail/common/constants';
import {
  APPOINTMENT_TYPES,
  CARE_PLAN_SCOPE,
  ENCOUNTER_STATUS,
  ICD_TYPE,
  MEDICATION_CATEGORY_SCOPE,
  MEDICATION_USAGE_TYPE,
  PAYMENT_ADVANCE_STATUS,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PRESCRIPTION_TYPE,
  SERVICE_TYPE_ALL,
  SYSTEM_CATEGORY_SCOPE,
  SYSTEM_CATEGORY_SCOPE as API_SYSTEM_CATEGORY_SCOPE,
  SYSTEM_CATEGORY_TYPE,
  MEDICATION_STATUS,
  INVENTORY_STATUS,
} from './apiConstants';
import { APPOINTMENT_STATUS } from 'modules/admin/component/account/constant';
import {
  AppointmentGroupSchema,
  AppointmentSchema,
  AppointmentTherapySchema,
  AppointmentUpcomingShema,
  AttachedDocumentSchema,
  AttendPersonSchema,
  CarePlanSchema,
  CodeSystemSchema,
  CollaboratorSchema,
  createSystemCategorySchema,
  DentalDiagnosisBasicSchema,
  DentalExamSchema,
  DentalInfoSchema,
  DentalToothDetailSchema,
  DiagnosticImageSchema,
  DistributionCustomerByRevenueSchema,
  EmployeeSchema,
  EncounterSchema,
  GroupSchema,
  IcdDiagnosisSchema,
  IndicationHistorySchema,
  IndicationImageSchema,
  IndicationLaboratoryListSchema,
  IndicationProcedureSchema,
  IndicationRequestSchema,
  InventoryAlertSettingSchema,
  InventoryExportSchema,
  InventoryImportSchema,
  InventoryInfoSchema,
  InventorySchema,
  ManagementAgencySchema,
  MedicalEquipmentSchema,
  MedicalSuppliesSchema,
  MedicalSuppliesUsageSchema,
  MedicationCategorySchema,
  MedicationInstructionSchema,
  MedicationSchema,
  MedicationSupplierSchema,
  MedicationUnitSettingSchema,
  MedicationUsageSchema,
  ObservationSchema,
  PatientSchema,
  PaymentAdvanceSchema,
  PaymentHistoryDetailSchema,
  PaymentHistorySchema,
  PaymentSchema,
  PhysicsRoomSchema,
  PrePaymentSchema,
  PrescriptionDetailSchema,
  PrescriptionDoctorSchema,
  PrescriptionFormTemplateSchema,
  PrescriptionSchema,
  ProvinceSchema,
  ReportDebtSchema,
  ReportImportExpenseSchema,
  ReportProfitDetailSchema,
  ReportRevenueDetailSchema,
  ReportSchema,
  RequestAppointmentSchema,
  RevenueExpenditureSchema,
  RevenueReportSchema,
  SaleReceiptSchema,
  ServiceSchema,
  SpecializedDepartmentSchema,
  StorageResourceSchema,
  SystemCategorySchema,
  TherapyDefinitionSchema,
  TherapyHistorySchema,
  TherapySchema,
  TherapySessionSchema,
  UserEmployeeRoleSchema,
  UserSchema,
} from 'modules/schema';
import { z } from 'zod';
import { EvaluationFormSchema } from 'modules/schema/EvaluationForms';
import { MedicalExaminationFormSchema } from 'modules/schema/MedicalExaminationForm';
import { Pageable } from './type';
import { STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';
import { MessageFullSchema } from 'modules/schema/MessageFull';

export function mappedBody(value?: some) {
  if (!value) {
    return null;
  }
  return Object.keys(value).reduce((val, key) => {
    const tmp = value[key];
    return {
      ...val,
      [key]: tmp === '' ? null : typeof tmp === 'object' && !Array.isArray(tmp) ? mappedBody(tmp) : tmp,
    };
  }, {});
}

export function mappedFlatParams(value?: some) {
  if (!value) {
    return {};
  }
  return Object.keys(value).reduce((val, key) => {
    const tmp = value[key];
    if (tmp?.id) {
      return {
        ...val,
        [key]: tmp.id,
      };
    }
    if (tmp !== null && tmp !== undefined && val !== '') {
      return {
        ...val,
        [key]: tmp,
      };
    }
    return val;
  }, {});
}

export const stringifyUrl = function (obj = {}) {
  const tmp = mappedFlatParams(obj);
  var str: string[] = [];
  for (const p in tmp)
    if (tmp.hasOwnProperty(p)) {
      const value = tmp[p];
      if (Array.isArray(value)) {
        value.forEach((val) => {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(val));
        });
      } else {
        str.push(
          encodeURIComponent(p) +
            '=' +
            encodeURIComponent(
              typeof value === 'object'
                ? JSON.stringify(value)
                : typeof value === 'string' || typeof value === 'number'
                ? value
                : typeof value === 'boolean'
                ? String(value)
                : '',
            ),
        );
      }
    }
  return str.join('&');
};

export const GATEWAY_HOST = process.env.REACT_APP_GATEWAY_HOST;
export const IDENTITY = 'identity/api';
export const CORE = process.env.REACT_APP_CORE_API ? process.env.REACT_APP_CORE_API : 'core/api';
export const NOTIFICATION = 'notification/api';

export const API_SERVER = {
  supplier: {
    get: (params?: { search?: string; page?: number; pageSize?: number; sortBy?: string; orderBy?: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-suppliers?${stringifyUrl(params)}`,
      schema: createPageSchema(MedicationSupplierSchema),
    }),
    create: `${GATEWAY_HOST}/${CORE}/v1.0/medication-suppliers`,
    update: (id) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-suppliers/${id}`,
  },
  importInventory: {
    index: (params?: some) => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports?${stringifyUrl(params)}`,
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports?${stringifyUrl(params)}`,
      schema: createPageSchema(InventoryImportSchema),
    }),
    update: (id?: number) => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports/${id}`,
    getById: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports/${id}`,
      schema: InventoryImportSchema,
    }),
    processImport: (id?: number) => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports/${id}/process`,
    exportReport: (params?: some) => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports/excel?${stringifyUrl(params)}`,
    uploadTemplate: () => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports/excel`,
    downloadTemplate: `${GATEWAY_HOST}/${CORE}/v1.0/inventory-imports/template/excel`,
  },
  exportInventory: {
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/inventory-exports?${stringifyUrl(params)}`,
      schema: createPageSchema(InventoryExportSchema),
    }),
    getById: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/inventory-exports/${id}`,
      schema: InventoryExportSchema,
    }),
  },
  inventory: {
    index: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/inventories?${stringifyUrl(params)}`,
      schema: createPageSchema(InventorySchema),
    }),
    getLot: (medicationId) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/${medicationId}/inventories`,
      schema: InventorySchema,
    }),
    getInventoryInfo: (medicationId) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/${medicationId}/inventory-info`,
      schema: z.array(InventoryInfoSchema),
    }),
    getListInventoryInfo: (params?: { medicationIds: number[] }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/inventory-info?${stringifyUrl(params)}`,
      schema: z.array(InventoryInfoSchema),
    }),
    getQuantityByMedicationResource: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/inventories/filter-medication-resource-id`,
      schema: z.array(InventorySchema),
    }),
    exportReport: (params?: some) => `${GATEWAY_HOST}/${CORE}/v1.0/inventories/excel?${stringifyUrl(params)}`,
    processInventoryExport: (inventoryExportId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/inventory-exports/${inventoryExportId}/process`,
    createInventoryExportDraft: () => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-exports/draft`,
    updateInventoryExportDraft: (inventoryExportId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/inventory-exports/${inventoryExportId}`,
  },
  inventoryAlertConfig: {
    index: (medicationId) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/${medicationId}/inventory-alert-settings`,
      schema: InventoryAlertSettingSchema,
    }),
    update: (medicationId) => `${GATEWAY_HOST}/${CORE}/v1.0/medications/${medicationId}/inventory-alert-settings`,
  },
  medication: {
    import: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/import`,
      schema: z.array(MedicationSchema),
    }),
    index: (params?: {
      categoryIds?: number[];
      productName?: string;
      scope?: keyof typeof MEDICATION_CATEGORY_SCOPE;
      medicationStatus?: keyof typeof MEDICATION_STATUS;
      inventoryStatus?: keyof typeof INVENTORY_STATUS;
      fromDate?: string;
      toDate?: string;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications?${stringifyUrl(params)}`,
      schema: z.array(MedicationSchema),
    }),
    inventories: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/inventories?${stringifyUrl(params)}`,
      schema: z.array(MedicationSchema),
    }),
    getDetail: (medicationId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/${medicationId}`,
      schema: MedicationSchema,
    }),
    update: (id: number, params?: { categoryId: number }) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/medications/${id}?${stringifyUrl(params)}`,
    getDetailDrugClinic: (medicationId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-clinics/${medicationId}`,
      schema: MedicationSchema,
    }),
    deleteMultiple: (categoryId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-clinics/category/${categoryId}`,
    uploadMultiMedication: () => `${GATEWAY_HOST}/${CORE}/v1.0/medications/multiple`,
    medicationDashboard: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/dashboard`,
      schema: MedicationCountResultSchema,
    }),
    search: (
      params?: {
        scope?: keyof typeof MEDICATION_CATEGORY_SCOPE;
        medicationStatus?: keyof typeof MEDICATION_STATUS;
        productName?: string;
        categoryIds?: number[];
        inventoryStatus?: keyof typeof INVENTORY_STATUS;
        fromDate?: string;
        toDate?: string;
      } & Pageable,
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medications/search?${stringifyUrl(params)}`,
      schema: createPageSchema(MedicationSchema),
    }),
  },
  medicationCategory: {
    getCategoryList: (params?: {
      scope?: keyof typeof MEDICATION_CATEGORY_SCOPE;
      medicationStatus?: keyof typeof MEDICATION_STATUS;
      productName?: string;
      categoryIds?: number[];
      inventoryStatus?: keyof typeof INVENTORY_STATUS;
      fromDate?: string;
      toDate?: string;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-categories?${stringifyUrl(params)}`,
      schema: z.array(MedicationCategorySchema),
    }),
    getCategoryByClinicId: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-categories?${stringifyUrl(params)}`,
      schema: z.array(MedicationCategorySchema),
    }),
    update: (categoryId) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-categories/${categoryId}`,
    getTotalCategory: (id) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-categories/${id}`,
      schema: MedicationCategorySchema,
    }),
    downloadSimpleExchangeUnitFile: `${GATEWAY_HOST}/${CORE}/v1.0/medications/unit-exchange/excel`,
    downloadSimpleDrugFile: `${GATEWAY_HOST}/${CORE}/v1.0/medications/download/excel`,
    uploadExchangeUnitFile: () => `${GATEWAY_HOST}/${CORE}/v1.0/medications/unit-exchange/excel`,
    uploadDrugFile: () => `${GATEWAY_HOST}/${CORE}/v1.0/medications/import/excel`,
    getAllFetchCategory: (params?: {
      scope: keyof typeof MEDICATION_CATEGORY_SCOPE;
      isShowQuantityInventory?: boolean;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-categories/medications?${stringifyUrl(params)}`,
      schema: z.array(MedicationCategorySchema),
    }),
  },
  receiptManagement: {
    cancel: (receiptId) => `${GATEWAY_HOST}/${CORE}/v1.0/sale-receipts/${receiptId}/cancel`,
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/sale-receipts?${stringifyUrl(params)}`,
      schema: createPageSchema(SaleReceiptSchema),
    }),
    getDetail: (receiptId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/sale-receipts/${receiptId}`,
      schema: SaleReceiptSchema,
    }),
  },
  sale: {
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/sale-receipts`,
    listPrescription: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/doctor-prescription?${stringifyUrl(params)}`,
      schema: z.array(SaleReceiptSchema),
    }),
    listPrescriptionSpecialist: (params?: {
      mobilePhone?: string;
      patientName?: string;
      picName?: string;
      prescriptionDate?: string;
      type: string;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions/doctor?${stringifyUrl(params)}`,
      schema: z.array(PrescriptionDoctorSchema),
    }),
    prescriptionDetail: (params?: { id?: number; type: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions?${stringifyUrl(params)}`,
      schema: PrescriptionSchema,
    }),
  },
  code_system: {
    getList: (params?: { type: typeof CODE_SYSTEM[keyof typeof CODE_SYSTEM] }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/code-system?${stringifyUrl(params)}`,
      schema: z.array(CodeSystemSchema),
    }),
  },
  specializedDepartment: {
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/specialized-departments`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/specialized-departments/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/specialized-departments/${id}`,
    getAll: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/specialized-departments`,
      schema: z.array(SpecializedDepartmentSchema),
    }),
  },

  report: {
    getListImportExpense: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/import-expense/chart?${stringifyUrl(params)}`,
      schema: z.array(ReportSchema),
    }),
    getListDetailImportExpense: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/import-expense/detail?${stringifyUrl(params)}`,
      schema: z.array(ReportImportExpenseSchema),
    }),
    getListDetailRevenue: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/revenue/detail?${stringifyUrl(params)}`,
      schema: z.array(ReportRevenueDetailSchema),
    }),
    exportDetailedRevenueExcel: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/report/revenue/excel?${stringifyUrl(params)}`,
    exportDetailedImportExcel: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/report/import-expense/detail/excel?${stringifyUrl(params)}`,
    getListDetailProfit: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/profit/detail?${stringifyUrl(params)}`,
      schema: z.array(ReportProfitDetailSchema),
    }),
    exportDetailedProfitExcel: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/report/profit/detail/excel?${stringifyUrl(params)}`,
    exportReport: (params?: some) => `${GATEWAY_HOST}/${CORE}/v1.0/inventory-exports/excel?${stringifyUrl(params)}`,
    getListTherapyRevenue: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/therapy/revenue?${stringifyUrl(params)}`,
      schema: z.array(TherapySchema),
    }),
    getListDentalRevenue: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/dental/revenue?${stringifyUrl(params)}`,
      schema: z.array(DentalExamSchema),
    }),
    exportDetailTherapyRevenueExcel: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/report/therapy/revenue/excel?${stringifyUrl(params)}`,
    exportDetailDetalRevenueExcel: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/report/dental/revenue/excel?${stringifyUrl(params)}`,
    searchDebtReport: (
      params: {
        paymentTypeList?: (keyof typeof SERVICE_TYPE_ALL)[];
        keyword?: string;
        fromDate: string;
        toDate: string;
      } & Pageable,
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/debt?${stringifyUrl(params)}`,
      schema: createPageSchema(ReportDebtSchema),
    }),
    exportDebtReportExcel: (
      params: {
        paymentTypeList?: (keyof typeof SERVICE_TYPE_ALL)[];
        fromDate: string;
        toDate: string;
      } & Pageable,
    ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/debt/excel?${stringifyUrl(params)}`,
    getTherapyRevenueExpenditureReport: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/revenue-expenditure?${stringifyUrl(params)}`,
      schema: z.array(RevenueExpenditureSchema),
    }),
    exportTherapyRevenueExpenditureReportExcel: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/report/revenue-expenditure/excel?${stringifyUrl(params)}`,
  },
  medicationUnitSetting: {
    getMedicationUnitSettings: (
      medicationId: number,
      params?: {
        lot: string;
      },
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-unit-settings/medications/${medicationId}?${stringifyUrl(params)}`,
      schema: z.array(MedicationUnitSettingSchema),
    }),
    updateMedicationUnitSettings: () => `${GATEWAY_HOST}/${CORE}/v1.0/medication-unit-settings`,
  },
  sequenceNumber: {
    generate: `${GATEWAY_HOST}/${CORE}/v1.0/sequence-numbers`,
  },
  physicsRoom: {
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/physics-rooms?${stringifyUrl(params)}`,
      schema: z.array(createSystemCategorySchema(PhysicsRoomSchema)),
    }),
    getListByCategoryCode: (categoryType: { code: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/physics-rooms/${categoryType.code}`,
      schema: z.array(PhysicsRoomSchema),
    }),
    create: (categoryId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/physics-rooms/categories/${categoryId}`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/physics-rooms/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/physics-rooms/${id}`,
    getListByCategory: (categoryCode) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/physics-rooms/${categoryCode}`,
      schema: z.array(PhysicsRoomSchema),
    }),
  },
  medicalEquipment: {
    search: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medical-equipment?${stringifyUrl(params)}`,
      schema: z.array(createSystemCategorySchema(MedicalEquipmentSchema)),
    }),
    update: (medicalEquipmentId) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-equipment/${medicalEquipmentId}`,
    create: (categoryId) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-equipment/${categoryId}`,
    delete: (medicalEquipmentId) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-equipment/${medicalEquipmentId}`,
    getListByCategory: (categoryCode) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medical-equipment/${categoryCode}`,
      schema: z.array(MedicalEquipmentSchema),
    }),
  },
  systemCategory: {
    getList: (params?: {
      type: keyof typeof SYSTEM_CATEGORY_TYPE;
      scope: keyof typeof API_SYSTEM_CATEGORY_SCOPE;
      id?: number;
      parentId?: number;
      name?: string;
      itemName?: string;
      isShowList?: boolean;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/system-categories?${stringifyUrl(params)}`,
      schema: z.array(createSystemCategorySchema(ServiceSchema)),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/system-categories`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/system-categories/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/system-categories/${id}`,
  },
  service: {
    getSystemCategoryList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/services/system-categories?${stringifyUrl(params)}`,
      schema: z.array(createSystemCategorySchema(ServiceSchema)),
    }),
    getAllSystemCategoryList: (params?: { scope: (keyof typeof SYSTEM_CATEGORY_SCOPE)[]; serviceName?: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/services/system-categories/get-all?${stringifyUrl(params)}`,
      schema: z.array(createSystemCategorySchema(ServiceSchema)),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/services`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/services/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/services/${id}`,
    deleteMultiple: `${GATEWAY_HOST}/${CORE}/v1.0/services`,
    import: (categoryId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/services/import-csv/${categoryId}`,
    getServiceById: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/services/${id}`,
      schema: ServiceSchema,
    }),
    downloadLaboratoryPriceCSV: (params: { scope: keyof typeof SYSTEM_CATEGORY_SCOPE; parentId: number }) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/services/laboratory/download?${stringifyUrl(params)}`,
  },
  department: {
    getDepartmentList: `${GATEWAY_HOST}/${CORE}/v1.0/specialized-departments`,
  },
  therapyManagement: {
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapy-definition?${stringifyUrl(params)}`,
      schema: z.array(SystemCategorySchema),
    }),
    getDetailById: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapy-definition/${id}`,
      schema: TherapyDefinitionSchema,
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-definition`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-definition/${id}`,
    changeStatus: (id: number, params?: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/therapy-definition/change-status/${id}?${stringifyUrl(params)}`,
  },
  patient: {
    searchPatient: (params) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/patients?sortBy=id&orderBy=DESC&${stringifyUrl(params)}`,
      schema: createPageSchema(PatientSchema),
    }),
    getPatient: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/patients/${id}`,
      schema: PatientSchema,
    }),
    create: (isDirectly?: boolean) => `${GATEWAY_HOST}/${CORE}/v1.0/patients?isDirectly=${isDirectly || false}`,
    edit: (patientId) => `${GATEWAY_HOST}/${CORE}/v1.0/patients/${patientId}`,
    updatePatient: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/patients/${id}`,
    delete: (patientId) => `${GATEWAY_HOST}/${CORE}/v1.0/patients/${patientId}`,
    downloadPatientInfoTemplateFile: `${GATEWAY_HOST}/${CORE}/v1.0/patients/import-template`,
    uploadPatientInfoFile: () => `${GATEWAY_HOST}/${CORE}/v1.0/patients/import-template`,
    exportPatientsInfo: (params) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/patients/export?sortBy=id&orderBy=DESC&${stringifyUrl(params)}`,
    getAllCollaborator: {
      url: `${GATEWAY_HOST}/${CORE}/v1.0/patients/employees/all`,
      schema: z.array(CollaboratorSchema),
    },
    searchByMobilePhoneAndName: (params: { mobilePhone?: string; name?: string; absolute?: boolean }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/patients/all?${stringifyUrl(params)}`,
      schema: z.array(PatientSchema),
    }),
  },
  appointmentGroup: {
    searchByMobilePhone: (params: { mobilePhone?: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/appointment-groups?${stringifyUrl(params)}`,
      schema: z.array(AppointmentGroupSchema),
    }),
  },
  laboratoryReference: {
    getList: (serviceId: string) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/laboratory-reference/${serviceId}`,
      schema: ServiceSchema,
    }),
    create: (serviceId: string) => `${GATEWAY_HOST}/${CORE}/v1.0/laboratory-reference/${serviceId}`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/laboratory-reference/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/laboratory-reference/${id}`,
  },
  medicalSupplies: {
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies?${stringifyUrl(params)}`,
      schema: z.array(createSystemCategorySchema(MedicalSuppliesSchema)),
    }),
    create: (categoryId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies/${categoryId}`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies/${id}`,
    deleteMultiple: `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies`,
    importList: () => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies/import`,
  },
  medicalExaminationForm: {
    getList: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medical-examination-forms`,
      schema: z.array(MedicalExaminationFormSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/medical-examination-forms`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-examination-forms/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-examination-forms/${id}`,
  },
  drugClinic: {
    index: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-clinics/${id}`,
  },
  therapy: {
    searchTherapy: (params: { patientId: number } & Pageable) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapies/history?${stringifyUrl(params)}`,
      schema: createPageSchema(TherapyHistorySchema),
    }),
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapies?${stringifyUrl(params)}`,
      schema: createPageSchema(TherapySchema),
    }),
    getListCashier: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapies/payment?${stringifyUrl(params)}`,
      schema: createPageSchema(TherapySchema),
    }),
    assignment: () => `${GATEWAY_HOST}/${CORE}/v1.0/therapies/assignment`,
    detail: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapies/${id}`,
      schema: TherapySchema,
    }),
    assignmentReference: () => `${GATEWAY_HOST}/${CORE}/v1.0/therapies/assignment-reference`,
    getTherapyByReference: (referenceId: number, params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapies/reference/${referenceId}?${stringifyUrl(params)}`,
      schema: z.array(TherapySchema),
    }),
    updateTherapySession: (therapySessionId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${therapySessionId}/appointment`,
    getTherapyAppointments: (params) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapies/appointment?${stringifyUrl(params)}`,
      schema: createPageSchema(AppointmentTherapySchema),
    }),
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapies/${id}`,
    cancel: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapies/${id}/cancel`,
    finish: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapies/${id}/finish`,
    updateDiagnosis: `${GATEWAY_HOST}/${CORE}/v1.0/therapies/diagnosis`,
    note: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapies/${id}/note`,
  },
  therapySession: {
    paymentDetail: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${id}/payment-detail`,
      schema: TherapySessionSchema,
    }),
    detail: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${id}`,
      schema: TherapySessionSchema,
    }),
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${id}`,
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/add`,
    updateName: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${id}/name`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${id}`,
    clone: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${id}/clone`,
  },
  therapyIndication: {
    request: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${id}`,
    finish: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${id}/finish`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${id}`,
    requestIndication: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${id}/request`,
  },
  diagnosticImage: {
    getTherapyDiagnosticImages: (sessionId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/therapy-sessions/${sessionId}/diagnostic-images`,
      schema: z.array(DiagnosticImageSchema),
    }),
    getDiagnosticImages: (params: { referenceId: number; imageType: keyof typeof DIAGNOSTIC_IMAGE_TYPE }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/diagnostic-images?${stringifyUrl(params)}`,
      schema: z.array(DiagnosticImageSchema),
    }),
    getDiagnosticImage: (diagnosticImageId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/diagnostic-images/${diagnosticImageId}`,
      schema: DiagnosticImageSchema,
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/diagnostic-images`,
    update: (diagnosticImageId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/diagnostic-images/${diagnosticImageId}`,
    delete: (diagnosticImageId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/diagnostic-images/${diagnosticImageId}`,
  },
  storageResource: {
    getFile: (id?: number) => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/download/${id}`,
    getList: (params: { ids?: number[]; referenceId?: number; type?: keyof typeof STORAGE_RESOURCE_TYPE }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources?${stringifyUrl(params)}`,
      schema: z.array(StorageResourceSchema),
    }),
    upload: (params) => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/upload?${stringifyUrl(params)}`,
    uploadMultiFile: (params) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/upload-multiple?${stringifyUrl(params)}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/${id}`,
    deleteMultiple: () => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/delete-multiple`,
    updateDescription: () => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/descriptions`,
    doawloadFile: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/download/${id}`,
  },
  therapyMedicalSupplies: {
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage`,
    index: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage/${id}`,
  },
  indicationAttendPerson: {
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/attend-persons`,
    delete: (indicationAttendPersonId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/attend-persons/${indicationAttendPersonId}`,
    get: (indicationRequestId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/attend-persons?indicationRequestId=${indicationRequestId}`,
      schema: z.array(AttendPersonSchema),
    }),
  },
  indicationRequest: {
    get: (indicationRequestId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${indicationRequestId}`,
      schema: IndicationRequestSchema,
    }),
    getIndicationRequestByReferenceId: (
      referenceId: number,
      type: keyof typeof INDICATION_REQUEST_TYPE,
      scope?: keyof typeof SYSTEM_CATEGORY_SCOPE | (keyof typeof SYSTEM_CATEGORY_SCOPE)[],
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/reference/${referenceId}?type=${type}${
        scope ? `&scope=${scope}` : ''
      }`,
      schema: z.array(IndicationRequestSchema),
    }),
    getByReferenceIdIndication: (
      referenceId: number,
      params: {
        type: string;
        scope: string;
      },
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/reference/${referenceId}?${stringifyUrl(params)}`,
      schema: z.array(IndicationRequestSchema),
    }),
    getList: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests?${stringifyUrl(params)}`,
      schema: createPageSchema(IndicationRequestSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests`,
    updateIndicationRequest: (indicationRequestId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${indicationRequestId}`,
    deleteIndicationRequest: (indicationRequestId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${indicationRequestId}`,
    finishIndicationRequest: (indicationRequestId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${indicationRequestId}/finish`,
    getIndicationLaboratories: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories?${stringifyUrl(params)}`,
      schema: IndicationLaboratoryListSchema,
    }),
    updateIndicationLaboratories: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories`,
    finishIndicationLaboratories: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories/finish`,
    requestIndication: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${id}/request`,
    updateNote: (indicationRequestId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${indicationRequestId}/note`,
    createMultiple: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/multiple`,
    note: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/${id}/note`,
    changeStatusToRequestedMultiple: (params: { indicationRequestIds: number[] }) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/request/multiple?${stringifyUrl(params)}`,
    deleteMultiple: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests`,
    completeMultiple: `${GATEWAY_HOST}/${CORE}/v1.0/indication-requests/finish`,
  },
  indicationMedicalSupplies: {
    get: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-medical-supplies?${stringifyUrl(params)}`,
      schema: z.array(MedicalSuppliesUsageSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-medical-supplies`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-medical-supplies/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/indication-medical-supplies/${id}`,
  },
  payment: {
    detail: (id: number, params: { type: keyof typeof PAYMENT_TYPE; isShowAll?: boolean }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/payments/${id}/payment-details?${stringifyUrl(params)}`,
      schema: PaymentSchema,
    }),
    pay: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/payments/${id}`,
    refund: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/payments/${id}/refund`,
    calculate: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/payments/${id}/calculate`,
  },
  indicationLaboratory: {
    getList: (indicationRequestId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories?indicationRequestId=${indicationRequestId}`,
      schema: IndicationLaboratoryListSchema,
    }),
    getListByRefereceId: (params: { referenceId: number; type: keyof typeof INDICATION_REQUEST_TYPE }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories/reference?${stringifyUrl(params)}`,
      schema: z.array(IndicationLaboratoryListSchema),
    }),
    finishLaboratories: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories`,
    updateList: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories`,
    getAllIndicationLaboratoryHistory: (params: {
      type: keyof typeof INDICATION_REQUEST_TYPE;
      patientId: number;
      laboratoryReferenceId: number;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-laboratories/history?${stringifyUrl(params)}`,
      schema: z.array(IndicationHistorySchema),
    }),
  },
  indicationImage: {
    get: (indicationRequestId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-images?indicationRequestId=${indicationRequestId}`,
      schema: IndicationImageSchema,
    }),
    update: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-images`,
    finishIndication: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-images/finish`,
  },
  indicationMedication: {
    get: (indicationRequestId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-medications?indicationRequestId=${indicationRequestId}`,
      schema: z.array(MedicationUsageSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/indication-medications`,
    update: (indicationMedicationId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-medications/${indicationMedicationId}`,
    delete: (indicationMedicationId: number) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-medications/${indicationMedicationId}`,
  },
  indicationProcedures: {
    get: (indicationRequestId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/indication-procedures?indicationRequestId=${indicationRequestId}`,
      schema: IndicationProcedureSchema,
    }),
    update: `${GATEWAY_HOST}/${CORE}/v1.0/indication-procedures`,
    createIndicationProcedureWithAttendPerson: () =>
      `${GATEWAY_HOST}/${CORE}/v1.0/indication-procedures-with-attend-person`,
  },
  paymentHistory: {
    getList: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/payment-history/payment/${id}`,
      schema: z.array(PaymentHistorySchema),
    }),
    getRefundDetail: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/payment-history/refund/${id}`,
      schema: PaymentHistorySchema,
    }),
    getDetail: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/payment-history/detail/${id}`,
      schema: PaymentHistoryDetailSchema,
    }),
  },
  prepayment: {
    getListPaymentAdvance: (params: { paymentId: number; statusList?: (keyof typeof PAYMENT_ADVANCE_STATUS)[] }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prepayment/payment-advances?${stringifyUrl(params)}`,
      schema: z.array(PaymentAdvanceSchema),
    }),
    getPrepayment: (params: { paymentId: number; statuses?: (keyof typeof PAYMENT_ADVANCE_STATUS)[] }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prepayment?${stringifyUrl(params)}`,
      schema: PrePaymentSchema,
    }),
    createPaymentAdvance: `${GATEWAY_HOST}/${CORE}/v1.0/prepayment/payment-advances`,
    updatePaymentAdvance: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prepayment/payment-advances/${id}`,
    confirmPrepayment: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prepayment/payment-advances/${id}/confirm`,
    refundPrepayment: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prepayment/${id}/refund`,
    deletePaymentAdvance: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prepayment/payment-advances/${id}`,
  },
  therapyReport: {
    expectedRevenueReport: (params) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/expected-revenue?${stringifyUrl(params)}`,
      schema: z.array(RevenueReportSchema),
    }),
    patientRevenueReport: (params) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/report/patient/revenue?${stringifyUrl(params)}`,
      schema: z.array(DistributionCustomerByRevenueSchema),
    }),
  },
  dentalExam: {
    getExaminationList: (patientId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/history?patientId=${patientId}`,
      schema: z.array(DentalExamSchema),
    }),
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams?${stringifyUrl(params)}`,
      schema: createPageSchema(DentalExamSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams`,
    update: (id) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${id}`,
    changePicUser: (id) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${id}/change-pic`,
    getDentalExam: (dentalExamId) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${dentalExamId}`,
      schema: DentalExamSchema,
    }),
    getListWithPaymentDetail: (params?: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/payment?${stringifyUrl(params)}`,
      schema: createPageSchema(DentalExamSchema),
    }),
    acceptDentalEncounter: (id) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${id}/accept`,
  },
  dental: {
    getListOdontogram: (params: { patientId: number; dentalExamId: number }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-tooth-model?${stringifyUrl(params)}`,
      schema: z.array(DentalToothDetailSchema),
    }),
    updateListOdontogram: () => `${GATEWAY_HOST}/${CORE}/v1.0/dental-tooth-model/switch`,
    getDentalExam: (dentalExamId: string) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${dentalExamId}`,
      schema: DentalExamSchema,
    }),
    updateDental: () => `${GATEWAY_HOST}/${CORE}/v1.0/dental-info`,
    getDentalInfo: (params: {
      dentalExamId: number;
      patientId: number;
      position?: number;
      objectType: keyof typeof DENTAL_GROUP;
      objectDetail?: keyof typeof DENTAL_GROUP_DETAIL;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-info?${stringifyUrl(params)}`,
      schema: DentalInfoSchema,
    }),
    getDentalDiagnosis: (params: { patientId: number; dentalExamId: number }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-info/board?${stringifyUrl(params)}`,
      schema: z.array(DentalInfoSchema),
    }),
    deleteDentalDiagnosis: (dentalDiagnosisId: number, params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis/${dentalDiagnosisId}?${stringifyUrl(params)}`,
    completeDentalDiagnosis: (dentalDiagnosisId: number, params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis/${dentalDiagnosisId}/finish?${stringifyUrl(params)}`,
    noteDentalDiagnosis: (dentalDiagnosisId: number, params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis/${dentalDiagnosisId}/note?${stringifyUrl(params)}`,
    getDentalPrescriptions: (params: { referenceId: number; type: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions?${stringifyUrl(params)}`,
      schema: PrescriptionSchema,
    }),
    getDentalPrescriptionsPatient: (params: { referenceId: number; patientId: number; type: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions/search?${stringifyUrl(params)}`,
      schema: z.array(PrescriptionSchema),
    }),
    createDentalPrescriptions: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions`,
    createDentalPrescriptionsClone: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions/clone`,
    createDentalPrescriptionsDetail: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-details`,
    createMultipleDentalPrescriptionsDetail: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-details/multiple`,
    deleteDentalPrescriptionsDetail: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-details/${id}`,
    updateDentalPrescriptionsDetail: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-details/${id}`,
    accept: (dentalExamId: some) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${dentalExamId}/accept`,
    getDentalPrescriptionTemplate: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates`,
      schema: z.array(PrescriptionFormTemplateSchema),
    }),
    createDentalPrescriptionTemplate: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates`,
    complete: (dentalExamId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${dentalExamId}/finish`,
    cancelDentalExam: (dentalExamId: number) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-exams/${dentalExamId}/cancel`,
  },
  dentalDiagnosisBasic: {
    getList: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis-basics/dental-exam?${stringifyUrl(params)}`,
      schema: z.array(DentalDiagnosisBasicSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis-basics`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis-basics/${id}`,
    createList: (dentalExamId: number, params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis-basics/${dentalExamId}/multiple?${stringifyUrl(params)}`,
    getListByPatient: (params: some) =>
      `${GATEWAY_HOST}/${CORE}/v1.0/dental-diagnosis-basics/dental-exam?${stringifyUrl(params)}`,
  },
  medicationUsage: {
    getList: (
      referenceId: number,
      params: { type: keyof typeof MEDICATION_USAGE_TYPE; indicationRequestId?: number },
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-usages/reference/${referenceId}?${stringifyUrl(params)}`,
      schema: z.array(MedicationUsageSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/medication-usages`,
    index: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-usages/${id}`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-usages/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-usages/${id}`,
    updateMultipleMedicationUsages: () => `${GATEWAY_HOST}/${CORE}/v1.0/medication-usages/multiple`,
  },
  medicalSuppliesUsage: {
    getList: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage?${stringifyUrl(params)}`,
      schema: z.array(MedicalSuppliesUsageSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage`,
    index: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage/${id}`,
      schema: MedicalSuppliesUsageSchema,
    }),
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage/${id}`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage/${id}`,
    updateMultipleMedicalSuppliesUsages: () => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage/multiple`,
    createMultiple: () => `${GATEWAY_HOST}/${CORE}/v1.0/medical-supplies-usage/multiple`,
  },
  prescriptionFormTemplate: {
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates/${id}`,
    getAll: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates`,
      schema: z.array(PrescriptionFormTemplateSchema),
    }),
    getDetail: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates/${id}`,
      schema: PrescriptionFormTemplateSchema,
    }),
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-form-templates/${id}`,
  },
  evaluationForms: {
    getAll: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/evaluation-forms`,
      schema: z.array(EvaluationFormSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/evaluation-forms`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/evaluation-forms/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/evaluation-forms/${id}`,
  },
  requestAppointment: {
    getList: (params: some) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/request-appointments?${stringifyUrl(params)}`,
      schema: createPageSchema(RequestAppointmentSchema),
    }),
    create: (groupId: number) => `${GATEWAY_HOST}/${CORE}/public/v1.0/request-appointments/groups/${groupId}`,
    approve: () => `${GATEWAY_HOST}/${CORE}/v1.0/request-appointments/approve`,
    reject: (params: some) => `${GATEWAY_HOST}/${CORE}/v1.0/request-appointments/reject?${stringifyUrl(params)}`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/request-appointments/${id}`,
  },
  s3Resource: {
    downloadPublic: (id: number) => `${GATEWAY_HOST}/${CORE}/public/storage-resources/download/${id}`,
    download: (id: string) => `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/download/${id}`,
    upload: `${GATEWAY_HOST}/${CORE}/v1.0/storage-resources/upload`,
  },
  carePlan: {
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/care-plans`,
    get: (params: { referenceId: number; scope: keyof typeof CARE_PLAN_SCOPE }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/care-plans?${stringifyUrl(params)}`,
      schema: CarePlanSchema,
    }),
  },
  appointment: {
    searchAll: (params: {
      fromDate?: string;
      toDate?: string;
      picUserIds?: number[];
      mobilePhone?: string;
      type?: keyof typeof APPOINTMENT_TYPES;
      status?: (keyof typeof APPOINTMENT_STATUS)[];
      referenceId?: number;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/appointments/all?${stringifyUrl(params)}`,
      schema: z.array(AppointmentSchema),
    }),

    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/appointments`,
    createMultiple: () => `${GATEWAY_HOST}/${CORE}/v1.0/appointments/multiple`,
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/appointments/${id}`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/appointments/${id}`,
    getUpcomingAppoimentOfPatient: (patientId: number, params: { fromDate: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/appointments/upcoming/${patientId}?${stringifyUrl(params)}`,
      schema: z.array(AppointmentUpcomingShema),
    }),
    overlap: (params: { fromDate?: string; toDate?: string; picUserIds?: number[] }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/appointments/overlap?${stringifyUrl(params)}`,
      schema: z.array(AppointmentSchema),
    }),
  },
  encounter: {
    searchEncounterIndicationRequest: (params: {
      fromDate?: string;
      toDate?: string;
      picName?: string;
      yearOfDOB?: number;
      patientName?: string;
      physicsRoomId?: number;
      status?: (keyof typeof ENCOUNTER_STATUS)[];
      page?: number;
      mobilePhone?: string;
      pageSize?: number;
      sortBy?: string;
      orderBy?: string;
      loadIndicationRequestQuantity?: boolean;
      scope?: keyof typeof SYSTEM_CATEGORY_SCOPE;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/encounters/indication-request?${stringifyUrl(params)}`,
      schema: createPageSchema(EncounterSchema),
    }),
    search: (params: {
      fromDate?: string;
      toDate?: string;
      picName?: string;
      yearOfDOB?: number;
      patientName?: string;
      physicsRoomId?: number;
      status?: (keyof typeof ENCOUNTER_STATUS)[];
      page?: number;
      mobilePhone?: string;
      pageSize?: number;
      sortBy?: string;
      orderBy?: string;
      loadIndicationRequestQuantity?: boolean;
      scope?: keyof typeof SYSTEM_CATEGORY_SCOPE;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/encounters?${stringifyUrl(params)}`,
      schema: createPageSchema(EncounterSchema),
    }),
    payment: (params: {
      fromDate?: string;
      toDate?: string;
      picName?: string;
      yearOfBirth?: string;
      patientCode?: string;
      patientName?: string;
      paymentStatus?: (keyof typeof PAYMENT_STATUS)[];
      status?: (keyof typeof ENCOUNTER_STATUS)[];
      page?: number;
      mobilePhone?: string;
      pageSize?: number;
      sortBy?: string;
      orderBy?: string;
    }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/encounters/payment?${stringifyUrl(params)}`,
      schema: createPageSchema(EncounterSchema),
    }),
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/encounters`,
    cancelEncounter: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}/cancel`,
    get: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}`,
      schema: EncounterSchema,
    }),
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}`,
    updateInfoBasic: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}/info-basic`,
    changePIC: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}/change-pic`,
    receiveEncounter: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}/receive`,
    complete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/encounters/${id}/complete`,
    historyEncounter: (params: { patientId: number }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/encounters/history?${stringifyUrl(params)}`,
      schema: z.array(EncounterSchema),
    }),
    getEncounterHistory: (params: { patientId: number } & Pageable) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/encounters/history/paging?${stringifyUrl(params)}`,
      schema: createPageSchema(EncounterSchema),
    }),
  },
  observation: {
    save: () => `${GATEWAY_HOST}/${CORE}/v1.0/observation`,
    getById: (id: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/observation/${id}`,
      schema: ObservationSchema,
    }),
    getByEncounterId: (encounterId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/observation?encounterId=${encounterId}`,
      schema: ObservationSchema,
    }),
  },
  icdDiagnosis: {
    getByEncounterId: (params: { encounterId?: number; type?: keyof typeof ICD_TYPE }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/icd-diagnosis?${stringifyUrl(params)}`,
      schema: z.array(IcdDiagnosisSchema),
    }),
    updateByEncounterId: () => `${GATEWAY_HOST}/${CORE}/v1.0/icd-diagnosis`,
    deleteIcdDiagnosis: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/icd-diagnosis/${id}`,
  },
  document: {
    getByEncounterId: (params: { referenceId?: number; type?: string }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/attached-documents?${stringifyUrl(params)}`,
      schema: z.array(AttachedDocumentSchema),
    }),
    update: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/attached-documents/${id}`,
    create: () => `${GATEWAY_HOST}/${CORE}/v1.0/attached-documents`,
    delete: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/attached-documents/${id}`,
  },
  nationalData: {
    getAllProvinces: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/national/provinces`,
      schema: z.array(ProvinceSchema),
    }),
    getAllProvincesWithDistricts: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/national/provinces-with-districts`,
      schema: z.array(ProvinceSchema),
    }),
    getProvinceByCode: (code: string) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/national/province/${code}`,
      schema: ProvinceSchema,
    }),
  },
  prescription: {
    getPatientPrescriptions: (
      patientId: number,
      params: {
        type?: keyof typeof PRESCRIPTION_TYPE;
      } & Pageable,
    ) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions/patient/${patientId}?${stringifyUrl(params)}`,
      schema: createPageSchema(PrescriptionSchema),
    }),
    getPrescription: (params: { id?: number; referenceId?: number; type: keyof typeof PRESCRIPTION_TYPE }) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescriptions?${stringifyUrl(params)}`,
      schema: PrescriptionSchema,
    }),
  },
  prescriptionDetail: {
    getPrescriptionDetails: (prescriptionId: number) => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/prescription-details/${prescriptionId}`,
      schema: z.array(PrescriptionDetailSchema),
    }),
    updateMultiplePrescriptionDetail: () => `${GATEWAY_HOST}/${CORE}/v1.0/prescription-details/multiple`,
  },
  medicationInstruction: {
    getMedicationInstructions: () => ({
      url: `${GATEWAY_HOST}/${CORE}/v1.0/medication-instructions`,
      schema: z.array(MedicationInstructionSchema),
    }),
    createMedicationInstruction: () => `${GATEWAY_HOST}/${CORE}/v1.0/medication-instruction`,
    deleteMedicationInstruction: (id: number) => `${GATEWAY_HOST}/${CORE}/v1.0/medication-instruction/${id}`,
  },
};

export const NOTIFICATION_API_SERVER = {
  notification: {
    getPageList: `${GATEWAY_HOST}/${NOTIFICATION}/v1.0/messages`,
    getAllList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${NOTIFICATION}/v1.0/messages?${stringifyUrl(params)}`,
      schema: createPageSchema(MessageFullSchema),
    }),
    readAll: `${GATEWAY_HOST}/${NOTIFICATION}/v1.0/messages/read-all`,
    readDetail: (messageId: number) => `${GATEWAY_HOST}/${NOTIFICATION}/v1.0/messages/${messageId}/read`,
    updateFCMToken: `${GATEWAY_HOST}/${NOTIFICATION}/api/v1.0/users/token`,
  },
};

export const IDENTITY_API_SERVER = {
  user: {
    login: `${GATEWAY_HOST}/${IDENTITY}/v1.0/auth/sign-in`,
    logout: `${GATEWAY_HOST}/${IDENTITY}/v1.0/auth/sign-out`,
    resetPassword: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/password/forgot`,
    verifyResetPassword: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/password/reset`,
    getListUser: (params?: some) => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users?${stringifyUrl(params)}`,
      schema: createPageSchema(UserSchema),
    }),
    adminChangePassword: (userId?: some) => `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/${userId}/password`,
    userChangePassword: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/change-password`,
    deleteUser: (userId?: some) => `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/${userId}`,
    createUser: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users`,
    updateUser: (userId?: some) => `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/${userId}`,
    getListDoctorUser: (params?: some) => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/doctor`,
      schema: z.array(UserSchema),
    }),
    getListUserByRoles: (params?: some) => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/users/current-group?${stringifyUrl(params)}`,
      schema: z.array(UserEmployeeRoleSchema),
    }),
    getPublicUserByRoles: (groupId: number, params: some) => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/public/users/groups/${groupId}?${stringifyUrl(params)}`,
      schema: z.array(UserEmployeeRoleSchema),
    }),
  },
  employee: {
    getList: (params?: some) => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/employees?${stringifyUrl(params)}`,
      schema: createPageSchema(EmployeeSchema),
    }),
    getAllEmployee: {
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/all-employees`,
      schema: z.array(EmployeeSchema),
    },
    update: (id) => `${GATEWAY_HOST}/${IDENTITY}/v1.0/employees/${id}`,
    create: `${GATEWAY_HOST}/${IDENTITY}/v1.0/employees`,
  },
  group: {
    getGroup: () => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/medi/groups`,
      schema: GroupSchema,
    }),
    update: `${GATEWAY_HOST}/${IDENTITY}/v1.0/medi/groups`,
    updateOrganizationName: (organizationId) => `${GATEWAY_HOST}/${IDENTITY}/v1.0/organization-name/${organizationId}`,
    getPublicGroupInfo: (id: number) => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/public/medi/groups/${id}`,
      schema: GroupSchema,
    }),
  },
  management_agency: {
    getInfo: () => ({
      url: `${GATEWAY_HOST}/${IDENTITY}/v1.0/management-agencies`,
      schema: ManagementAgencySchema,
    }),
    update: (params: some) => `${GATEWAY_HOST}/${IDENTITY}/v1.0/management-agencies?${stringifyUrl(params)}`,
  },
};
