import {
  Checkbox,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { FormCommonProps, GroupRender, OptionRender, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';
import { ReactNode, useMemo } from 'react';
import { formatGroupOptions, formatOptions, getError, getOptionValue } from './utils';
import { isArray, keyBy, map } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

type Props<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  readonly options: TOption[] | undefined;
  getValue: keyof TOption | ((option: TOption) => string | number);
  renderLabel: OptionRender<TOption>;
  rawOptionLabel?: boolean;
  groupBy?: keyof TOption | ((option: TOption) => string | number);
  renderGroup?: GroupRender<TOption>;
  rawGroup?: boolean;
  placeholder?: string;
  rawPlaceholder?: boolean;
  multiple?: boolean;
  allOption?: boolean;
  rawError?: boolean;
  getOptionDisabled?: (option: TOption) => boolean;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
  renderValue?: (selectedValues: TOption | TOption[]) => ReactNode;
  onChange?: (selectedValue: TOption | TOption[]) => void;
  onBlur?: (selectedValue: TOption | TOption[]) => void;
  clearButton?: boolean;
  showCheckBox?: boolean;
  showError?: boolean;
  iconCheck?: boolean;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<
    SelectProps,
    | 'name'
    | 'ref'
    | 'placeholder'
    | 'error'
    | 'id'
    | 'multiline'
    | 'renderValue'
    | 'onChange'
    | 'onBlur'
    | 'renderOption'
  >;

const ALL_VALUE = 'ALL';
const PLACEHOLDER_VALUE = '';

const SelectInput = <
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  getValue,
  options,
  renderLabel,
  rawLabel = false,
  rawOptionLabel = false,
  groupBy,
  renderGroup,
  rawGroup = false,
  form,
  placeholder,
  rawPlaceholder = false,
  label,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  rawError = false,
  hideError = false,
  required,
  fullWidth = true,
  multiple,
  disabled = false,
  getOptionDisabled,
  allOption,
  renderValue,
  onChange: onInputChange,
  onBlur: onInputBlur,
  clearButton = false,
  showCheckBox,
  iconCheck,
  showError = false,
  type,
  ...restProps
}: Props<TOption, TFieldValues, TName>) => {
  const id = useId();
  const intl = useIntl();
  const optionFormateds = useMemo(
    () =>
      groupBy
        ? formatGroupOptions(
            options || [],
            groupBy,
            getValue,
            renderLabel,
            intl,
            renderGroup,
            getOptionDisabled,
            rawOptionLabel,
            rawGroup,
          )
        : formatOptions(options || [], getValue, renderLabel, intl, getOptionDisabled, rawOptionLabel),
    [getOptionDisabled, getValue, groupBy, intl, options, rawGroup, rawOptionLabel, renderGroup, renderLabel],
  );
  const fomratedOptionMap = useMemo(() => keyBy(optionFormateds, 'value'), [optionFormateds]);
  const optionMap = useMemo(() => keyBy(options, getValue), [getValue, options]);
  const allOptionValues = useMemo(
    () => options?.filter((option) => !getOptionDisabled || !getOptionDisabled(option)) || [],
    [getOptionDisabled, options],
  );

  return (
    <FormControl
      error={getError(form, name)}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      fullWidth={fullWidth}
      controlProps={controlProps}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      disabled={disabled}
      rawError={rawError}
      type={type}
    >
      <Controller
        name={name}
        control={form.control}
        rules={{
          required: required && intl.formatMessage({ id: 'required' }),
        }}
        render={({ field: { onChange, onBlur, ref, value }, fieldState }) => {
          let convertedValue;
          let isAllOptionSelected = false;
          if (!value) {
            convertedValue = PLACEHOLDER_VALUE;
          } else {
            convertedValue = isArray(value)
              ? value.map((ele) => getOptionValue(ele as TOption, getValue))
              : getOptionValue(value as TOption, getValue);
            isAllOptionSelected = isArray(value)
              ? allOptionValues
                  ?.map((item) => getOptionValue(item as TOption, getValue))
                  ?.every((item) => convertedValue.some((convertedValue) => convertedValue === item))
              : allOptionValues.every((item) => getOptionValue(item as TOption, getValue) === convertedValue);
          }
          if (allOption && isAllOptionSelected) {
            convertedValue = [ALL_VALUE];
          }

          const needClearButton = clearButton && !disabled && convertedValue !== PLACEHOLDER_VALUE;
          return (
            <Select
              {...restProps}
              // required={required}
              id={id}
              disabled={disabled}
              displayEmpty
              name={name}
              value={convertedValue}
              ref={ref}
              multiple={multiple}
              sx={{
                '& .MuiSelect-select': {
                  padding: '6px 8px !important',
                },
                ...restProps.sx,
              }}
              endAdornment={
                needClearButton ? (
                  <InputAdornment position="end" sx={{ marginRight: 3 }} className="clear-btn">
                    <IconButton size="small" onClick={() => onChange(multiple ? [] : PLACEHOLDER_VALUE)}>
                      <Close sx={{ height: 15 }} />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              onChange={(event) => {
                const currentValue = event.target.value;
                let finalValue;
                if (currentValue === ALL_VALUE || (isArray(currentValue) && currentValue.includes(ALL_VALUE))) {
                  finalValue = allOptionValues;
                } else {
                  finalValue = isArray(currentValue)
                    ? map(currentValue, (itemValue) => optionMap[itemValue])
                    : optionMap[currentValue];
                }
                onChange(finalValue);
                onInputChange && onInputChange(finalValue);
              }}
              onBlur={() => {
                onBlur();
                onInputBlur && onInputBlur(convertedValue);
              }}
              renderValue={(selected) => {
                // Render Placeholder:
                const shouldRenderPlaceholder =
                  placeholder && (!selected || (isArray(selected) && selected.length === 0));
                if (shouldRenderPlaceholder) {
                  return (
                    <Typography
                      sx={{
                        color: '#AEB3B5',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        letterSpacing: '0.15px',
                        lineHeight: 'unset',
                      }}
                    >
                      {rawPlaceholder ? placeholder : intl.formatMessage({ id: placeholder })}
                    </Typography>
                  );
                }
                // Render All:
                const shouldRenderAllOptionLabel = allOption && (selected === ALL_VALUE || isAllOptionSelected);
                if (shouldRenderAllOptionLabel) {
                  return intl.formatMessage({ id: 'all' });
                }

                if (renderValue) {
                  return renderValue(
                    isArray(selected)
                      ? selected?.map((valueSelected) => optionMap[valueSelected] as TOption)
                      : (optionMap[selected] as TOption),
                  );
                }

                if (!selected) {
                  return '';
                }

                return isArray(selected)
                  ? selected?.map((valueSelected) => fomratedOptionMap[valueSelected]?.label)?.join(', ')
                  : fomratedOptionMap[selected]?.label;
              }}
              error={(showError && !value) || !!fieldState.error}
            >
              {placeholder && <MenuItem sx={{ display: 'none' }} value={PLACEHOLDER_VALUE} />}
              {allOption && (
                <MenuItem value={ALL_VALUE} sx={{ backgroundColor: isAllOptionSelected ? '#BFD4F7' : 'unset' }}>
                  <FormattedMessage id="all" />
                </MenuItem>
              )}
              {optionFormateds.map((option, index) => {
                if (option.isGroup) {
                  return (
                    <ListSubheader
                      key={`group-${index}`}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '17px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '24px',
                        paddingY: '5px',
                        letterSpacing: '0.15px',
                        marginBottom: '4px',
                      }}
                    >
                      {option.label}
                    </ListSubheader>
                  );
                }
                const optionValue = option.value;

                return (
                  <MenuItem
                    key={optionValue}
                    value={optionValue}
                    disabled={option.disable}
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                      paddingY: '5px',
                      paddingX: '25px',
                    }}
                  >
                    {showCheckBox && multiple === true ? (
                      <Stack direction={'row'} flex={1} justifyContent={'start'} alignItems={'center'}>
                        <Checkbox checked={Object.values(convertedValue)?.indexOf(option.value) !== -1} />
                        <Typography fontSize="16px">{option.label}</Typography>
                      </Stack>
                    ) : (
                      <Stack direction={'row'} flex={1} justifyContent={'start'} alignItems={'center'}>
                        {iconCheck && Object.values(convertedValue)?.indexOf(option.value) !== -1 && (
                          <CheckIcon color="success" />
                        )}
                        <Typography fontSize="16px">{option.label}</Typography>
                      </Stack>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default SelectInput;
