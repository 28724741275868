import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useFetch } from 'modules/common/hook';
import DrugDataForm from './DrugDataForm';
import useDrugDatabaseContext from '../const';
import { MedicationCategory } from 'modules/schema';

interface Props {
  dataMedicationCategory?: MedicationCategory[];
}

const DrugDataFormDialog: React.FunctionComponent<Props> = ({ dataMedicationCategory }) => {
  const drugDatabaseContext = useDrugDatabaseContext();
  const { API_SERVER } = useGeneralHook();

  const { data: formData } = useFetch(API_SERVER.medication.getDetail(drugDatabaseContext?.listId?.medicationId!), {
    dedupingInterval: 0,
    enabled: drugDatabaseContext?.listId?.medicationId,
  });

  return (
    <DialogCustom
      open={!!formData || !!drugDatabaseContext?.openMedicationForm}
      maxWidth="xs"
      keepMounted={false}
      onClose={() => {
        drugDatabaseContext?.setListId({ medicationId: null, medicationCategoryId: null });
        drugDatabaseContext?.setOpenMedicationForm(false);
      }}
      PaperProps={{
        style: { minWidth: 1000 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'pharmacy.edit' : 'addMedicine'} />
        </Typography>
      }
    >
      <Box padding={2}>
        {dataMedicationCategory && (
          <DrugDataForm
            onClose={() => {
              drugDatabaseContext?.setListId({ medicationId: null, medicationCategoryId: null });
              drugDatabaseContext?.setOpenMedicationForm(false);
            }}
            formData={formData}
            dataMedicationCategory={dataMedicationCategory}
          />
        )}
      </Box>
    </DialogCustom>
  );
};
export default DrugDataFormDialog;
