import { useCallback } from 'react';
import useGeneralHook from './useGeneralHook';
import { axiosThunk } from '../redux/axios';

const useImportFile = (onSuccess?: (res) => void) => {
  const { openSnackbar, intl, dispatch } = useGeneralHook();
  return useCallback(
    async ({ file, url }: { file: File; url: string }) => {
      await dispatch(
        axiosThunk({
          url: url,
          method: 'post',
          responseType: 'blob',
          headers: {
            'Content-type': 'multipart/form-data',
          },
          data: { data: file },
        }),
      )
        .then((res) => {
          if (onSuccess) onSuccess(res);
        })
        .catch(() => {
          openSnackbar({ message: intl.formatMessage({ id: 'report.uploadFail' }), type: 'error' });
        });
    },
    [dispatch, intl, onSuccess, openSnackbar],
  );
};
export default useImportFile;
