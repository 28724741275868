import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Button, Collapse, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { useDeleteMutate, useFetch } from 'modules/common/hook';
import { Add } from '@mui/icons-material';
import { INVENTORY_STATUS, MEDICATION_UNIT } from 'modules/common/apiConstants';
import { Medication, MedicationCategory } from 'modules/schema';
import useDrugDatabaseContext from './const';
import { BOX_SUMMARY_KEY } from './const';

interface Props {
  data: MedicationCategory;
}

const DrugTable: React.FunctionComponent<Props> = (props) => {
  const { data } = props;
  const { confirmDialog, locationId, intl, API_SERVER } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const { params } = usePaginationHook();
  const [open, setOpen] = useState(false);
  const drugDatabaseContext = useDrugDatabaseContext();
  const isSummmaryDeleted = drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED;
  const [selection, setSelection] = useState<Medication[]>([]);

  const {
    data: listData,
    revalidate,
    isValidating,
  } = useFetch(API_SERVER.medication.index({ ...drugDatabaseContext.searchParams, categoryIds: [data.id!] }), {
    enabled: open && data.id && drugDatabaseContext.selectedViewMode !== 'TABLE',
  });

  const deleteMutate = useDeleteMutate({
    onSuccess: () => {
      revalidate();
      drugDatabaseContext.revalidateCategoryGroup();
      drugDatabaseContext.revalidateDashboard();
    },
  });

  const onDelete = useCallback(
    async (value) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.code }),
      });
      if (confirm) {
        await deleteMutate({
          url: API_SERVER.medication.update(value.id, { categoryId: data?.id! }),
          method: 'delete',
          data: value,
        });
      }
      close();
    },
    [API_SERVER.medication, close, data?.id, deleteMutate, intl, promptConfirmation],
  );

  // tạm ẩn restore
  // const restoreMutate = useUpdateMutate({
  //   onSuccess: () => {
  //     revalidate();
  //     revalidateCategory();
  //   },
  // });

  // const onRestore = useCallback(
  //   async (value) => {
  //     const confirm = await promptConfirmation({
  //       warning: true,
  //       title: intl.formatMessage({ id: 'confirmRestoreTitle' }),
  //       content: intl.formatMessage({ id: 'confirmRestore' }, { name: value.code }),
  //       okId: intl.formatMessage({ id: 'restore' }),
  //     });
  //     if (confirm) {
  //       await restoreMutate({
  //         url: API_SERVER.medication.update(value.id, { categoryId: data?.id! }),
  //         method: 'delete',
  //         data: value,
  //       });
  //     }
  //     close();
  //   },
  //   [API_SERVER.medication, close, data?.id, intl, promptConfirmation, restoreMutate],
  // );

  const deleteMultipleMutate = useDeleteMutate({
    onSuccess: () => {
      revalidate();
      drugDatabaseContext.revalidateCategoryGroup();
      drugDatabaseContext.revalidateDashboard();
      setSelection([]);
    },
  });

  const onDeleteMultiple = useCallback(
    async (data) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: `${selection.length} item` }),
      });
      if (confirm && selection?.length > 0) {
        deleteMultipleMutate({
          url: API_SERVER.medication.index(locationId).url,
          method: 'delete',
          data: { ids: selection.map((v: Medication) => v.id), categoryId: data?.id },
        });
      }
      close();
    },
    [promptConfirmation, intl, selection, close, deleteMultipleMutate, API_SERVER.medication, locationId],
  );

  const deleteGroupMutate = useDeleteMutate({
    onSuccess: () => {
      drugDatabaseContext.revalidateCategoryGroup();
    },
  });

  const onDeleteGroup = useCallback(
    async (value) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        await deleteGroupMutate({
          url: API_SERVER.medicationCategory.update(value.id),
          method: 'delete',
        });
      }
      close();
    },
    [API_SERVER.medicationCategory, close, deleteGroupMutate, intl, promptConfirmation],
  );

  // tạm ẩn restore mở lại sau
  // const restoreGroupMutate = useUpdateMutate({
  //   onSuccess: () => {
  //     revalidate();
  //   },
  // });

  // const onRestoreGroup = useCallback(
  //   async (value) => {
  //     const confirm = await promptConfirmation({
  //       warning: true,
  //       title: intl.formatMessage({ id: 'confirmRestoreTitle' }),
  //       content: intl.formatMessage({ id: 'confirmRestore' }, { name: value.name }),
  //       okId: intl.formatMessage({ id: 'restore' }),
  //     });
  //     if (confirm) {
  //       await restoreGroupMutate({
  //         url: API_SERVER.medicationCategory.update(value.id),
  //         method: 'delete',
  //       });
  //     }
  //     close();
  //   },
  //   [API_SERVER.medicationCategory, close, intl, promptConfirmation, restoreGroupMutate],
  // );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'pharmacy.code',
        dataIndex: 'code',
      },
      {
        title: 'pharmacy.drugName',
        dataIndex: 'name',
      },
      {
        title: 'drugDispenseBook.ingredients',
        render: (record) => (
          <Typography>
            {record.ingredient}
            {record.dose && record.ingredient && ', '}
            {record.dose}
          </Typography>
        ),
      },
      {
        title: 'pharmacy.numeratorUnit',
        render: (record) => record?.basicUnit && <FormattedMessage id={MEDICATION_UNIT[record.basicUnit]?.label} />,
      },
      {
        title: 'drugUsage.intendedRoute',
        dataIndex: 'intendedRoute.label',
        render: (record) => (
          <Box component="ul" margin={0} paddingLeft={2}>
            {record.intendedRoute &&
              record.intendedRoute
                //.filter((one) => one.value)
                .map((route) => (
                  <Typography component={'li'}>{` ${route.label || ''} - ${route.value || ''}`}</Typography>
                ))}
          </Box>
        ),
      },
      {
        title: 'dental.label.conditionDiagnosis.updateDay',
        render: (record) => record.updatedTime?.format(FE_DATE_FORMAT),
      },
      {
        title: 'status',
        render: (record) =>
          record.inventoryStatus && <FormattedMessage id={INVENTORY_STATUS[record.inventoryStatus].label} />,
      },
      {
        title: 'action',
        align: 'right',
        width: 100,
        render: (record) => {
          return (
            <>
              {isSummmaryDeleted ? (
                <></>
              ) : (
                // tạm ẩn chức năng restore
                // <IconButtonTitle
                //   title="restore"
                //   size="small"
                //   onClick={() => {
                //     onRestore({
                //       ...record,
                //       listId: data?.id,
                //     });
                //     setSelection([]);
                //   }}
                // >
                //   <History />
                // </IconButtonTitle>
                <>
                  <IconButtonTitle
                    title="update"
                    size="small"
                    onClick={() => {
                      drugDatabaseContext?.setListId({ medicationId: record?.id, medicationCategoryId: data?.id! });
                    }}
                    style={{ margin: '0px 8px' }}
                  >
                    <EditIcon color={record.validation === false ? 'error' : undefined} />
                  </IconButtonTitle>
                  <IconButtonTitle
                    title="delete"
                    size="small"
                    onClick={() => {
                      onDelete({
                        ...record,
                        listId: data?.id,
                        // listData: data?.items,
                        // allItems: data?.allItems,
                      });
                      setSelection([]);
                    }}
                  >
                    <DeleteIcon />
                  </IconButtonTitle>
                </>
              )}
            </>
          );
        },
      },
    ] as Columns[];
  }, [isSummmaryDeleted, drugDatabaseContext, data?.id, onDelete]);

  useEffect(() => {
    setSelection(drugDatabaseContext.selectionMedication);
  }, [drugDatabaseContext.selectionMedication]);

  if (!data || (data?.total === 0 && !!params.name)) {
    return null;
  }

  return (
    <>
      <MediCard
        sx={{
          margin: '8px 0px',
          color: 'white',
          '>div': {
            backgroundColor: '#E0ECFF!important',
            color: '#0052E0!important',
          },
        }}
        title={
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              setOpen((old) => !old);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Box flex={1} display="flex" alignItems="center">
              <ExpandLessIcon
                style={{
                  transition: '0.2s all',
                  transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                  marginRight: 16,
                }}
              />
              {drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED ? (
                <Typography variant="subtitle1" color="inherit">
                  {data.name}&nbsp;({drugDatabaseContext?.dataDashboard?.deleted || 0})
                </Typography>
              ) : (
                <Typography variant="subtitle1" color="inherit">
                  {data.name}&nbsp;({data?.total || 0})
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              onClick={(e) => {
                e.stopPropagation();
              }}
              color="#607D8B"
            >
              {isSummmaryDeleted ? (
                <></>
              ) : (
                // <IconButtonTitle
                //   title="restore"
                //   size="small"
                //   color="inherit"
                //   disabled={data?.total! > 0 ? !selection.length : !!data?.total && !isPreview}
                //   onClick={(e) => {
                //     e.stopPropagation();
                //     onRestoreGroup(data);
                //   }}
                // >
                //   <History />
                // </IconButtonTitle>
                <>
                  <IconButtonTitle
                    title="create"
                    size="small"
                    color="inherit"
                    onClick={() => {
                      drugDatabaseContext?.setListId({
                        medicationId: null,
                        medicationCategoryId: data.id!,
                      });
                      drugDatabaseContext?.setOpenMedicationForm(true);
                    }}
                  >
                    <Add />
                  </IconButtonTitle>
                  <IconButtonTitle
                    title="update"
                    size="small"
                    color="inherit"
                    onClick={() => {
                      drugDatabaseContext?.setFormDataGroup(data);
                    }}
                    style={{ margin: '0px 8px' }}
                  >
                    <EditIcon />
                  </IconButtonTitle>
                  <IconButtonTitle
                    title="delete"
                    size="small"
                    color="inherit"
                    disabled={data?.total! > 0 ? !selection.length : !!data?.total}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (data?.total! > 0) {
                        onDeleteMultiple(data);
                      } else {
                        onDeleteGroup(data);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButtonTitle>
                </>
              )}
            </Box>
          </Box>
        }
      >
        <Collapse in={open} unmountOnExit>
          <Box padding={1}>
            <TableCustom
              dataSource={listData}
              loading={isValidating}
              columns={columns}
              rowSelection={{
                selectedRowKeys: selection,
                onChange: ({ selectedRows }) => {
                  setSelection(selectedRows);
                  drugDatabaseContext.setSelectionMedication(selectedRows);
                },
              }}
              caption={
                <Button
                  size="large"
                  fullWidth
                  variant="text"
                  startIcon={<Add />}
                  color="primary"
                  onClick={() =>
                    drugDatabaseContext?.setListId({
                      medicationId: null,
                      medicationCategoryId: data.id!,
                    })
                  }
                >
                  <Typography variant="subtitle2">
                    <FormattedMessage id="pharmacy.addNew" />
                  </Typography>
                </Button>
              }
            />
          </Box>
        </Collapse>
      </MediCard>
    </>
  );
};

export default DrugTable;
