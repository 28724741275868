import { Box, Button, Stack } from '@mui/material';
import TextInput from 'modules/common/component/form/TextInput';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { MedicationCategoryForm } from 'modules/schema';
import { API_SERVER } from 'modules/common/api';
import { useCreateMutate, useUpdateMutate } from 'modules/common/hook';
import { FormattedMessage } from 'react-intl';
import useDrugDatabaseContext from '../const';

interface GroupDrugDataFormProps {
  onClose: () => void;
}

const GroupDrugDataForm = ({ onClose }: GroupDrugDataFormProps) => {
  const { locationId } = useGeneralHook();
  const drugDatabaseContext = useDrugDatabaseContext();
  const formData = drugDatabaseContext?.formDataGroup;

  const defaultValue = useMemo(() => {
    return {
      name: '',
    };
  }, []);

  const form = useForm<MedicationCategoryForm>({
    defaultValues: defaultValue,
  });

  const createGroupDrugMutate = useCreateMutate({
    onSuccess: () => {
      drugDatabaseContext.revalidateCategoryGroup();
      drugDatabaseContext.revalidateMedicationCategory();
      onClose();
      form.reset(defaultValue);
    },
  });

  const updateGroupDrugMutate = useUpdateMutate({
    onSuccess: () => {
      drugDatabaseContext.revalidateCategoryGroup();
      drugDatabaseContext.revalidateMedicationCategory();
      onClose();
      form.reset(defaultValue);
    },
  });

  const onSubmit = useCallback(
    async (data: MedicationCategoryForm) => {
      if (formData?.id) {
        await updateGroupDrugMutate({
          url: API_SERVER.medicationCategory.update(formData.id),
          method: 'put',
          data: {
            name: data.name,
          },
        });
      } else {
        await createGroupDrugMutate({
          url: API_SERVER.medicationCategory?.getCategoryByClinicId(locationId).url,
          method: 'post',
          data: {
            name: data.name,
          },
        });
      }
    },
    [createGroupDrugMutate, formData, locationId, updateGroupDrugMutate],
  );

  useEffect(() => {
    if (formData) {
      form.reset({
        name: formData.name,
      });
    } else {
      form.reset({});
    }
  }, [form, formData]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box sx={{ flexGrow: 1 }}>
        <TextInput name="name" form={form} label="pharmacy.groupName" placeholder="pharmacy.groupNameEnter" required />
        <Box display="flex" justifyContent="flex-end">
          <Stack spacing={2} direction="row" alignItems="center">
            <Button variant="outlined" onClick={onClose}>
              <FormattedMessage id="cancelled" />
            </Button>
            <Button variant="contained" type="submit">
              <FormattedMessage id="save" />
            </Button>
          </Stack>
        </Box>
      </Box>
    </form>
  );
};
export default GroupDrugDataForm;
