import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useDeleteMutate } from 'modules/common/hook';
import { INVENTORY_STATUS, MEDICATION_UNIT } from 'modules/common/apiConstants';
import { Medication } from 'modules/schema';
import useDrugDatabaseContext, { BOX_SUMMARY_KEY } from './const';
import { API_SERVER } from 'modules/common/api';
import { Page } from 'modules/schema/common';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import usePaginationHook from 'modules/common/hook/usePaginationHook';

interface Props {
  dataMedicationSearch?: Page<Medication>;
}

const DrugTableView: React.FunctionComponent<Props> = ({ dataMedicationSearch }) => {
  const { confirmDialog, intl } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const drugDatabaseContext = useDrugDatabaseContext();
  const isSummmaryDeleted = drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED;
  const hookPagination = usePaginationHook();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  useEffect(() => {
    drugDatabaseContext.setTotalResult(dataMedicationSearch?.pagination.totalElements || 0);
  }, [drugDatabaseContext, dataMedicationSearch]);

  const deleteMutate = useDeleteMutate({
    onSuccess: () => {
      drugDatabaseContext.revalidateMedicationSearch();
      drugDatabaseContext.revalidateDashboard();
    },
  });

  const onDelete = React.useCallback(
    async (value) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.code }),
      });
      if (confirm) {
        await deleteMutate({
          url: API_SERVER.medication.update(value.id, { categoryId: value.category.id }),
          method: 'delete',
          data: value,
        });
      }
      close();
    },
    [close, intl, promptConfirmation, deleteMutate],
  );

  // tạm ẩn restore
  // const restoreMutate = useUpdateMutate({
  //   onSuccess: () => {
  //     revalidate();
  //     revalidateCategory();
  //   },
  // });

  // const onRestore = React.useCallback(
  //   async (value) => {
  //     const confirm = await promptConfirmation({
  //       warning: true,
  //       title: intl.formatMessage({ id: 'confirmRestoreTitle' }),
  //       content: intl.formatMessage({ id: 'confirmRestore' }, { name: value.code }),
  //       okId: intl.formatMessage({ id: 'restore' }),
  //     });
  //     if (confirm) {
  //       await restoreMutate({
  //         url: API_SERVER.medication.update(value.id, { categoryId: data?.id! }),
  //         method: 'delete',
  //         data: value,
  //       });
  //     }
  //     close();
  //   },
  //   [close, data?.id, intl, promptConfirmation, restoreMutate],
  // );

  const COLUMNS: Columns<Medication>[] = [
    {
      title: 'drugUsage.groupDrug',
      render: (record) => record.category?.name || '',
    },
    {
      title: 'pharmacy.code',
      dataIndex: 'code',
    },
    {
      title: 'pharmacy.drugName',
      dataIndex: 'name',
    },
    {
      title: 'drugDispenseBook.ingredients',
      render: (record) => (
        <Typography fontSize={14}>
          {record.ingredient}
          {record.dose && record.ingredient && ', '}
          {record.dose}
        </Typography>
      ),
    },
    {
      title: 'pharmacy.numeratorUnit',
      render: (record) => (record?.basicUnit ? <FormattedMessage id={MEDICATION_UNIT[record.basicUnit]?.label} /> : ''),
    },
    {
      title: 'drugUsage.intendedRoute',
      render: (record) => (
        <Box component="ul" margin={0} paddingLeft={2}>
          {record.intendedRoute &&
            record.intendedRoute.map((route) => (
              <Typography fontSize={14} component={'li'}>{` ${route.label || ''} - ${route.value || ''}`}</Typography>
            ))}
        </Box>
      ),
    },
    {
      title: 'dental.label.conditionDiagnosis.updateDay',
      render: (record) => record.updatedTime?.format(FE_DATE_FORMAT) || '',
    },
    {
      title: 'status',
      render: (record) => {
        return (
          <Typography color={INVENTORY_STATUS[record.inventoryStatus!]?.color}>
            {record.inventoryStatus && <FormattedMessage id={INVENTORY_STATUS[record.inventoryStatus].label} />}
          </Typography>
        );
      },
    },
    {
      title: 'action',
      align: 'right',
      render: (record) => {
        return (
          <>
            {isSummmaryDeleted ? (
              <></>
            ) : (
              // <IconButtonTitle
              //   title="restore"
              //   size="small"
              //   onClick={() => {
              //     onRestore({
              //       ...record,
              //       listId: data?.id,
              //     });
              //   }}
              // >
              //   <History />
              // </IconButtonTitle>
              <>
                <IconButtonTitle
                  title="update"
                  size="small"
                  onClick={() => {
                    drugDatabaseContext?.setListId({
                      medicationId: record?.id!,
                      medicationCategoryId: record?.category?.id!,
                    });
                  }}
                  style={{ margin: '0px 8px' }}
                >
                  <EditIcon />
                </IconButtonTitle>
                <IconButtonTitle
                  title="delete"
                  size="small"
                  onClick={() => {
                    onDelete({
                      ...record,
                      listId: record?.category?.id,
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButtonTitle>
              </>
            )}
          </>
        );
      },
    },
  ];

  if (drugDatabaseContext.isValidatingMedicationSearch) {
    return <LinearProgress />;
  }

  return (
    <Box padding={1}>
      <TableCustom
        dataSource={dataMedicationSearch?.content}
        loading={drugDatabaseContext.isValidatingMedicationSearch}
        columns={COLUMNS}
        paginationProps={{
          count: dataMedicationSearch?.pagination.totalElements || 0,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowSelection={{
          selectedRowKeys: drugDatabaseContext.selectionMedication,
          onChange: ({ selectedRows }) => {
            drugDatabaseContext.setSelectionMedication(selectedRows);
          },
        }}
      />
    </Box>
  );
};

export default DrugTableView;
