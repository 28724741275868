import React, { useCallback, useEffect } from 'react';
import { Box, Button, ButtonGroup, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SearchIcon from '@mui/icons-material/Search';
import { DrugGroup, DrugTable } from 'svg';
import { PRIMARY } from 'colors';
import SelectInput from 'modules/common/component/form/SelectInput';
import { useForm } from 'react-hook-form';
import { Info, SaveAlt } from '@mui/icons-material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import IconButton from 'modules/common/component/IconButton';
import GroupCollapseButton from 'modules/common/component/GroupCollapseButton';
import DateInput from 'modules/common/component/form/DateInput';
import { INVENTORY_STATUS, MEDICATION_STATUS } from 'modules/common/apiConstants';
import { useCreateMutate, useDialog, useFetch } from 'modules/common/hook';
import { API_SERVER, mappedBody } from 'modules/common/api';
import { MedicationCategory } from 'modules/schema';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import TextInput from 'modules/common/component/form/TextInput';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import moment from 'moment';
import useDrugDatabaseContext, { BOX_SUMMARY_KEY } from './const';
import { formatEndDate, formatStartDate } from 'modules/common/utils';
import { Option } from 'modules/common/type';
import FormDataDialog from '../importInventory/FormDataDialog';
import ImportDrugFormDialog from './ImportDrugFormDialog';
import useImportFile from '../../../common/hook/useImportFile';
import { setLoading } from '../../../common/redux/commonReducer';
import fileDownload from 'js-file-download';
import { map } from 'lodash';
import ImportUnitExchangeFormDialog from './ImportUnitExchangeFormDialog';

interface Props {
  selectedViewMode: 'TABLE' | 'GROUP';
  setSelectedViewMode: (value) => void;
  setSearchParams: (value) => void;
  clearParams: () => void;
}

interface FormSchema {
  productName?: string;
  category?: Omit<MedicationCategory, 'medicationList'>[];
  inventoryStatus?: Option;
  fromDate?: string;
  toDate?: string;
}

const Header: React.FunctionComponent<Props> = ({
  selectedViewMode,
  setSelectedViewMode,
  setSearchParams,
  clearParams,
}) => {
  const { intl, confirmDialog, dispatch } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [openImport, onOpenImport, onCloseImport] = useDialog();
  const [openImportFile, onOpenImportFile, onCloseImportFile] = useDialog();
  const [openUnitExchange, onOpenUnitExchange, onCloseUnitExchange] = useDialog();
  const drugDatabaseContext = useDrugDatabaseContext();
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const { data: categoryData } = useFetch(API_SERVER.medicationCategory.getCategoryByClinicId());
  const { data: medicationImportData } = useFetch(API_SERVER.medication.import(), {
    enabled: openImport,
  });
  const { data: supplierData } = useFetch(API_SERVER.supplier.get({ pageSize: 100, page: 0 }), {
    enabled: openImport,
  });
  const form = useForm<FormSchema>({
    defaultValues: {
      category: [],
      productName: '',
    },
    mode: 'all',
  });

  const boxSummary = useCallback(() => {
    return [
      {
        title: 'inventory.box.summary.1',
        tooltip: 'inventory.box.summary.1.tooltip',
        key: BOX_SUMMARY_KEY.TOTAL,
        value: drugDatabaseContext.dataDashboard?.total,
        color: '#003CA6',
        bgColor: '#f0f6ff',
      },
      {
        title: 'inventory.box.summary.2',
        tooltip: 'inventory.box.summary.2.tooltip',
        key: BOX_SUMMARY_KEY.ADD_ED_RECENTLY,
        value: drugDatabaseContext.dataDashboard?.addedRecently,
        color: '#307BFF',
        bgColor: '#f0f6ff',
      },
      {
        title: 'inventory.box.summary.3',
        tooltip: 'inventory.box.summary.3.tooltip',
        key: BOX_SUMMARY_KEY.NOT_IN_STOCK,
        value: drugDatabaseContext.dataDashboard?.notInStock,
        color: '#FFA000',
        bgColor: '#fff7e6',
      },
      {
        title: 'inventory.box.summary.4',
        tooltip: 'inventory.box.summary.4.tooltip',
        key: BOX_SUMMARY_KEY.IN_STOCK,
        value: drugDatabaseContext.dataDashboard?.inStock,
        color: '#43A047',
        bgColor: '#e6f7e6',
      },
      {
        title: 'inventory.box.summary.5',
        key: BOX_SUMMARY_KEY.DELETED,
        value: drugDatabaseContext.dataDashboard?.deleted,
        color: '#90A4AE',
        bgColor: '#f8f9fc',
      },
    ];
  }, [drugDatabaseContext.dataDashboard]);

  const fromDate = form.watch('fromDate');
  const toDate = form.watch('toDate');
  const category = form.watch('category');
  const inventoryStatus = form.watch('inventoryStatus');

  useEffect(() => {
    setSearchParams((prev) => ({
      ...prev,
      fromDate: formatStartDate(fromDate),
      toDate: formatEndDate(toDate),
      inventoryStatus: inventoryStatus?.value,
      categoryIds: map(category, 'id'),
    }));
  }, [toDate, fromDate, category, setSearchParams, inventoryStatus, form]);

  useEffect(() => {
    form.reset();
    if (drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.NOT_IN_STOCK)
      form.setValue('inventoryStatus', INVENTORY_STATUS.NOT_STOCK);
    if (drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.IN_STOCK)
      form.setValue('inventoryStatus', INVENTORY_STATUS.STOCKED);
    if (drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.ADD_ED_RECENTLY) {
      form.setValue('toDate', moment().format(BE_DATE_TIME_FORMAT));
      form.setValue('fromDate', moment().subtract(7, 'days').format(BE_DATE_TIME_FORMAT));
    }
    if (
      drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED ||
      drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.TOTAL
    ) {
      setSearchParams((prev) => ({
        ...prev,
        medicationStatus:
          drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.TOTAL
            ? MEDICATION_STATUS.ACTIVE
            : MEDICATION_STATUS.INACTIVE,
        inventoryStatus: undefined,
      }));
    }
    if (
      drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.IN_STOCK ||
      drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.NOT_IN_STOCK
    ) {
      setSearchParams((prev) => ({
        ...prev,
        medicationStatus: undefined,
      }));
    }
  }, [form, drugDatabaseContext.summaryBox, setSearchParams]);

  const createImportMutate = useCreateMutate({
    successMessage: 'inventory.import.success',
    onSuccess: () => {
      onCloseImport();
      drugDatabaseContext.setSelectionMedication([]);
    },
  });
  const createImport = useCallback(
    async (value) => {
      await createImportMutate({
        url: API_SERVER.importInventory?.index(),
        method: 'post',
        data: mappedBody({
          ...value,
          importDate: value?.importDate?.format(BE_DATE_FORMAT),
          inventoryImportDetails: value.inventoryImportDetails.map((v) => {
            return {
              ...v,
              expiredDate: moment(v.expiredDate).format(BE_DATE_FORMAT),
              medicationId: v.medication?.id,
              supplierId: v.medicationSupplier?.id,
              lot: v?.lot?.lot || v?.lot,
            };
          }),
        }),
      });
    },
    [createImportMutate],
  );
  const updateFileDrugUse = useImportFile((res) => {
    dispatch(setLoading(false));
    if (res.data.size === 0) {
      setShowSuccessMessage(true);
      return;
    } else {
      const effectiveFileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0];
      setShowErrorMessage(true);
      fileDownload(res.data, effectiveFileName);
    }
  });

  const [showSuccessMessageUnitChange, setShowSuccessMessageUnitChange] = React.useState<boolean>(false);
  const [showErrorMessageUnitChange, setShowErrorMessageUnitChange] = React.useState<boolean>(false);
  const updateFileUnitExchange = useImportFile((res) => {
    dispatch(setLoading(false));
    if (res.data.size === 0) {
      setShowSuccessMessageUnitChange(true);
      return;
    } else {
      const effectiveFileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0];
      setShowErrorMessageUnitChange(true);
      fileDownload(res.data, effectiveFileName);
    }
  });

  return (
    <Box padding="10px">
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={1}>
          <Box>
            <Typography
              sx={{
                color: '#263238',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.18px',
              }}
            >
              <FormattedMessage id={'navMenu.drugDatabase'} />
            </Typography>
            <FormattedMessage id={'found'} />
            <b style={{ color: '#003CA6' }}>
              {' '}
              {drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED
                ? drugDatabaseContext.dataDashboard.deleted
                : drugDatabaseContext.totalResult}{' '}
            </b>
            <FormattedMessage id={'resultLast'} />
          </Box>
          <Box>
            <Stack direction="row" spacing={1} alignItems="end" justifyContent="end">
              <TextInput
                form={form}
                name="productName"
                placeholder={intl.formatMessage({ id: 'search' })}
                rawLabel
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter') {
                    const inputValue = (event.target as HTMLInputElement).value;
                    setSearchParams(() => ({ productName: inputValue }));
                    form.resetField('category');
                    form.resetField('fromDate');
                    form.resetField('inventoryStatus');
                    form.resetField('toDate');
                    drugDatabaseContext.setSummaryBox(BOX_SUMMARY_KEY.TOTAL);
                  }
                }}
                onBlur={(event) => {
                  setSearchParams(() => ({ productName: event.target.value }));
                  form.resetField('category');
                  form.resetField('fromDate');
                  form.resetField('inventoryStatus');
                  form.resetField('toDate');
                  drugDatabaseContext.setSummaryBox(BOX_SUMMARY_KEY.TOTAL);
                }}
                sx={{ width: '300px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                hideError
              />
              <ButtonGroup sx={{ justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    setSelectedViewMode('GROUP');
                  }}
                  sx={{
                    padding: '7px',
                    borderRadius: '5px 0px 0px 5px',
                    '& path': { fill: selectedViewMode === 'GROUP' ? 'white' : PRIMARY.main },
                  }}
                  variant={selectedViewMode === 'GROUP' ? 'contained' : 'outlined'}
                >
                  <DrugGroup />
                </Button>
                <Button
                  onClick={() => {
                    setSelectedViewMode('TABLE');
                  }}
                  sx={{
                    padding: '7px',
                    borderRadius: '0px 5px 5px 0px',
                    '& path': { fill: selectedViewMode === 'TABLE' ? 'white' : PRIMARY.main },
                  }}
                  variant={selectedViewMode === 'TABLE' ? 'contained' : 'outlined'}
                >
                  <DrugTable />
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: 2,
          backgroundColor: '#ffffff',
          borderRadius: 2,
        }}
      >
        {boxSummary().map((box) => (
          <Box
            key={box.value}
            onClick={() => {
              drugDatabaseContext.setSummaryBox(box.key);
              clearParams();
            }}
            sx={{
              textAlign: 'center',
              flex: 1,
              padding: '10px',
              borderRadius: '8px',
              backgroundColor: '#f0f6ff',
              border: drugDatabaseContext.summaryBox === box.key ? '2px solid #1976d2' : '2px solid transparent',
              cursor: 'pointer',
              transition: 'border 0.3s ease',
              boxShadow: '0px 2px 2px rgba(0,0,0,0.1)',
            }}
          >
            <Typography sx={{ color: '#6c757d', fontSize: '14px', fontWeight: 'bold', paddingBottom: '10px' }}>
              {intl.formatMessage({ id: box.title })}
              {box.key !== BOX_SUMMARY_KEY.DELETED && (
                <Tooltip
                  title={intl.formatMessage({ id: box.tooltip })}
                  sx={{ margin: '0 0 -5px 5px', fontSize: '20px!important' }}
                >
                  <Info />
                </Tooltip>
              )}
            </Typography>
            <Typography sx={{ color: box.color, fontWeight: 'bold', fontSize: '24px' }}>{box.value}</Typography>
          </Box>
        ))}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={2}>
        <Stack direction="column" gap="10px">
          <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
            <Box display="flex" gap={2} mb={2}>
              <Box width="200px">
                <AutocompleteInput
                  form={form}
                  name="category"
                  options={categoryData}
                  getValue="id"
                  getLabel="name"
                  label="drugUsage.groupDrug"
                  placeholder="all"
                  hideError
                  disabled={drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED}
                  multiple
                />
              </Box>
              <Box width="200px">
                <SelectInput
                  form={form}
                  name="inventoryStatus"
                  options={Object.values(INVENTORY_STATUS)}
                  getValue="value"
                  renderLabel="label"
                  label="status"
                  placeholder="all"
                  hideError
                />
              </Box>
              <Box width="200px">
                <DateInput
                  name="fromDate"
                  form={form}
                  label="fromDate"
                  format={FE_DATE_FORMAT}
                  maxDate={moment()}
                  toUTC={false}
                />
              </Box>
              <Box width="200px">
                <DateInput
                  name="toDate"
                  form={form}
                  label="toDate"
                  format={FE_DATE_FORMAT}
                  maxDate={moment()}
                  toUTC={false}
                />
              </Box>
              <Box paddingTop={3}>
                <IconButtonTitle>
                  <AutorenewIcon
                    onClick={() => {
                      form.reset();
                      drugDatabaseContext.setSummaryBox(BOX_SUMMARY_KEY.TOTAL);
                      setSearchParams({});
                    }}
                  />
                </IconButtonTitle>
              </Box>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
          <Box>
            <Button startIcon={<SaveAlt />} color="inherit" onClick={onOpenImport}>
              <FormattedMessage id="inventory.button.process.import" />
            </Button>
          </Box>
          <Box>
            <IconButton icon={<SyncAltIcon onClick={onOpenUnitExchange} sx={{ color: '#307BFF' }} />} />
          </Box>
          <GroupCollapseButton title={intl.formatMessage({ id: 'add' })} variant="primary">
            <Box width="200px">
              <Button
                variant="text"
                onClick={() => drugDatabaseContext?.setFormDataGroup({})}
                fullWidth
                sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
              >
                {intl.formatMessage({ id: 'inventory.add.group' })}
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
                onClick={() => drugDatabaseContext?.setOpenMedicationForm(true)}
              >
                {intl.formatMessage({ id: 'inventory.add.one' })}
              </Button>
              <Button
                variant="text"
                onClick={onOpenImportFile}
                fullWidth
                sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
              >
                {intl.formatMessage({ id: 'inventory.add.multiple' })}
              </Button>
            </Box>
          </GroupCollapseButton>
        </Stack>
      </Stack>
      <FormDataDialog
        medicationImportData={medicationImportData || []}
        supplierData={supplierData?.content || []}
        formData={{
          inventoryImportDetails: drugDatabaseContext.selectionMedication
            .slice(0, 10)
            .map((item) => ({ medication: item })),
        }}
        open={openImport}
        onClose={async () => {
          if (drugDatabaseContext.selectionMedication.length > 0) {
            const confirm = await promptConfirmation({
              warning: false,
              title: intl.formatMessage({ id: 'common.title.confirm' }),
              content: intl.formatMessage({ id: 'confirmClose' }),
              cancelId: 'no',
              okId: 'yes',
            });
            if (confirm) {
              onCloseImport();
            }
            close();
            drugDatabaseContext.setSelectionMedication([]);
          } else {
            onCloseImport();
          }
        }}
        onSubmit={createImport}
      />
      <ImportDrugFormDialog
        open={openImportFile}
        onClose={() => {
          onCloseImportFile();
          setShowErrorMessage(false);
          setShowSuccessMessage(false);
        }}
        onUploadFile={(file: File) => {
          dispatch(setLoading(true));
          updateFileDrugUse({ file: file, url: API_SERVER.medicationCategory.uploadDrugFile() });
        }}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
      />

      <ImportUnitExchangeFormDialog
        open={openUnitExchange}
        onClose={() => {
          onCloseUnitExchange();
          setShowErrorMessageUnitChange(false);
          setShowSuccessMessageUnitChange(false);
        }}
        onUploadFile={(file: File) => {
          dispatch(setLoading(true));
          updateFileUnitExchange({
            file: file,
            url: API_SERVER.medicationCategory.uploadExchangeUnitFile(),
          });
        }}
        showErrorMessage={showErrorMessageUnitChange}
        showSuccessMessage={showSuccessMessageUnitChange}
      />
    </Box>
  );
};

export default Header;
