import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import NoDataBox from 'modules/common/component/NoDataBox';
import DrugTable from './DrugTable';
import { MedicationCategory } from 'modules/schema';
import useDrugDatabaseContext, { BOX_SUMMARY_KEY } from './const';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { sumBy } from 'lodash';

interface Props {
  data?: MedicationCategory[];
  loading?: boolean;
  isInsurance?: boolean;
}

const DrugDataListTable: React.FunctionComponent<Props> = (props) => {
  const { data, loading } = props;
  const drugDatabaseContext = useDrugDatabaseContext();
  const { intl } = useGeneralHook();
  useEffect(() => {
    drugDatabaseContext.setTotalResult(sumBy(data, 'total'));
  }, [data, drugDatabaseContext]);
  const dataCategory =
    drugDatabaseContext.selectedViewMode === 'GROUP' && drugDatabaseContext.summaryBox === BOX_SUMMARY_KEY.DELETED
      ? [
          {
            id: -1,
            name: intl.formatMessage({ id: 'inventory.box.summary.5' }),
          },
        ]
      : data;
  return (
    <>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Box padding={2} paddingTop={0} overflow="auto">
          {dataCategory && dataCategory?.length > 0 ? (
            dataCategory?.map((value) => {
              return <DrugTable key={value.id} data={value} />;
            })
          ) : (
            <NoDataBox />
          )}
        </Box>
      )}
    </>
  );
};

export default DrugDataListTable;
