import { z } from 'zod';
import { MedicationSchema } from './Medication';

export interface MedicationCategoryForm {
  name?: string;
}

export const MedicationCategorySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    total: z.number(),
    status: z.string(),
    scope: z.string(),
    medicationList: z.array(MedicationSchema),
  })
  .partial();

export type MedicationCategory = z.infer<typeof MedicationCategorySchema>;
