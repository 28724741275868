import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FilterType, FormFilter } from './type';
import {
  CodeSystemSchema,
  MedicalSuppliesSchema,
  MedicationCategorySchema,
  MedicationSchema,
  ServiceSchema,
  SystemCategorySchema,
  UserEmployeeRoleSchema,
} from 'modules/schema';
import { z } from 'zod';

export const FilterContext = React.createContext<{
  onOpenPopup: () => void;
  form: UseFormReturn<FormFilter>;
  filters: FilterType[];
  filterValue: FormFilter | null;
  onFilterChange: (formData: FormFilter) => void;
  defaultValues: FormFilter;
} | null>(null);

const OptionSchema = z.object({
  value: z.string(),
  label: z.string(),
  checked: z.boolean(),
});

export const FormSchema = z.object({
  PAYMENT_METHODS: z.array(OptionSchema),
  INDICATION_REQUEST_SCOPE: z.array(OptionSchema),
  INDICATION_REQUEST_STATUS: z.array(OptionSchema),
  ENCOUNTER_STATUS: z.array(OptionSchema),
  DEBT_STATUS: z.array(OptionSchema),
  PAYMENT_PLAN: z.array(OptionSchema),
  APPOINTMENT_STATUS: z.array(OptionSchema),
  PATIENT_TYPE: z.array(OptionSchema),
  APPOINTMENT_PATIENT_TYPE: z.array(OptionSchema),
  APPOINTMENT_CHART_TYPE: z.array(OptionSchema),
  EMPLOYEE: z.array(UserEmployeeRoleSchema),
  SERVICE: z.array(ServiceSchema),
  ICD10: z.array(CodeSystemSchema),
  MEDICAL_SUPPLY_GROUP: z.array(SystemCategorySchema.omit({ items: true })),
  MEDICATION_CATEGORY: z.array(MedicationCategorySchema.omit({ medicationList: true })),
  isSaveFilter: z.boolean().default(true).catch(true),
  PAYMENT_STATUS: z.array(OptionSchema),
  TRANSACTION_TYPE: z.array(OptionSchema),
  MEDICAL_SUPPLIERS: z.array(MedicalSuppliesSchema),
  MEDICATION: z.array(MedicationSchema.omit({ inventoryStatus: true, updatedTime: true, category: true })),
});

export const DEFAULT_CONFIG = {
  PAYMENT_METHODS: { title: 'cashier.paymentMethod' },
  PAYMENT_STATUS: { title: 'revenueReport.status' },
  INDICATION_REQUEST_SCOPE: { title: 'filter.popup.title.indicationRequestScope' },
  INDICATION_REQUEST_STATUS: { title: 'filter.popup.title.indicationRequestStatus' },
  ENCOUNTER_STATUS: { title: 'filter.popup.title.encounterStatus' },
  DEBT_STATUS: { title: 'debtStatus' },
  PAYMENT_PLAN: { title: 'filter.popup.title.paymentPlan' },
  APPOINTMENT_STATUS: { title: 'filter.popup.title.appointmentStatus' },
  PATIENT_TYPE: { title: 'filter.popup.title.patientType' },
  APPOINTMENT_PATIENT_TYPE: { title: 'filter.popup.title.appointmentPatientType' },
  APPOINTMENT_CHART_TYPE: { title: 'filter.popup.title.appointmentPatientType' },
  EMPLOYEE: {
    title: 'filter.popup.title.employee',
    placeholder: 'filter.popup.placeholder.employee',
  },
  SERVICE: {
    title: 'filter.popup.title.service',
    placeholder: 'filter.popup.placeholder.service',
  },
  ICD10: {
    title: 'filter.popup.title.icd10',
    placeholder: 'filter.popup.placeholder.icd10',
  },
  MEDICAL_SUPPLY_GROUP: {
    title: 'filter.popup.title.medicalSupplyGroup',
    placeholder: 'filter.popup.placeholder.medicalSupplyGroup',
  },
  MEDICATION_CATEGORY: {
    title: 'filter.popup.title.medicationCategory',
    placeholder: 'filter.popup.placeholder.medicationCategory',
  },
  TRANSACTION_TYPE: { title: 'therapy.cashier.dialog.label.paymentHistory.paymentType' },
  MEDICAL_SUPPLIERS: { title: 'supplierLabel', placeholder: 'supplierLabel' },
  MEDICATION: { title: 'sale.products', placeholder: 'sale.products' },
  isSaveFilter: true,
} as const;
