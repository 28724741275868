import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FE_DATE_FORMAT, THERAPY_SESSION_STATUS } from 'modules/common/constants';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { TherapyWorkReport } from 'modules/schema';
import _ from 'lodash';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

interface Props {
  data?: TherapyWorkReport[];
  loading: boolean;
}

const DetailedTable: React.FunctionComponent<Props> = ({ data, loading }) => {
  const tableDataMapped = React.useMemo(() => {
    return data?.map((ele) => ({
      ...ele,
      startTime: moment(ele?.startTime)?.format(FE_DATE_FORMAT),
      endTime: ele?.endTime && moment(ele?.endTime)?.format(FE_DATE_FORMAT),
      patientCode: ele?.patientCode?.split('-')?.[1],
    }));
  }, [data]);

  const LIST_COLUMNS: Columns[] = [
    {
      title: 'therapy.label.customer.code.kh',
      dataIndex: 'patientCode',
    },
    {
      dataIndex: 'patientName',
      title: 'therapy.label.customerName',
    },
    {
      dataIndex: 'diagnosis',
      title: 'therapyList.header.label.diagnosis',
      render: (record) => {
        return (
          <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
            {record.diagnosis && extractHTMLContent(record.diagnosis)}
          </Typography>
        );
      },
    },
    {
      dataIndex: 'therapyCode',
      title: 'therapy.code',
      minWidth: 80,
    },
    {
      dataIndex: 'therapyName',
      title: 'therapy',
      minWidth: 80,
    },
    {
      dataIndex: 'therapySessionName',
      title: 'therapy.session.name',
    },
    {
      dataIndex: 'therapySessionStatus',
      title: 'status',
      render: (record) => {
        return (
          <Box component="span" color={THERAPY_SESSION_STATUS?.[record.therapySessionStatus]?.color}>
            <FormattedMessage id={THERAPY_SESSION_STATUS?.[record.therapySessionStatus]?.label || ' '} />
          </Box>
        );
      },
    },
    {
      dataIndex: 'picUserName',
      title: 'therapyList.header.label.therapySession.picName',
    },
    {
      title: 'participant',
      render: (record) => {
        const attendPersonName = record?.attendPersons && _.map(record?.attendPersons, 'employeeName').join(', ');
        return <Typography variant="body1">{attendPersonName}</Typography>;
      },
    },
    {
      dataIndex: 'startTime',
      title: 'therapyList.header.label.therapySession.startDate',
    },
    {
      dataIndex: 'endTime',
      title: 'therapyList.header.label.therapySession.endDate',
    },
    {
      dataIndex: 'conclude',
      title: 'therapyList.header.label.therapySession.conclude',
      render: (record) => {
        return (
          <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
            {record.conclude}
          </Typography>
        );
      },
    },
  ];

  return <TableCustom dataSource={tableDataMapped} loading={loading} columns={LIST_COLUMNS} fixIndexColumn={true} />;
};

export default DetailedTable;
