import { INVENTORY_STATUS, MEDICATION_CATEGORY_SCOPE, MEDICATION_STATUS } from 'modules/common/apiConstants';
import { Medication, MedicationCategory, MedicationCountResult } from 'modules/schema';
import React, { useContext } from 'react';

export interface ListId {
  medicationId: number | null;
  medicationCategoryId: number | null;
}

export interface SearchParams {
  scope?: keyof typeof MEDICATION_CATEGORY_SCOPE;
  medicationStatus?: keyof typeof MEDICATION_STATUS;
  productName?: string;
  categoryIds?: number[];
  inventoryStatus?: keyof typeof INVENTORY_STATUS;
  fromDate?: string;
  toDate?: string;
  name?: string;
}

export const BOX_SUMMARY_KEY = {
  TOTAL: 'TOTAL',
  ADD_ED_RECENTLY: 'ADD_ED_RECENTLY',
  NOT_IN_STOCK: 'NOT_IN_STOCK',
  IN_STOCK: 'IN_STOCK',
  DELETED: 'DELETED',
} as const;

export const DrugDatabaseContext = React.createContext<{
  selectionMedication: Medication[];
  setSelectionMedication: (value: Medication[]) => void;
  summaryBox: string;
  setSummaryBox: (value) => void;
  searchParams: SearchParams;
  selectedViewMode: string;
  revalidateCategoryGroup: () => void;
  dataDashboard: MedicationCountResult;
  revalidateDashboard: () => void;
  openMedicationForm: boolean;
  setOpenMedicationForm: (openMedicationForm: boolean) => void;
  listId: ListId;
  setListId: (listId: ListId) => void;
  formDataGroup: MedicationCategory | undefined;
  setFormDataGroup: (formDataGroup: MedicationCategory | undefined) => void;
  revalidateMedicationCategory: () => void;
  revalidateMedicationSearch: () => void;
  isValidatingMedicationSearch: boolean;
  totalResult: number;
  setTotalResult: (totalResult: number) => void;
} | null>(null);

const useDrugDatabaseContext = () => {
  const context = useContext(DrugDatabaseContext);

  if (!context) {
    throw new Error('Must be wrap by Filter component');
  }
  return context;
};

export default useDrugDatabaseContext;
