import { SchemaSearch } from 'modules/admin/page/ExportInventoryPage';
import { createContext, useContext } from 'react';

export const ExportInventoryContext = createContext<{
  revalidateExportInventory: () => void;
  searchParam?: SchemaSearch;
} | null>(null);

const useExportInventoryContext = () => {
  const context = useContext(ExportInventoryContext);
  if (!context) {
    throw new Error('Must be wrap by Filter component');
  }
  return context;
};

export default useExportInventoryContext;
