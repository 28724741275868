import { Button, Popover, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import IconButton from './IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useId from '@mui/material/utils/useId';

type Props = {
  children: ReactNode;
  variant?: 'primary' | 'secondary';
} & ({ title: string; titleIcon?: ReactNode } | { title?: string; titleIcon: ReactNode });

const GroupCollapseButton = ({ title, titleIcon, children, variant = 'primary' }: Props) => {
  const id = useId();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      gap="1px"
      sx={{
        '>button': {
          height: '33px',
        },
      }}
    >
      {title ? (
        <Button
          sx={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', cursor: 'pointer' }}
          onClick={handleClick}
        >
          {title}
        </Button>
      ) : (
        <IconButton
          icon={titleIcon}
          sx={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
          onClick={handleClick}
        />
      )}
      <IconButton
        aria-describedby={id}
        icon={<ExpandMoreIcon sx={{ color: variant === 'primary' ? '#fff' : '#307BFF' }} />}
        onClick={handleClick}
        sx={{
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          backgroundColor: variant === 'primary' ? '#0052E0' : '#CDDFFF',
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </Stack>
  );
};

export default GroupCollapseButton;
