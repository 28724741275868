import { Paper } from '@mui/material';
import { useMemo, useState } from 'react';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import FilterBox from 'modules/admin/component/exportInventory/FilterBox';
import FormDataDialog from 'modules/admin/component/exportInventory/FormDataDialog';
import ExportInventoryTable from 'modules/admin/component/exportInventory/ExportInventoryTable';
import { useFetch } from 'modules/common/hook';
import { INVENTORY_EXPORT_STATUS } from 'modules/admin/component/inventory/constant';
import { ExportInventoryContext } from 'modules/admin/component/exportInventory/utils';

interface Props {}

export interface SchemaSearch {
  code?: string;
  medicationId?: number;
  lot?: string;
  type?: string;
  status?: keyof typeof INVENTORY_EXPORT_STATUS;
  exportDateFrom?: string;
  exportDateTo?: string;
  page: number;
  pageSize: number;
}

const ExportInventoryPage: React.FunctionComponent<Props> = (props: Props) => {
  const { API_SERVER } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const [formData, setFormData] = useState<some | undefined>();
  const [searchParam, setSearchParam] = useState<SchemaSearch>();
  const { params } = hookPagination;

  const formatSearchParam = useMemo(() => {
    return {
      ...searchParam,
      page: params.page,
      pageSize: params.pageSize,
    };
  }, [params.page, params.pageSize, searchParam]);

  const { data = [], revalidate } = useFetch(API_SERVER.exportInventory.getList(formatSearchParam), {
    revalidateOnMount: true,
    revalidateOnFocus: true,
  });

  // Get inventory export by id
  const { data: inventoryExportDetail } = useFetch(API_SERVER.exportInventory.getById(formData?.id), {
    globalLoading: true,
    enabled: formData?.id,
  });

  return (
    <ExportInventoryContext.Provider
      value={{
        revalidateExportInventory: revalidate,
        searchParam: searchParam,
      }}
    >
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterBox revalidate={revalidate} setSearchParam={setSearchParam} />
        <ExportInventoryTable data={data} onViewDetail={setFormData} hookPagination={hookPagination} />
      </Paper>
      {inventoryExportDetail && (
        <FormDataDialog
          formData={inventoryExportDetail}
          open={!!formData}
          onClose={() => {
            setFormData(undefined);
          }}
        />
      )}
    </ExportInventoryContext.Provider>
  );
};
export default ExportInventoryPage;
