import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import GroupDrugDataForm from './GroupDrugDataForm';
import { MedicationCategory } from 'modules/schema';

interface Props {
  onClose: () => void;
  formData?: MedicationCategory;
}

const GroupDrugDataFormDialog: React.FunctionComponent<Props> = (props) => {
  const { onClose, formData } = props;
  return (
    <DialogCustom
      open={!!formData}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 460 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'pharmacy.editGroup' : 'pharmacy.addGroup'} />
        </Typography>
      }
    >
      <Box padding={2}>{formData && <GroupDrugDataForm onClose={onClose} />}</Box>
    </DialogCustom>
  );
};
export default GroupDrugDataFormDialog;
