import { FormCommonProps, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { getError } from './utils';
import { DateTimeType } from 'modules/schema/common';

type DateMode = 'start' | 'end' | 'normal';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  dateFormat?: DateTimeType;
  mode?: DateMode;
  toUTC?: boolean;
  rawError?: boolean;
  onChange?: (date: string) => void;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
  onBlur?: () => void;
  type?: string;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<
    DatePickerProps<moment.Moment>,
    'value' | 'onChange' | 'renderInput' | 'ref' | 'name' | 'renderDay' | 'ToolbarComponent'
  >;

const DateInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  form,
  label,
  rawLabel = false,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  hideError = false,
  disabled = false,
  toUTC = true,
  required,
  dateFormat = BE_DATE_TIME_FORMAT,
  fullWidth = true,
  onChange: onDateChange,
  mode = 'normal',
  rawError = false,
  onBlur: onInputBlur,
  type,
  ...restProps
}: Props<TFieldValues, TName>) => {
  const id = useId();

  // validate message error
  const dateForm = form?.watch();
  const dateValue = dateForm && label && dateForm[label];
  let errMes: FieldError = {
    type: 'required',
  };
  if (required && (!dateValue || dateValue === 'Invalid date')) {
    errMes = {
      ...errMes,
      message: 'required',
    };
  } else if (dateForm?.fromDate && dateForm?.toDate && moment(dateForm.fromDate) > moment(dateForm.toDate)) {
    errMes = {
      ...errMes,
      message: 'validation.fromDateAfterToDate',
    };
  } else if (type === 'TABLE') {
    if (moment(dateForm?.toDate) > moment().add(+1, 'day')) {
      errMes = {
        ...errMes,
        message: 'validation.invalidDate',
      };
    }
    if (
      moment(dateForm?.toDate) >
      moment(dateForm?.fromDate)
        .add(+1, 'year')
        .add(+1, 'day')
    ) {
      errMes = {
        ...errMes,
        message: 'validation.maxFilterOneYear',
      };
    }
  }

  return (
    <FormControl
      error={getError(form, name) || errMes}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      fullWidth={fullWidth}
      controlProps={controlProps}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      rawError={rawError}
    >
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, ref, onChange, onBlur } }) => {
          const formatValue = moment(value, dateFormat).utc();

          return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
              <DatePicker
                sx={{
                  fieldset: {
                    borderColor: errMes?.message ? '#E53935!important' : 'rgba(0, 0, 0, 0.23)',
                  },
                }}
                ref={ref}
                value={formatValue.isValid() ? formatValue : null}
                onChange={(date) => {
                  if (!date) {
                    onChange('');
                    return;
                  }
                  let newDate = date as Moment;
                  if (mode === 'start') {
                    newDate.startOf('day');
                  } else if (mode === 'end') {
                    newDate.endOf('day');
                  }
                  if (toUTC) {
                    newDate.utc();
                  }
                  onChange(newDate.format(dateFormat));
                  onDateChange && onDateChange(newDate.format(dateFormat));
                }}
                {...restProps}
              />
            </LocalizationProvider>
          );
        }}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default DateInput;
