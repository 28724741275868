import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import { some } from 'modules/common/constants';
import { PriorityHighIcon, UploadExchangeIcon } from '../../../../../svg';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { LoadingButton } from '@mui/lab';
import GridOnIcon from '@mui/icons-material/GridOn';
import { useDownloadFileLoading } from '../../../../common/hook';
import { API_SERVER } from '../../../../common/api';

interface Props {
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  onDownLoadFile?: () => void;
  onUploadFile: (file: File) => void;
  showSuccessMessage?: boolean;
  showErrorMessage?: boolean;
}

const ImportDrugFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, onUploadFile, showSuccessMessage, showErrorMessage } = props;
  const { openSnackbar, intl } = useGeneralHook();
  const { downloadFile, loading } = useDownloadFileLoading(API_SERVER.medicationCategory.downloadSimpleDrugFile);
  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 910 },
      }}
      title={
        <Typography variant="h5" color="primary">
          <FormattedMessage id="dugDatabase.importDrug.message.importPopupTitle" />
        </Typography>
      }
    >
      <Box padding={2}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={1.5} textAlign={'center'}>
            <PriorityHighIcon color={'primary'} style={{ width: 50, height: 60, marginTop: 90, marginLeft: 25 }} />
          </Grid>
          <Grid item xs={10.5}>
            <Typography variant="h6" paddingTop={2}>
              <FormattedMessage id="dugDatabase.importUnitExchange.message.instructTitle" />
            </Typography>
            <Typography
              variant="subtitle1"
              paddingTop={1}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'dugDatabase.importDrug.message.instructStep' }),
              }}
            ></Typography>
            <Typography
              variant="subtitle1"
              paddingTop={2}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'dugDatabase.importUnitExchange.message.instructSystem' }),
              }}
            ></Typography>
          </Grid>
        </Grid>
        <Box padding={1}>
          {!!showSuccessMessage && (
            <Typography variant="subtitle1" paddingTop={2} style={{ color: '#43A047', textAlign: 'center' }}>
              <FormattedMessage id="dugDatabase.importUnitExchange.message.instructSuccessMessage" />
            </Typography>
          )}
          {!!showErrorMessage && (
            <Typography variant="subtitle1" paddingTop={2} style={{ color: '#FF0000', textAlign: 'center' }}>
              <FormattedMessage id="dugDatabase.importUnitExchange.message.instructErrorMessage" />
            </Typography>
          )}
        </Box>
        <Box padding={1} paddingTop={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            startIcon={<UploadExchangeIcon />}
            component="label"
            variant="text"
            style={{ color: '#FFFFFF', backgroundColor: '#0052E0', marginRight: 40 }}
          >
            <FormattedMessage id="dugDatabase.importUnitExchange.message.upload" />
            <input
              accept={'.xlsx'}
              hidden
              type="file"
              onChange={(event) => {
                const files = event.target.files;
                if (files && files?.length > 0) {
                  const file = files.item(0);
                  if (file) {
                    if (Array.from(files).every((file) => file.size <= 5000000)) {
                      onUploadFile(file);
                    } else {
                      openSnackbar({ message: intl.formatMessage({ id: 'upload.file.max.size' }), type: 'error' });
                    }
                  }
                }
                event.target.value = '';
              }}
            />
          </Button>
          <LoadingButton
            sx={{ backgroundColor: '#CDDFFF' }}
            loading={loading}
            color="inherit"
            startIcon={<GridOnIcon />}
            onClick={downloadFile}
          >
            <FormattedMessage id="dugDatabase.importUnitExchange.message.download" />
          </LoadingButton>
        </Box>
      </Box>
    </DialogCustom>
  );
};
export default ImportDrugFormDialog;
